export interface IDetalleAlerta {
    tipo: number;
    estatus: string;
    lista_negra: string;
    lco_status: number;
    proveedores_lista_negra: any;
}

export interface IAlerta {
    tipo: number;
    text: string;
    leido: boolean;
    detalles: IDetalleAlerta;
}

export class IAlerta {
  tipo: number;
  text: string;
  leido: boolean;
  detalles: IDetalleAlerta;
}

export class DetalleAlerta implements IDetalleAlerta {
    tipo: number;
    estatus: string;
    lista_negra: string;
    lco_status: number;
    proveedores_lista_negra: any;
    constructor() {
        this.tipo = 0;
        this.estatus = 'Cargando...';
        this.lista_negra = 'Cargando...';
        this.proveedores_lista_negra = new Array<string>();

    }
}
