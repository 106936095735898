import Swal from 'sweetalert2';
import { ConstantsService } from './../../../Compartidos/global/constants.service';
import { UsuariosService } from './../usuarios.service';
import { Usuario } from './../../../Compartidos/Entidades';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component } from '@angular/core';


@Component({
  selector: 'app-usuario-contrasena',
  templateUrl: './usuario-contrasena.component.html',
  styleUrls: ['./usuario-contrasena.component.css']
})
export class UsuarioContrasenaComponent {
  public usuario = new Usuario();
  public forma = new FormGroup({
    actual_contrasena: new FormControl(this.usuario.password, [Validators.required, Validators.minLength(8)]),
    nueva_contrasena: new FormControl('', [Validators.required, Validators.minLength(8), this.validarContrasena]),
    confirmar_contrasena: new FormControl('', [Validators.required, Validators.minLength(8), this.validarContrasena])
  });

  constructor(
    private _servicio_usuarios: UsuariosService,
    private _constantes: ConstantsService
  ) { }

  private validarContrasena(control: AbstractControl) {
    const contrasena = control.value;
    let error = null;
    const regex = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/);
    if (!regex.test(contrasena)) {
      error = 'Las contraseñas no coinciden';
    }
    return error;
  }

  actualizaContrasena() {
    this._constantes.isLoading = true;
    this._servicio_usuarios.actualizarContrasena(this.usuario.password, this.usuario.new_password).subscribe(
      (data) => {
        Swal.fire('Exito', 'Contraseña actualizada con exito', 'success');
        this._constantes.isLoading = false;
      },
      (error) => {
        console.log(error.error);
        if (error.error) {
          Swal.fire('Atención', 'Ha ocurrido un error. <br> Detalle error: ' + error.error.message, 'error');
        } else {
          Swal.fire('Atención', 'Ha ocurrido un error inesperado. ', 'error');
        }
        this._constantes.isLoading = false;
      },
      () => {
        this._constantes.isLoading = false;
      }
    );
  }



  get controles() {
    return this.forma.controls;
  }

}
