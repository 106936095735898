export class NominaEmision {
    public codigo_postal: string;
    public estatus_cfdi: number;
    public fecha_factura: string;
    public fecha_timbrado: string;
    public folio: string;
    public folio_fiscal: string;
    public nombre_empleado: string;
    public rfc_receptor: string;
    public ruta_xml: string;
    public serie: string;
    public subtotal: number;
    public total_deducciones: number;
    public total_isr_retenido: number;
    public total_otros_pagos: number;
    public total_percepciones: number;
    public total_recibido: number;
    public monto: number;
    public moneda: string;
    public tipo_cambio: number;
    public moneda_dr: number;
    public tipo_cambio_dr: number;
    public metodo_pago_dr: string;
    public forma_pago: string;
    public fecha_pago: string;
    public importe_pagado: number;
    public importe_saldo: number;
    public importe_insol: number;
    public documento_relacionado: string;
    public num_parcialidad: number;
    public serie_dr: string;
    public folio_dr: string;
    public razon_social_emisor: string;
    public razon_social_receptor: string;
    public rfc_emisor: string;

    constructor() {
        this.codigo_postal = '';
        this.estatus_cfdi = 0;
        this.fecha_factura = '';
        this.fecha_timbrado = '';
        this.folio = '';
        this.folio_fiscal = '';
        this.nombre_empleado = '';
        this.rfc_receptor = '';
        this.ruta_xml = '';
        this.serie = '';
        this.subtotal = 0;
        this.total_deducciones = 0;
        this.total_isr_retenido = 0;
        this.total_otros_pagos = 0;
        this.total_percepciones = 0;
        this.total_recibido = 0;
        this.monto = 0;
        this.moneda = '';
        this.tipo_cambio = 0;
        this.moneda_dr = 0 ;
        this.tipo_cambio_dr = 0;
        this.metodo_pago_dr = '';
        this.forma_pago = '';
        this.fecha_pago = '';
        this.importe_pagado = 0;
        this.importe_saldo = 0;
        this.importe_insol = 0;
        this.documento_relacionado = '';
        this.num_parcialidad = 0;
        this.serie_dr = '';
        this.folio_dr = '';
        this.razon_social_emisor = '';
        this.razon_social_receptor = '';
    }
}
