import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import * as Highstock from "highcharts/highstock";
declare var require: any;
// tslint:disable-next-line: no-use-before-declare
require("highcharts/modules/exporting")(Highcharts);
// import the module
import Variablepie from "highcharts/modules/variable-pie";
import Drilldown from "highcharts/modules/drilldown";
import Treemap from "highcharts/modules/treemap";
import Exporting from "highcharts/modules/exporting";
import Accessibility from "highcharts/modules/accessibility";

Drilldown(Highcharts);
// initialize the module
Variablepie(Highcharts);
Treemap(Highcharts);
Exporting(Highcharts);
Accessibility(Highcharts);

const Boost = require("highcharts/modules/boost");
const noData = require("highcharts/modules/no-data-to-display");
const More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: "app-grafica-barra-grill",
  templateUrl: "./grafica-barra-grill.component.html",
  styleUrls: ["./grafica-barra-grill.component.css"],
})
export class GraficaBarraGrillComponent {
  @Input() contenedor: string;
  @Input() options: any;
  @Input() sombreado: string;
  @Input() scroll = false;
  constructor() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
        loading: "Cargando...",
        rangeSelectorZoom: "Periodo",
        downloadPNG: "Descargar imagen PNG",
        downloadJPEG: "Descargar imagen JPEG",
        downloadPDF: "Descargar documento PDF",
        downloadSVG: "Descargar imagen SVG",
        viewFullscreen: "Ver en pantalla completa",
        printChart: "Imprimir Gráfica",
        // ,numericSymbolMagnitude: 1
      },
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges() {
    setTimeout(() => {
      if (this.contenedor) {
        if (this.scroll) {
          Highstock.chart(this.contenedor, this.options || {});
        } else {
          Highcharts.chart(this.contenedor, this.options || {});
        }
      }
    }, 0.5);
  }
}
