export class CardKpi {
    cantidad: number;
    estatus: string;
    monto: number;
    porcentaje: number;
    tipo: string;
    constructor() {
        this.cantidad = 0;
        this.estatus = '';
        this.monto = 0;
        this.porcentaje = 0;
        this.tipo = '';
    }
}