import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';


class Funcionalidad {
  funcionalidad: string;
  id: string;
  modulo: string;
  path: string;
  checked?: boolean;
}

class Modulo {
  funcionalidades: Array<Funcionalidad>;
  modulo: string;

  constructor() {
    this.funcionalidades = new Array<Funcionalidad>();
  }
}

@Component({
  selector: 'app-select-funcionalidades',
  templateUrl: './select-funcionalidades.component.html',
  styleUrls: ['./select-funcionalidades.component.css']
})
export class SelectFuncionalidadesComponent implements AfterViewInit, OnDestroy, OnChanges {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChange = new EventEmitter();
  @Input() data = new Array<Funcionalidad>();
  @Input() value_name = 'id';
  @Input() text_name = 'nombre';
  @ViewChild('contenedor_select', { static: true }) modal: any;


  modulos = new Array<Modulo>();
  expanded = false;
  isToogle = false;
  isFocus = false;
  seleccioandos = [];
  listenerClick: any;
  constructor(
  ) { }

  ngOnChanges() {
    this.modulos = new Array<Modulo>();
    for (let nodo in this.data) {
      const mod = new Modulo();
      mod.funcionalidades = this.data[nodo] as any;
      mod.funcionalidades.map(x => {
        if (this.seleccioandos.filter(y => { return y.id === x.id }).length !== 0) {
          x.checked = true;
        }
        return x;
      });
      mod.modulo = nodo;
      this.modulos.push(mod);
    }
  }

  ngAfterViewInit(): void {
    this.modal = document.getElementById('contenedor_select');
    window.addEventListener('click', (event: any) => {
      if (!event.target.classList.contains('class_cerrar_modal') && this.isToogle) {
        this.toogleSelect();
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.modal);
  }

  toogleSelect() {
    document.getElementById('basic').blur();
    if (this.isToogle) {
      this.isToogle = false;
      this.modal.style.display = 'none';
    } else {
      this.isToogle = true;
      this.modal.style.display = 'block';
    }
  }

  addSeleccion(input, dato) {
    if (input.checked && this.seleccioandos.filter(x => x.id === dato.id).length === 0) {
      this.seleccioandos.push(dato);
    } else {
      if (!input.checked) {
        this.seleccioandos = this.seleccioandos.filter(x => x.id !== dato.id);
      }
    }
    this.onChange.emit(this.seleccioandos);
  }
}
