import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';
import { Component, Input } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-audit-activado',
  templateUrl: './audit-activado.component.html',
  styleUrls: ['./audit-activado.component.css']
})
export class AuditActivadoComponent {

  @Input() datos_cuenta: AutoActivacion;
  public formulario_activado = new FormGroup({
    rfc: new FormControl('' , Validators.required),
    razon_social: new FormControl('', Validators.required),
    uso_cfdi: new FormControl('', Validators.required),
    correo: new FormControl('', Validators.required),
    razon: new FormControl('', Validators.required),
    terminos: new FormControl('', Validators.required)
  });

  constructor() { }

  Siguiente() {
    Swal.fire('Éxito', '¡En construcción!', 'success');
    console.log('Siguiente');
  }

}
