import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Columnas } from '../Entidades';

@Component({
  selector: 'app-modal-ordenar-columnas',
  templateUrl: './modal-ordenar-columnas.component.html',
  styleUrls: ['./modal-ordenar-columnas.component.css']
})
export class ModalOrdenarColumnasComponent implements OnInit {
  @Input() columnas = new Array<Columnas>();
  @Input() propiedad_ordenar: string;
  @Output() reordenar = new EventEmitter();
  @ViewChild('body_modal', { static: true }) modal: any;
  isToogle = false;
  listenerClick: any;
  options: any;


  constructor() {
    this.options = {
      onUpdate: (event: any) => {
      }
    };
  }

  ngOnInit() {
    window.addEventListener('click', (event: any) => {
      if (!event.target.classList.contains('class_cerrar_modal') && this.isToogle) {
        this.toogleSelect();
      }
    });
    window.addEventListener('mousedown', (event: any) => {
      if (!event.target.classList.contains('class_cerrar_modal') && this.isToogle) {
        document.getElementById('grag-container').classList.add('grabbing');
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.modal);
  }

  toogleSelect() {
    if (this.isToogle) {
      this.isToogle = false;
      this.modal.nativeElement.style.display = 'none';
    } else {
      this.isToogle = true;
      this.modal.nativeElement.style.display = 'block';
    }
  }

  guardarOrden() {
    this.reordenar.emit(this.columnas);
    this.columnas.map((x, index) => (x.posicion = index));
  }

  cancelarOrden() {
    this.columnas = this.ordenar(this.columnas, 'posicion');

  }

  ordenar(array: Array<any>, orden_prop) {
    return array.sort((a, b) => {
      if (a[orden_prop] > b[orden_prop]) { //comparación lexicogŕafica
        return 1;
      } else if (a[orden_prop] < b[orden_prop]) {
        return -1;
      }
      return 0;
    });
  }

}
