import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Label, MultiDataSet, BaseChartDirective } from 'ng2-charts';
import { ChartType, ChartOptions } from 'chart.js';
import { GlobalsComponent } from '../../global/global.component';

@Component({
  selector: 'app-grafica-dona',
  templateUrl: './grafica-dona.component.html'
})
export class GraficaDonaComponent implements OnInit {
  // Ejemplo
  //  public doughnutChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales','Download', 'In-Store', 'Mail-Ordert'];
  //  public doughnutChartData: MultiDataSet = [
  //    [350, 450, 100, 350, 450, 100],
  //    [50, 150, 120, 350, 450, 100],
  //    [250, 130, 70, 350, 450, 100]
  //  ];
  @Input() doughnutChartLabels: Label[];
  @Input() doughnutChartData: MultiDataSet;
  // @Output() actualizar = new EventEmitter<any>();
  public doughnutChartType: ChartType = 'doughnut';
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;


  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'top',
      labels: {
        boxWidth: 10
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
      },
    }
  };

  ChartColors = [
    {
      backgroundColor: [this.globals.COLORES.lila, this.globals.COLORES.azul, this.globals.COLORES.azul_claro],
    },
  ];
  constructor(private globals: GlobalsComponent) {
  //   Highcharts.setOptions({
  //     lang: {
  //     thousandsSep: ','
  //   }
  // });
  }

  ngOnInit() {
  }

  ActualizarVista() {
    this.chart.update();
  }

  downloadCanvas(event) {
    // get the `<a>` element from click event
    const anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    // set the anchors 'download' attibute (name of the file to be downloaded)
    anchor.download = 'resumen.jpeg';
  }
}
