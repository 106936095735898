import { Injectable } from '@angular/core';
import { HttpClient2 } from './Servicios-Compartidos/http-clien.service';
import { GlobalsComponent } from './global/global.component';

@Injectable({
  providedIn: 'root'
})
export class ReportesDescargaService {

  constructor(
    private _http: HttpClient2,
    private globals: GlobalsComponent
  ) { }



  getVidosrCFDI(endPoint: string) {
    // console.log('este es el endpoint desde el servicio', endPoint);
    // console.log('PDF', `https://com-next-tech-audit-api-reportes-descargas-t3w52zi3dq-uc.a.run.app/interprete?path=${endPoint}`);
    
    return this._http.get(`${this.globals.host_descargas}/interprete?path=${endPoint}`);
  }


  descargarXML(ruta_xml) {
    return this._http.post(
      `${this.globals.host_descargas}/documento_xml`,
      { ruta_xml },
      'text'
    );
  }
  getOrdenColumnas(
    identificador_usuario: string,
    documento: 'nomina' | 'factura' | 'pago',
    tipo: 'emision' | 'recepcion'
  ) {
    return this._http.get(
      `${this.globals.host_descargas}/factura_tabla?identificador_usuario=${identificador_usuario}&documento=${documento}&tipo=${tipo}`
    );
  }
  actualizarOrdenColumnas(
    identificador_usuario: string,
    orden_columnas: any,
    documento: 'nomina' | 'factura' | 'pago',
    tipo: 'emision' | 'recepcion'
  ) {
    return this._http.put(
      `${this.globals.host_descargas}/factura_tabla?documento=${documento}&tipo=${tipo}`,
      { identificador_usuario, data: orden_columnas }
    );
  }
  getFormaPagoCatalogo() {
    return this._http.post(
      `${this.globals.host_descargas}/forma_pago`,
      {}
    );
  }
  getMetodoPagoCatalogo() {
    return this._http.post(
      `${this.globals.host_descargas}/metodo_pago`,
      {}
    );
  }
  getUsoCFDICatalogo() {
    return this._http.post(
      `${this.globals.host_descargas}/uso_cfdi`,
      {}
    );
  }
  getRazonesSociales(
    rfc_contribuyente: string,
    tipo_documento: 'factura' | 'nomina' | 'pagos',
    tipo_reporte: 'emision' | 'recepcion'
  ) {
    return this._http.get(
      `${this.globals.host_descargas}/factura_tabla?tipo_reporte=${tipo_reporte}&rfc_contribuyente=${rfc_contribuyente}&tipo_documento=${tipo_documento}`
    );
  }
  // filtro de anios para el filtro meses
  getFiltroAnioRfc(tipo: 'pagos' | 'factura' | 'nomina', rfc: string) {
    return this._http.get(`${this.globals.host_descargas}/filtro_anio?rfc=${rfc}&tipo=${tipo}`);
  }
}
