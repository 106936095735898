import { ConciliacionModule } from './../../Modulos/conciliacion/conciliacion.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './home.component';

const routes: Routes = [
  {
    path: '', component: MainPageComponent,
    children: [
      {
        path: 'configuracion', loadChildren: () => import('../../Modulos/Configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      {
        path: 'preliminar', loadChildren: () => import('../../Modulos/Presupuesto-Preliminar/presupuesto-preliminar.module').then(m => m.PresupuestoPreliminarModule)
      },
      {
        path: 'general', loadChildren: () => import('../../Modulos/Resumen-General/resumen-general.module').then(m => m.ResumenGeneralModule)
      },
      {
        path: 'cancelaciones', loadChildren: () => import('../../Modulos/Resumen-Cancelaciones/resumen-cancelaciones.module').then(m => m.ResumenCancelacionesModule)
      },
      {
        path: 'facturas', loadChildren: () => import('../../Modulos/Reporte-Facturas/reporte-facturas.module').then(m => m.ReporteFacturasModule)
      },
      {
        path: 'pagos', loadChildren: () => import('../../Modulos/Reporte-Pagos/reporte-pagos.module').then(m => m.ReportePagosModule)
      },
      {
        path: 'nomina', loadChildren: () => import('../../Modulos/Reporte-Nomina/reporte-nomina.module').then(m => m.ReporteNominaModule)
      },
      {
        path: 'notificaciones', loadChildren: () => import('../../Modulos/Notificaciones/notificaciones.module').then(m => m.NotificacionesModule)
      },
      {
        path: 'descargas', loadChildren: () => import('../../Modulos/Descargas/descargas.module').then(m => m.DescargasModule)
      }
      ,
      {
        path: 'principal', loadChildren: () => import('../../Modulos/principal/principal.module').then(m => m.PrincipalModule)
      },
      {
        path: 'usuarios', loadChildren: () => import('../../Modulos/Usuarios/usuarios.module').then(m => m.UsuariosModule)
      },
      {
        path: 'facturacion', loadChildren: () => import('../../Modulos/perfil-facturacion/perfil-facturacion.module').then(m => m.PerfilFacturacionModule)
      },
      {
        path: 'conciliacion', loadChildren: () => import('../../Modulos/conciliacion/conciliacion.module').then(m => m.ConciliacionModule)
      },
      {
        path: 'proveedores', loadChildren: () => import('../../Modulos/proveedores/proveedores.module').then(m => m.ProveedoresModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
