import { Component, OnInit, Input } from '@angular/core';
import { TarjetaTitulos } from '../Entidades';

@Component({
  selector: 'app-tarjeta-titulos',
  templateUrl: './tarjeta-titulos.component.html',
  styleUrls: ['./tarjeta-titulos.component.css']
})
export class TarjetaTitulosComponent implements OnInit {
  @Input() data: TarjetaTitulos = new TarjetaTitulos();
  @Input() monto;
  @Input() moneda?: false;
  @Input() centrado?= false;
  constructor() {
  }

  ngOnInit() {
  }

}
