export class ConfiguracionSistemasconciliacion {
  id_corporativo: number;
  configuraciones: ConfiguracionesSistemas[];

  constructor() {
    this.configuraciones = new Array<ConfiguracionesSistemas>();
  }
}

export class ConfiguracionesSistemas {
  nombre: string;
  tipo: string;
  identificador: string;
  constructor(tipo?: 'emision' | 'recepcion' | 'nomina') {
    this.nombre = '';
    this.tipo = tipo ? tipo : '';
  }
}
