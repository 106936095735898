import { Injectable } from "@angular/core";
import {
  TablaPreliminar,
  CardData,
  TarjetaTitulos,
  TablaGIngresos,
  DashboardCencelacionEmision,
  DetalleTablaDiferencia,
  TablaConciliacion,
} from "../Entidades";
import { FiltroCabecera } from "../Entidades/FiltroCabecera";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  isLoading = true;
  filtro_header = new FiltroCabecera();
  orden_columnas_tablas?: any;
  meses_seleccionados = [];
  // Estatus de cfdi para conciliación
  estatus_filtro_cabecera: "vigente" | "cancelado" = "vigente";

  sistema_propio_emision: string;
  sistema_propio_recepcion: string;
  sistema_propio_nomina: string;

  // Dashboard General

  tarjeta_presupuesto_general = new CardData();

  tarjeta_ingresos_general = new TarjetaTitulos();
  tarjeta_egresos_general = new TarjetaTitulos();
  card_ingreso_general = new CardData();
  card_nc_general = new CardData();
  card_egresos_general = new CardData();
  card_egresos_nc_general = new CardData();
  card_nomina_general = new CardData();
  datos_grafica_ingreso_general: any;
  datos_grafica_egreso_general: any;
  datos_grafica4_egreso_general: any;
  datos_grafica3_ingreso_general: any;
  datos_resultado_resumen_general: any;
  tabla_ingresos_general = new Array<TablaGIngresos>();
  tabla_egresos_general = new Array<TablaGIngresos>();

  // Presupuesto Preliminar
  card_kpi_preliminar = new CardData();
  datos_tabla_preliminar = new Array<TablaPreliminar>();
  datos_grafia: any;

  // Resumen Cancelaciones Emitidos

  datos_dashboard_cancelaciones_emision = new DashboardCencelacionEmision();

  // datos_grafica_cancelaciones_emitidas: any;
  // datos_grafica_dos_cancelaciones_emitidas: any;
  kpi_uno_cancelaciones_emision = new CardData();
  kpi_dos_cancelaciones_emision = new CardData();
  // lista_kpis_cancelaciones = new Array<CardKpi>();
  // tabla_cancelaciones_emision = new Array<TablaCancelacionesEmision>();

  // Resumen Cancelaciones Recividos
  datos_dashboard_cancelaciones_recepcion = new DashboardCencelacionEmision();
  // datos_grafica_cancelaciones_recibidas: any;
  // datos_grafica_dos_cancelaciones_recibidas: any;
  kpi_uno_cancelaciones_recepcion = new CardData();
  kpi_dos_cancelaciones_recepcion = new CardData();
  // lista_kpis_cancelaciones_recepcion = new Array<CardKpi>();
  // tabla_cancelaciones_recepcion = new Array<TablaCancelacionesEmision>();

  // Proveedores
  datos_grafica_69_proveedores: any;
  datos_grafica_69_b_proveedores: any;
  datos_grafica_line_proveedores: any;
  lista_tabla_art_69 = new Array<any>();
  lista_tabla_art_69_B = new Array<any>();
  // Seleccionado Proveedores
  seleccionado_tabla_69 = "no_localizable";
  seleccionado_tabla_69_B = "definitivo";

  // Conciliaciones
  kpi_timbrado_sat_e = new TarjetaTitulos();
  kpi_timbrado_sistema_e = new TarjetaTitulos();
  kpi_faltante_e = new TarjetaTitulos();
  grafica_diferencia_e: any;
  grafica_timbrado_sat_e: any;
  grafica_timbrado_sistema_e: any;

  kpi_timbrado_sat_r = new TarjetaTitulos();
  kpi_timbrado_sistema_r = new TarjetaTitulos();
  kpi_faltante_r = new TarjetaTitulos();
  grafica_diferencia_r: any;
  grafica_timbrado_sat_r: any;
  grafica_timbrado_sistema_r: any;

  nombre_spe = {
    identificador: "",
    nombre: "N/A",
    tipo: "emision",
  };
  nombre_spr = {
    identificador: "",
    nombre: "N/A",
    tipo: "recepcion",
  };
  nombre_spn = {
    identificador: "",
    nombre: "N/A",
    tipo: "nomina",
  };

  // Conciliaciones Pago
  kpi_conciliacion_pago_timbrado_sat_e = new TarjetaTitulos();
  kpi_conciliacion_pago_timbrado_sistema_e = new TarjetaTitulos();
  kpi_conciliacion_pago_faltante_e = new TarjetaTitulos();

  kpi_conciliacion_pago_timbrado_sat_r = new TarjetaTitulos();
  kpi_conciliacion_pago_timbrado_sistema_r = new TarjetaTitulos();
  kpi_conciliacion_pago_faltante_r = new TarjetaTitulos();

  //#region  Conciliacion Nomina
  // KPIS Conciliacion nomina
  kpi_conciliacion_nomina_timbrado_sat_e = new TarjetaTitulos();
  kpi_conciliacion_nomina_timbrado_sistema_e = new TarjetaTitulos();
  kpi_conciliacion_nomina_faltante_e = new TarjetaTitulos();
  // Grafica Conciliacion Nomina
  grafica_conciliacion_nomina_timbrado_diferencia_e: any
  //#endregion

  // Graficas Conciliaciones Pago
  grafica_conciliacion_pago_formas_pago_e: any
  grafica_conciliacion_pago_formas_pago_r: any
  grafica_conciliacion_pago_timbrado_diferencia_e: any
  grafica_conciliacion_pago_timbrado_diferencia_r: any

  //#region Conciliacion Pagos
  // Conciliacion Detalle Pagos
  // KPIS
  kpi_conciliacion_detalle_pago_timbrado_sat_e = new TarjetaTitulos();
  kpi_conciliacion_detalle_pago_timbrado_sistema_e = new TarjetaTitulos();
  kpi_conciliacion_detalle_pago_faltante_e = new TarjetaTitulos();
  //#endregion Conciliacion Pagos


  // Detalle conciliacion
  detalle_kpi_timbrado_sat = new TarjetaTitulos();
  detalle_kpi_faltante = new TarjetaTitulos();
  detalle_kpi_timbrado_sistema = new TarjetaTitulos();
  detalle_grafica_cfdis_faltantes: any;
  detalle_grafica_comparativo_timbrado: any;
  // detalle_grafica_diferencia_receptores: any;
  detalle_grafica: any;
  detalle_grafica_diferencia_emisores: any;
  detalle_grafica_faltantes_sistema: any;
  detalle_grafica_activa: any;
  detalle_tabla_conciliacion: Array<TablaConciliacion>;
  detalle_tabla_diferencia: Array<DetalleTablaDiferencia>;
  // detalle_tabla_diferencia_emisores: any;
  detalle_cantidad_diferencia: number;
  grafica_seleccionada: string = 'comparativo';
}
