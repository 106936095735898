export class TablaCancelacionesEmision {
    tipo: string;
    total_emitido: string;
    total_cancelado: string;
    porcentage_cancelado: string;
    monto_neto_cancelado: string;
    constructor() {
        this.tipo = '';
        this.total_emitido = '';
        this.total_cancelado = '';
        this.porcentage_cancelado = '';
        this.monto_neto_cancelado = '';
    }
}