import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Compartidos/login/storage.service';
import { DatosIniciales } from 'src/app/Compartidos/Entidades';
import { HomeService } from './home.service';
@Component({
  selector: 'app-main-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class MainPageComponent implements OnInit {

  estado_pagina = {
    mostrar_menu: true
  };
  public datos_inciales: DatosIniciales;
  public url_activa: string;

  constructor(
    public _constant: ConstantsService,
    private route: Router,
    private _storageService: StorageService,
    private _homerService: HomeService
  ) {
    this.url_activa = this.route.url;
    this.datos_inciales = this._storageService.getDatosIniciales();
  }

  ngOnInit() {
    if (window.innerWidth < 767) {
      this.estado_pagina.mostrar_menu = false;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 767) {
        this.estado_pagina.mostrar_menu = false;
      } else {
        this.estado_pagina.mostrar_menu = true;
      }
    });

    setInterval( () => {
      this._homerService.actualizarToken(this.datos_inciales.usuario.RefreshToken).subscribe(
        (data: any) => {
          this.datos_inciales.usuario.token = data.AuthenticationResult.AccessToken;
          this._storageService.setDatosIniciales(this.datos_inciales);
        },
        (error) => {
          console.log(error);
        },
        () => {

        }
      );
    }, 300000 );

  }

  onToggleSideBar(bandera) {
    if (!bandera) {
      this._constant.isLoading = true;
    }
    // this.estado_pagina.mostrar_menu = !this.estado_pagina.mostrar_menu;
    if (window.innerWidth < 767) {
      this.estado_pagina.mostrar_menu = !this.estado_pagina.mostrar_menu;
    } else {
      this.estado_pagina.mostrar_menu = true;
    }
  }

  onToggleSideBar2() {
    const barra_menu = document.getElementById('sidebar');
    const flecha = document.getElementById('flecha');
    const content = document.getElementById('content');
    let arr;
    arr = barra_menu.className.split(' ');
    if ( arr.indexOf('sidebar-oculto') === -1 ) {
      content.classList.add('ajustar-content');
      barra_menu.className += ' ' + 'sidebar-oculto';
      flecha.className += ' ' + 'fa-angle-double-right';
      document.getElementById('ocultar-menu').style.visibility = 'hidden';
      document.getElementById('ocultar-logo').style.visibility = 'hidden';
      document.getElementById('flecha').classList.remove('fa-angle-double-left');
    } else {
      content.classList.remove('ajustar-content');
      document.getElementById('ocultar-menu').style.visibility = 'initial';
      document.getElementById('ocultar-logo').style.visibility = 'initial';
      document.getElementById('flecha').classList.add('fa-angle-double-left');
      document.getElementById('flecha').classList.remove('fa-angle-double-right');
      document.getElementById('sidebar').classList.remove('sidebar-oculto');
    }
  }
}
