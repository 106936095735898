import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuariosRoutingModule } from './usuarios-routing.module';
import { UsuariosMainComponent } from './usuarios-main/usuarios-main.component';
import { UsuariosFormularioComponent } from './usuarios-formulario/usuarios-formulario.component';
import { UsuariosConfirmacionComponent } from './usuarios-confirmacion/usuarios-confirmacion.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UsuarioContrasenaComponent } from './usuario-contrasena/usuario-contrasena.component';

@NgModule({
  declarations: [UsuariosMainComponent, UsuariosFormularioComponent, UsuariosConfirmacionComponent, UsuarioContrasenaComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    UsuariosRoutingModule
  ]
})
export class UsuariosModule { }
