import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ConstantsService } from '../global/constants.service';
import { CompartidosService } from '../compartidos.service';
import { GlobalsComponent } from '../global/global.component';
import { CardData, TarjetaTitulos, TablaConciliacion, DetalleTablaDiferencia } from '../Entidades';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../login/storage.service';
import { ApiNotificacionesService } from '../api-notificaciones.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-filtro-meses',
  templateUrl: './filtro-meses.component.html',
  styleUrls: ['./filtro-meses.component.css'],
})
export class FiltroMesesComponent {
  public tipo_fecha: string;
  public nombre_sistema: string;

  @Output() filtrar = new EventEmitter();

  @Input() mostrar_meses = true;
  @Input() mostrar_anios = true;
  @Input() mostrar_seleccionados = true;
  @Input() mostrar_estatus_conciliacion = false;

  // public visible_mensaje = false;
  kpi_uno = new CardData();
  kpi_dos = new CardData();
  public meses_selected = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  };
  constructor(
    public _constant: ConstantsService,
    private compartidosService: CompartidosService,
    private _servicioNotificaciones: ApiNotificacionesService,
    private _activatedRoute: ActivatedRoute,
    public _globals: GlobalsComponent,
    private _storageService: StorageService
  ) {
    if (this._storageService.getDatosIniciales().usuario.sistemas) {
      const nombre_emision = this._storageService.getDatosIniciales().usuario.sistemas.filter(x => x.tipo === 'emision')[0];
      this._constant.sistema_propio_emision = nombre_emision ? nombre_emision.nombre : null;
      const nombre_recepcion = this._storageService.getDatosIniciales().usuario.sistemas.filter(x => x.tipo === 'recepcion')[0];
      this._constant.sistema_propio_recepcion = nombre_recepcion ? nombre_recepcion.nombre : null;
      const nombre_nomina = this._storageService.getDatosIniciales().usuario.sistemas.filter(x => x.tipo === 'nomina')[0];
      this._constant.sistema_propio_nomina = nombre_nomina ? nombre_nomina.nombre : null;
    }
  }

  cambiarMeses(mesesSeleccionados: any) {
    let meses_marcados = $('.class_cerrar_modal')
      .find($('input:checkbox:checked'))
      .map(function () {
        return this.value;
      })
      .get();
    if (mesesSeleccionados[0] === '0') {
      // Verificar si esta check el de todos
      if (!$($('input:checkbox')[0]).prop('checked')) {
        meses_marcados = [];
        $('input:checkbox').prop('checked', false);
      } else {
        meses_marcados = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        $('input:checkbox').prop('checked', true);
      }
    } else {
      if (
        $('.class_cerrar_modal').find($('input:checkbox:not(:checked)'))
          .length > 0
      ) {
        $($('.class_cerrar_modal').find($('input:checkbox'))[0]).prop(
          'checked',
          false
        );
        meses_marcados = $('.class_cerrar_modal')
          .find($('input:checkbox:checked'))
          .map(function () {
            return this.value;
          })
          .get();
      }
    }
    this._constant.filtro_header.meses = meses_marcados;
  }

  cambiarAnios(mesesSeleccionados: any) {
    // console.log(mesesSeleccionados);
  }
  cambiarEstatusCFDI(dato) {
    this._constant.estatus_filtro_cabecera = dato.target.value;
    this.filtrarMeses();
  }

  filtrarMeses() {
    let aux_todos = false;
    if (this._constant.filtro_header.meses.length === 0) {
      this._constant.isLoading = false;
      return false;
    }
    const aux = this._constant.filtro_header.meses.map((obj) => {
      if (Number(obj) === 0) {
        aux_todos = true;
      }
      return Number(obj);
    });
    if (aux_todos) {
      this._constant.filtro_header.meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,];
    } else {
      this._constant.filtro_header.meses = aux;
    }

    const aux_url = window.location.href;

    if (aux_url.indexOf("cancelaciones/emitidos") !== -1) {
      this._constant.kpi_uno_cancelaciones_emision = null;
      this._constant.kpi_dos_cancelaciones_emision = null;
      this._constant.datos_dashboard_cancelaciones_emision.grafico_uno = null;
      this._constant.datos_dashboard_cancelaciones_emision.grafico_dos = null;
      this._constant.datos_dashboard_cancelaciones_emision.lista_kpis = null;
      this._constant.datos_dashboard_cancelaciones_emision.lista_general = null;
      this._constant.isLoading = true;

      this._constant.filtro_header.tipo = "emision";
      this.compartidosService
        .getCancelaciones(this._constant.filtro_header)
        .subscribe(
          (data: any) => {
            const kpi_uno = new CardData();
            kpi_uno.titulo = "TOTAL CFDIS EMITIDOS";
            kpi_uno.monto = data.kpi_uno;
            const kpi_dos = new CardData();
            kpi_dos.titulo = "TOTAL CFDIS CANCELADOS";
            kpi_dos.monto = data.kpi_dos;

            this._constant.kpi_uno_cancelaciones_emision = kpi_uno;
            this._constant.kpi_dos_cancelaciones_emision = kpi_dos;
            this._constant.datos_dashboard_cancelaciones_emision.grafico_uno =
              data.grafico_uno;
            this._constant.datos_dashboard_cancelaciones_emision.grafico_dos =
              data.grafico_dos;
            this._constant.datos_dashboard_cancelaciones_emision.lista_kpis =
              data.lista_kpis;
            this._constant.datos_dashboard_cancelaciones_emision.lista_general =
              data.lista_general;
            this._constant.isLoading = false;
          },
          (error) => {
            this._constant.isLoading = false;
            Swal.fire('Advertencia Cancelaciones Emitadas', error.error.message, 'warning');
          },
          () => {
            this._constant.isLoading = false;
          }
        );
    }

    if (aux_url.indexOf("cancelaciones/recibidos") !== -1) {
      this._constant.filtro_header.tipo = "recepcion";

      this._constant.kpi_uno_cancelaciones_recepcion = null;
      this._constant.kpi_dos_cancelaciones_recepcion = null;
      this._constant.datos_dashboard_cancelaciones_recepcion.grafico_uno = null;
      this._constant.datos_dashboard_cancelaciones_recepcion.grafico_dos = null;
      this._constant.datos_dashboard_cancelaciones_recepcion.lista_kpis = null;
      this._constant.datos_dashboard_cancelaciones_recepcion.lista_general = null;
      this._constant.isLoading = true;

      this.compartidosService
        .getCancelaciones(this._constant.filtro_header)
        .subscribe(
          (data: any) => {
            this._constant.isLoading = false;

            const kpi_uno = new CardData();
            kpi_uno.titulo = "TOTAL CFDIS RECIBIDOS";
            kpi_uno.monto = data.kpi_uno;
            const kpi_dos = new CardData();
            kpi_dos.titulo = "TOTAL CFDIS CANCELADOS";
            kpi_dos.monto = data.kpi_dos;

            this._constant.kpi_uno_cancelaciones_recepcion = kpi_uno;
            this._constant.kpi_dos_cancelaciones_recepcion = kpi_dos;
            this._constant.datos_dashboard_cancelaciones_recepcion.grafico_uno =
              data.grafico_uno;
            this._constant.datos_dashboard_cancelaciones_recepcion.grafico_dos =
              data.grafico_dos;
            this._constant.datos_dashboard_cancelaciones_recepcion.lista_kpis =
              data.lista_kpis;
            this._constant.datos_dashboard_cancelaciones_recepcion.lista_general =
              data.lista_general;
          },
          (error) => {
            this._constant.isLoading = false;
            Swal.fire('Advertencia Cancelaciones Recibidas', error.error.message, 'warning');
          },
          () => {
            this._constant.isLoading = false;
          }
        );
    }

    if (aux_url.indexOf("home/proveedores") !== -1) {
      this._constant.isLoading = true;
      this._constant.datos_grafica_69_proveedores = null;
      this._constant.datos_grafica_69_b_proveedores = null;
      this._constant.datos_grafica_line_proveedores = null;
      // Graficas
      this._servicioNotificaciones
        .getGraficasConciliacion(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio
        )
        .subscribe((data: any) => {
          if (data.grafico_pie_69) {
            this._constant.datos_grafica_69_proveedores = data.grafico_pie_69;
          }
          if (data.grafico_pie69_b) {
            this._constant.datos_grafica_69_b_proveedores =
              data.grafico_pie69_b;
          }
          if (data.grafico_line) {
            this._constant.datos_grafica_line_proveedores = data.grafico_line;
          }
          this._constant.isLoading = false;
        });
      // Tablas
      this._servicioNotificaciones
        .getProveedoresListas(
          this._constant.filtro_header.rfc_solicitante,
          "no_localizable"
        )
        .subscribe(
          (data: any) => {
            this._constant.seleccionado_tabla_69 = "no_localizable";

            this._constant.isLoading = false;
            this._constant.lista_tabla_art_69 = data;
          },
          (error) => {
            this._constant.isLoading = false;
          }
        );

      this._servicioNotificaciones
        .getProveedoresListas(
          this._constant.filtro_header.rfc_solicitante,
          "definitivo"
        )
        .subscribe(
          (data: any) => {
            this._constant.seleccionado_tabla_69_B = "definitivo";

            this._constant.isLoading = false;
            this._constant.lista_tabla_art_69_B = data;
          },
          (error) => {
            this._constant.isLoading = false;
          }
        );
    }

    if (aux_url.indexOf("conciliacion/facturas") !== -1) {
      this._constant.isLoading = true;
      this.actualizarKPIS();
    }
    //#region Conciliación Nomina
    if (aux_url.indexOf("conciliacion/nomina") !== -1) {
      this._constant.isLoading = true;
      this.actualizarKPISNomina();
    }
    if (aux_url.indexOf('conciliacion/detalle_nomina/emision') !== -1) {
      this._constant.isLoading = true;
      this.actualizarKPISNominaDetalle();
    }
    //#endregion
    if (aux_url.indexOf("conciliacion/pagos") !== -1) {
      this._constant.isLoading = true;
      this.obtenerKpisPagos();
    }
    if (
      aux_url.indexOf("/conciliacion/detalle_pagos/emision") !== -1 ||
      aux_url.indexOf("/conciliacion/detalle_pagos/recepcion") !== -1
    ) {
      this._constant.isLoading = true;
      this.obtenerConciliacionPago(window.location.href.split("/")[window.location.href.split("/").length - 1]);
    }
    // Detalle Facturas
    if (
      aux_url.indexOf('conciliacion/detalle/emision') !== -1 ||
      aux_url.indexOf('conciliacion/detalle/recepcion') !== -1
    ) {
      this._constant.isLoading = true;
      this.obtenerDatos(
        window.location.href.split('/')[
        window.location.href.split('/').length - 1
        ]
      );
    }

    this.filtrar.emit();
  }

  cambia_anio(anio: any) {
    this._constant.filtro_header.anio = Number(anio.target.value);
    // console.log(this._constant.filtro_header.anio);
  }

  // Conciliacion Facturas
  actualizarKPIS() {
    console.log('actualizarKPIS  FiltroMesesComponent');
    this.compartidosService
      .getDFCIConciliacion(
        this._constant.filtro_header.rfc_solicitante,
        this._constant.filtro_header.anio,
        this._constant.filtro_header.meses.join("-"),
        this._constant.sistema_propio_emision,
        this._constant.sistema_propio_recepcion,
        this._constant.estatus_filtro_cabecera,
      )
      .subscribe(
        (data: any) => {
          // Emitidos
          // Timbrado SAT
          this._constant.kpi_timbrado_sat_e.monto = data.kpi_timbrado_sat_e;
          this._constant.kpi_timbrado_sat_e.titulo = "Timbrado SAT";
          this._constant.kpi_timbrado_sat_e.color_titulo = "azul";
          this._constant.kpi_timbrado_sat_e.color_monto = "verde_azul";
          this._constant.kpi_timbrado_sat_e.subtitulo = "";
          // Timbrado Sistema Nombre Dinamico
          this._constant.kpi_timbrado_sistema_e.monto = data.kpi_timbrado_sistema_e;
          this._constant.kpi_timbrado_sistema_e.titulo = "Timbrado  " + this._constant.nombre_spe.nombre;
          this._constant.kpi_timbrado_sistema_e.subtitulo = "";
          this._constant.kpi_timbrado_sistema_e.color_titulo = "morado";
          this._constant.kpi_timbrado_sistema_e.color_monto = "morado";
          // Resumen Faltante
          this._constant.kpi_faltante_e.monto = data.kpi_faltante_e;
          this._constant.kpi_faltante_e.titulo = "Faltante " + this._constant.nombre_spe.nombre;
          this._constant.kpi_faltante_e.subtitulo = "";
          this._constant.kpi_faltante_e.color_titulo = "morado";
          this._constant.kpi_faltante_e.color_monto = "rojo";
          this._constant.kpi_faltante_e.cantidad_diferencia = data.cantidad_diferencia_e;
          // Gaficas
          this._constant.grafica_diferencia_e = data.grafica_diferencia_e;
          this._constant.grafica_timbrado_sat_e = data.grafica_timbrado_sat_e;
          this._constant.grafica_timbrado_sistema_e = data.grafica_timbrado_sistema_e;

          // Recibidos
          // Timbrado SAT
          this._constant.kpi_timbrado_sat_r.monto = data.kpi_timbrado_sat_r;
          this._constant.kpi_timbrado_sat_r.titulo = "Timbrado SAT";
          this._constant.kpi_timbrado_sat_r.color_titulo = "azul";
          this._constant.kpi_timbrado_sat_r.color_monto = "sky_blue";
          this._constant.kpi_timbrado_sat_r.subtitulo = "";
          // Sistema Nombre Dinamico
          this._constant.kpi_timbrado_sistema_r.monto = data.kpi_timbrado_sistema_r;
          this._constant.kpi_timbrado_sistema_r.titulo = "Timbrado  " + this._constant.nombre_spr.nombre;
          this._constant.kpi_timbrado_sistema_r.color_titulo = "verde";
          this._constant.kpi_timbrado_sistema_r.color_monto = "verde";
          this._constant.kpi_timbrado_sistema_r.subtitulo = "";
          // Resumen Faltante
          this._constant.kpi_faltante_r.monto = data.kpi_faltante_r;
          this._constant.kpi_faltante_r.titulo = "Faltante " + this._constant.nombre_spr.nombre;
          this._constant.kpi_faltante_r.subtitulo = "";
          this._constant.kpi_faltante_r.cantidad_diferencia = data.cantidad_diferencia_r;
          this._constant.kpi_faltante_r.color_titulo = "morado";
          this._constant.kpi_faltante_r.color_monto = "rojo";

          // Graficas
          this._constant.grafica_diferencia_r = data.grafica_diferencia_r;
          this._constant.grafica_timbrado_sat_r = data.grafica_timbrado_sat_r;
          this._constant.grafica_timbrado_sistema_r = data.grafica_timbrado_sistema_r;
          this._constant.isLoading = false;
        },
        (error) => {
          console.log(error);
          this._constant.isLoading = false;
        }
      );
  }

  // Detalle Conciliacion Facturas
  obtenerDatos(tipo) {
    this._constant.detalle_tabla_conciliacion = null;
    this.compartidosService
      .getDetalleConciliacion(
        this._constant.filtro_header.rfc_solicitante,
        this._constant.filtro_header.anio,
        this._constant.filtro_header.meses.join('-'),
        tipo,
        tipo === 'emision' ? this._constant.sistema_propio_emision : this._constant.sistema_propio_recepcion,
        this._constant.estatus_filtro_cabecera
      )
      .subscribe(
        (data: any) => {
          // Kpi Timbrado sat
          this._constant.detalle_kpi_timbrado_sat.monto = data.kpi_timbrado_sat;
          this._constant.detalle_kpi_timbrado_sat.titulo = 'Timbrado SAT';
          this._constant.detalle_kpi_timbrado_sat.subtitulo = '';
          this._constant.detalle_kpi_timbrado_sat.color_titulo = 'verde_pistache';
          this._constant.detalle_kpi_timbrado_sat.color_monto = 'verde_azul';
          // Kpi Timbrado SPE
          this._constant.detalle_kpi_timbrado_sistema.monto = data.kpi_timbrado_sistema;
          if (tipo === 'emision') {
            this._constant.detalle_kpi_timbrado_sistema.titulo = 'Timbrado  ' + this._constant.nombre_spe.nombre;
          } else {
            this._constant.detalle_kpi_timbrado_sistema.titulo = 'Timbrado  ' + this._constant.nombre_spr.nombre;
          }
          this._constant.detalle_kpi_timbrado_sistema.subtitulo = '';
          this._constant.detalle_kpi_timbrado_sistema.color_titulo = 'morado';
          this._constant.detalle_kpi_timbrado_sistema.color_monto = 'verde';
          // Kpi Faltante
          this._constant.detalle_kpi_faltante.monto = data.kpi_faltante;
          if (tipo === 'emision') {
            this._constant.detalle_kpi_faltante.titulo = 'Faltante ' + this._constant.nombre_spe.nombre;
          } else {
            this._constant.detalle_kpi_faltante.titulo = 'Faltante ' + this._constant.nombre_spr.nombre;
          }
          this._constant.detalle_kpi_faltante.subtitulo = '';
          this._constant.detalle_kpi_faltante.color_titulo = 'rosa';
          this._constant.detalle_kpi_faltante.color_monto = 'rojo';
          this._constant.detalle_kpi_faltante.cantidad_diferencia = data.cantidad_diferencia;
          if (tipo !== 'recepcion') {
            this._constant.detalle_tabla_diferencia = data.tabla_diferencia_receptores;
            // Detalle grafica receptores
            this._constant.detalle_grafica = data.grafica_diferencia_receptores;
          } else {
            this._constant.detalle_tabla_diferencia = data.tabla_diferencia_emisores;
            // Detalle grafica receptores
            this._constant.detalle_grafica = data.grafica_diferencia_emisores;
          }

          // Grafica detalle
          this._constant.grafica_seleccionada = 'comparativo';
          this._constant.detalle_grafica_activa = data.grafica_comparativo_timbrado;
          this._constant.detalle_grafica_comparativo_timbrado = data.grafica_comparativo_timbrado;
          this._constant.detalle_grafica_faltantes_sistema = data.grafica_faltantes_sistema;
          this._constant.detalle_grafica_cfdis_faltantes = data.grafica_cfdis_faltantes;
          // Tabla conciliancion
          this._constant.detalle_tabla_conciliacion = data.tabla_conciliacion;
          this._constant.isLoading = false;
        },
        (error) => {
          this._constant.isLoading = false;
        }
      );
  }

  //#region Conciliacion Pagos
  obtenerKpisPagos() {
    this._constant.isLoading = true;
    this._constant.kpi_conciliacion_pago_timbrado_sat_e = new TarjetaTitulos();
    this._constant.kpi_conciliacion_pago_timbrado_sistema_e = new TarjetaTitulos();
    this._constant.kpi_conciliacion_pago_faltante_e = new TarjetaTitulos();
    this._constant.grafica_conciliacion_pago_formas_pago_e = null;
    this._constant.grafica_conciliacion_pago_formas_pago_r = null;
    this._constant.grafica_conciliacion_pago_timbrado_diferencia_e = null;
    this._constant.grafica_conciliacion_pago_timbrado_diferencia_r = null;

    this.compartidosService
      .getConciliacionPagos(
        this._constant.filtro_header.rfc_solicitante,
        this._constant.filtro_header.anio,
        this._constant.filtro_header.meses.join("-"),
        this.tipo_fecha = 'pago',
        this._constant.estatus_filtro_cabecera,
        this._constant.estatus_filtro_cabecera,
        this._constant.sistema_propio_recepcion
      )
      .subscribe(
        (data: any) => {
          // Emitidos
          // Timbrado SAT
          this._constant.kpi_conciliacion_pago_timbrado_sat_e.monto = data.kpi_timbrado_sat_e;
          this._constant.kpi_conciliacion_pago_timbrado_sat_e.titulo = "Timbrado SAT";
          this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_titulo = "azul";
          this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_monto = "verde_azul";
          this._constant.kpi_conciliacion_pago_timbrado_sat_e.subtitulo = "";
          // Timbrado Sistema Nombre Dinamico
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.monto =
            data.kpi_timbrado_sistema_e;
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.titulo =
            "Timbrado " + this._constant.nombre_spe.nombre;
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.subtitulo =
            "";
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_titulo =
            "morado";
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_monto =
            "morado";
          // Resumen Faltante
          this._constant.kpi_conciliacion_pago_faltante_e.monto =
            data.kpi_faltante_e;
          this._constant.kpi_conciliacion_pago_faltante_e.titulo =
            "Faltante " + this._constant.nombre_spe.nombre;
          this._constant.kpi_conciliacion_pago_faltante_e.subtitulo = "";
          this._constant.kpi_conciliacion_pago_faltante_e.color_titulo =
            "morado";
          this._constant.kpi_conciliacion_pago_faltante_e.color_monto = "rojo";
          this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia =
            data.cantidad_diferencia_e;
          // Gaficas

          // Recibidos
          // Timbrado SAT
          this._constant.kpi_conciliacion_pago_timbrado_sat_r.monto = data.kpi_timbrado_sat_r;
          this._constant.kpi_conciliacion_pago_timbrado_sat_r.titulo = "Timbrado SAT";
          this._constant.kpi_conciliacion_pago_timbrado_sat_r.color_titulo = "azul";
          this._constant.kpi_conciliacion_pago_timbrado_sat_r.color_monto = "sky_blue";
          this._constant.kpi_conciliacion_pago_timbrado_sat_r.subtitulo = "";
          // Sistema Nombre Dinamico
          this._constant.kpi_conciliacion_pago_timbrado_sistema_r.monto =
            data.kpi_timbrado_sistema_r;
          this._constant.kpi_conciliacion_pago_timbrado_sistema_r.titulo =
            "Timbrado " + this._constant.nombre_spr.nombre;
          this._constant.kpi_conciliacion_pago_timbrado_sistema_r.color_titulo =
            "verde";
          this._constant.kpi_conciliacion_pago_timbrado_sistema_r.color_monto =
            "verde";
          this._constant.kpi_conciliacion_pago_timbrado_sistema_r.subtitulo =
            "";
          // Resumen Faltante
          this._constant.kpi_conciliacion_pago_faltante_r.monto =
            data.kpi_faltante_r;
          this._constant.kpi_conciliacion_pago_faltante_r.titulo =
            "Faltante " + this._constant.nombre_spr.nombre;
          this._constant.kpi_conciliacion_pago_faltante_r.subtitulo = "";
          this._constant.kpi_conciliacion_pago_faltante_r.cantidad_diferencia =
            data.cantidad_diferencia_r;
          this._constant.kpi_conciliacion_pago_faltante_r.color_titulo =
            "morado";
          this._constant.kpi_conciliacion_pago_faltante_r.color_monto = "rojo";

          // Graficas
          this._constant.grafica_conciliacion_pago_formas_pago_e = data.grafica_formas_pago_e;
          this._constant.grafica_conciliacion_pago_formas_pago_r = data.grafica_formas_pago_r;
          this._constant.grafica_conciliacion_pago_timbrado_diferencia_e = data.grafica_timbrado_diferencia_e;
          this._constant.grafica_conciliacion_pago_timbrado_diferencia_r = data.grafica_timbrado_diferencia_r;

          this._constant.isLoading = false;
        },
        (error) => {
          console.log(error);
          this._constant.isLoading = false;
        }
      );
  }
  //#endregion


  //#region  Detalle Conciliacion Facturas Pago
  // obtenerKpisDetallePagos() {
  //   this._constant.isLoading = true;
  //   this.obtenerCinturonKpis();
  //   this.obtenerGraficaDiferencias();
  //   this.obtenerTablaDiferencias();
  // }
  // // Cinturon de Kpis
  obtenerCinturonKpis() {
    this._constant.kpi_conciliacion_pago_timbrado_sat_e = null;
    this._constant.kpi_conciliacion_pago_timbrado_sistema_e = null;
    this._constant.kpi_conciliacion_pago_faltante_e = null;
    this.compartidosService.getDetalleConciliacionPagos(
      this._constant.filtro_header.rfc_solicitante,
      this._constant.filtro_header.anio,
      this._constant.filtro_header.meses.join("-"),
      'emision', 'emision',
      this._constant.estatus_filtro_cabecera,
      'cinturon_kpis',
      this._constant.sistema_propio_emision
    ).subscribe((data: any) => {
      // Timbrado SAT
      this._constant.kpi_conciliacion_pago_timbrado_sat_e.monto = data.kpi_timbrado_sat;
      this._constant.kpi_conciliacion_pago_timbrado_sat_e.titulo = "Timbrado SAT";
      this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_titulo = "azul";
      this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_monto = "verde_azul";
      this._constant.kpi_conciliacion_pago_timbrado_sat_e.subtitulo = "";
      // Timbrado SPEI
      this._constant.kpi_conciliacion_pago_timbrado_sistema_e.monto = data.kpi_timbrado_sistema;
      this._constant.kpi_conciliacion_pago_timbrado_sistema_e.titulo = "Timbrado  " + this._constant.nombre_spe.nombre;
      this._constant.kpi_conciliacion_pago_timbrado_sistema_e.subtitulo = "";
      this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_titulo = "morado";
      this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_monto = "morado";
      // Resumen Faltante
      this._constant.kpi_conciliacion_pago_faltante_e.monto = data.kpi_faltante;
      this._constant.kpi_conciliacion_pago_faltante_e.titulo = "Faltante " + this._constant.nombre_spe.nombre;
      this._constant.kpi_conciliacion_pago_faltante_e.subtitulo = "";
      this._constant.kpi_conciliacion_pago_faltante_e.color_titulo = "morado";
      this._constant.kpi_conciliacion_pago_faltante_e.color_monto = "rojo";
      this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia = data.cantidad_diferencia;
      this._constant.isLoading = false;
    },
      (error) => {
        console.log(error);
        this._constant.isLoading = false;
      }
    );
  }

  //#region Conciliacion Pagos
  async obtenerConciliacionPago(tipo_detalle: string) {
    // this.obtenerCinturonKpis();
    await this.actualizarCinturonKPISConciliacionPagos(tipo_detalle);
    await this.actualizarGraficaDiferenciaReceptores(tipo_detalle);
    await this.actualizarTablaDiferenciaReceptores(tipo_detalle);
    await this.actualizarGraficaComparativoTimbrado(tipo_detalle);
    await this.actualizarGraficaFaltantes(tipo_detalle);
    await this.actualizarGraficaCFDISFaltantes(tipo_detalle);
    await this.actualizaTablaConciliacion(tipo_detalle);
    this._constant.isLoading = false;
  }


  actualizarCinturonKPISConciliacionPagos(tipo_detalle): Promise<any> {
    let sistema_nombre: string;
    return new Promise(resolve => {
      if (tipo_detalle === 'emision') {
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this.compartidosService.getDetalleConciliacionPagos(
        this._constant.filtro_header.rfc_solicitante,
        this._constant.filtro_header.anio,
        this._constant.filtro_header.meses.join("-"),
        tipo_detalle, 'pago',
        this._constant.estatus_filtro_cabecera,
        'cinturon_kpis',
        this.nombre_sistema = sistema_nombre
      ).subscribe((data: any) => {
        // Timbrado SAT
        this._constant.kpi_conciliacion_pago_timbrado_sat_e.monto = data.kpi_timbrado_sat;
        this._constant.kpi_conciliacion_pago_timbrado_sat_e.titulo = "Timbrado SAT";
        this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_titulo = "azul";
        this._constant.kpi_conciliacion_pago_timbrado_sat_e.color_monto = "verde_azul";
        this._constant.kpi_conciliacion_pago_timbrado_sat_e.subtitulo = "";
        // Timbrado SPEI
        this._constant.kpi_conciliacion_pago_timbrado_sistema_e.monto = data.kpi_timbrado_sistema;
        if (tipo_detalle === 'emision') {
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.titulo = "Timbrado " + this._constant.nombre_spe.nombre;
        } else {
          this._constant.kpi_conciliacion_pago_timbrado_sistema_e.titulo = "Timbrado " + this._constant.nombre_spr.nombre;
        }
        this._constant.kpi_conciliacion_pago_timbrado_sistema_e.subtitulo = "";
        this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_titulo = "morado";
        this._constant.kpi_conciliacion_pago_timbrado_sistema_e.color_monto = "morado";
        // Resumen Faltante
        this._constant.kpi_conciliacion_pago_faltante_e.monto = data.kpi_faltante;
        if (tipo_detalle === 'emision') {
          this._constant.kpi_conciliacion_pago_faltante_e.titulo = "Faltante " + this._constant.nombre_spe.nombre;
        } else {
          this._constant.kpi_conciliacion_pago_faltante_e.titulo = "Faltante " + this._constant.nombre_spr.nombre;
        }
        this._constant.kpi_conciliacion_pago_faltante_e.subtitulo = "";
        this._constant.kpi_conciliacion_pago_faltante_e.color_titulo = "morado";
        this._constant.kpi_conciliacion_pago_faltante_e.color_monto = "rojo";
        this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia = data.cantidad_diferencia;
        resolve();
      },
        (error) => {
          console.log(error);
          this._constant.isLoading = false;
          resolve();
        }
      );

    });
    // this._constant.isLoading = true;

    // this._constant.kpi_conciliacion_pago_timbrado_sat_e.cantidad_diferencia = null;
    // this._constant.kpi_conciliacion_pago_timbrado_sistema_e.cantidad_diferencia = null;
    // this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia = null;
    // this._constant.kpi_conciliacion_pago_faltante_e.monto = null;

    // this.compartidosService
    //   .getDetalleConciliacionPagos(
    //     this._constant.filtro_header.rfc_solicitante,
    //     this._constant.filtro_header.anio,
    //     this._constant.filtro_header.meses.join("-"),
    //     'emision', tipo_detalle === 'emision' ? 'emision' : 'pago',
    //     this._constant.estatus_filtro_cabecera,
    //     'cinturon_kpis'
    //   ).subscribe((data: any) => {
    //     this._constant.kpi_conciliacion_pago_timbrado_sat_e.cantidad_diferencia = data.kpi_timbrado_sat;
    //     this._constant.kpi_conciliacion_pago_timbrado_sistema_e.cantidad_diferencia = data.kpi_timbrado_sistema;
    //     this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia = data.cantidad_diferencia;
    //     this._constant.kpi_conciliacion_pago_faltante_e.monto = data.kpi_faltante;
    //     if (this.conciliacion_detalle_facturas()) {
    //       this._constant.isLoading = false;
    //     }
    //   },
    //     (error) => {
    //       console.log(error);
    //       this._constant.isLoading = false;
    //     }
    //   );
  }

  actualizarGraficaDiferenciaReceptores(tipo_detalle): Promise<any> {
    return new Promise(resolve => {
      let aux_respuesta;
      let sistema_nombre: string;
      if (tipo_detalle === 'emision') {
        aux_respuesta = 'grafica_diferencia_receptores';
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        aux_respuesta = 'grafica_diferencia_emisores';
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this._constant.isLoading = true;
      this._constant.grafica_diferencia_e = null;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join('-'),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          aux_respuesta,
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          if (tipo_detalle === 'emision') {
            this._constant.grafica_diferencia_e = data.grafica_diferencia_receptores;
          } else {
            this._constant.grafica_diferencia_e = data.grafica_diferencia_emisores;
          }
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.grafica_diferencia_e = new Object();
            resolve();
          }
        );
    });
  }
  actualizarTablaDiferenciaReceptores(tipo_detalle): Promise<any> {
    let aux_respuesta;
    let sistema_nombre: string;
    if (tipo_detalle === 'emision') {
      aux_respuesta = 'tabla_diferencia_receptores';
      sistema_nombre = this._constant.sistema_propio_emision;
    } else {
      aux_respuesta = 'tabla_diferencia_emisores';
      sistema_nombre = this._constant.sistema_propio_recepcion;
    }
    return new Promise(resolve => {
      this._constant.isLoading = true;
      this._constant.detalle_tabla_diferencia = null;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join("-"),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          aux_respuesta,
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          if (tipo_detalle === 'emision') {
            this._constant.detalle_tabla_diferencia = data.tabla_diferencia_receptores;
          } else {
            this._constant.detalle_tabla_diferencia = data.tabla_diferencia_emisores;
          }
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.isLoading = false;
            this._constant.detalle_tabla_diferencia = new Array<DetalleTablaDiferencia>();
            resolve();
          }
        );
    });
  }
  actualizarGraficaComparativoTimbrado(tipo_detalle): Promise<any> {
    return new Promise(resolve => {
      let sistema_nombre: string;
      if (tipo_detalle === 'emision') {
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this._constant.detalle_grafica_comparativo_timbrado = null;
      this._constant.isLoading = true;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join("-"),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          'grafica_comparativo_timbrado',
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          this._constant.detalle_grafica_comparativo_timbrado = data.grafica_comparativo_timbrado;
          this.actualizarDatosGraficaSeleccionada(this._constant.grafica_seleccionada);
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.detalle_grafica_comparativo_timbrado = new Object();
            resolve();
          }
        );
    });
  }
  actualizarGraficaFaltantes(tipo_detalle): Promise<any> {
    let sistema_nombre: string;
    return new Promise(resolve => {
      if (tipo_detalle === 'emision') {
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this._constant.isLoading = true;
      this._constant.detalle_grafica_faltantes_sistema = null;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join("-"),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          'grafica_faltantes_sistema',
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          this._constant.detalle_grafica_faltantes_sistema = data.grafica_faltantes_sistema;
          this.actualizarDatosGraficaSeleccionada(this._constant.grafica_seleccionada);
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.detalle_grafica_faltantes_sistema = new Object();
            resolve();
          }
        );
    });
  }
  actualizarGraficaCFDISFaltantes(tipo_detalle): Promise<any> {
    let sistema_nombre: string;
    return new Promise(resolve => {
      if (tipo_detalle === 'emision') {
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this._constant.isLoading = true;
      this._constant.detalle_grafica_cfdis_faltantes = null;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join("-"),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          'grafica_cfdis_faltantes',
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          this._constant.detalle_grafica_cfdis_faltantes = data.grafica_cfdis_faltantes;
          this.actualizarDatosGraficaSeleccionada(this._constant.grafica_seleccionada);
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.detalle_grafica_cfdis_faltantes = new Object();
            resolve();
          }
        );
    })
  }
  actualizaTablaConciliacion(tipo_detalle): Promise<any> {
    let sistema_nombre: string;
    return new Promise(resolve => {
      this._constant.isLoading = true;
      if (tipo_detalle === 'emision') {
        sistema_nombre = this._constant.sistema_propio_emision;
      } else {
        sistema_nombre = this._constant.sistema_propio_recepcion;
      }
      this._constant.detalle_tabla_conciliacion = null;
      this.compartidosService
        .getDetalleConciliacionPagos(
          this._constant.filtro_header.rfc_solicitante,
          this._constant.filtro_header.anio,
          this._constant.filtro_header.meses.join("-"),
          tipo_detalle, 'pago',
          this._constant.estatus_filtro_cabecera,
          'tabla_conciliacion',
          this.nombre_sistema = sistema_nombre
        ).subscribe((data: any) => {
          this._constant.detalle_tabla_conciliacion = data.tabla_conciliacion;
          resolve();
        },
          (error) => {
            console.log(error);
            this._constant.detalle_tabla_conciliacion = new Array<TablaConciliacion>();
            resolve();
          }
        );
    });
  }
  //#endregion

  public actualizarDatosGraficaSeleccionada(tipo) {
    this._constant.grafica_seleccionada = String(tipo);
    switch (tipo) {
      case 'comparativo':
        this._constant.detalle_grafica_activa = this._constant.detalle_grafica_comparativo_timbrado;
        break;
      case 'sistema':
        this._constant.detalle_grafica_activa = this._constant.detalle_grafica_faltantes_sistema;
        break;
      case 'faltantes':
        this._constant.detalle_grafica_activa = this._constant.detalle_grafica_cfdis_faltantes;
        break;
      default:
        break;
    }
  }


  actualizarKPISNomina() {
    this._constant.isLoading = true;

    this.compartidosService
      .getConciliacionNomina(
        this._constant.filtro_header.rfc_solicitante,
        this._constant.filtro_header.anio,
        this._constant.filtro_header.meses.join("-"),
        this._constant.estatus_filtro_cabecera,
        this._constant.nombre_spn.nombre
      )
      .subscribe(
        (data: any) => {
          // Emitidos
          // Timbrado SAT
          this._constant.kpi_conciliacion_nomina_timbrado_sat_e.monto = data.kpi_timbrado_sat_e;
          this._constant.kpi_conciliacion_nomina_timbrado_sat_e.titulo = "Timbrado SAT";
          this._constant.kpi_conciliacion_nomina_timbrado_sat_e.color_titulo = "azul";
          this._constant.kpi_conciliacion_nomina_timbrado_sat_e.color_monto = "verde_azul";
          this._constant.kpi_conciliacion_nomina_timbrado_sat_e.subtitulo = "";
          // Timbrado Sistema Nombre Dinamico
          this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.monto = data.kpi_timbrado_sistema_e;
          this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.titulo = "Timbrado " + this._constant.nombre_spn.nombre;
          this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.subtitulo = "";
          this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.color_titulo = "morado";
          this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.color_monto = "morado";
          // Resumen Faltante
          this._constant.kpi_conciliacion_nomina_faltante_e.monto = data.kpi_faltante_e;
          this._constant.kpi_conciliacion_nomina_faltante_e.titulo = "Faltante " + this._constant.nombre_spn.nombre;
          this._constant.kpi_conciliacion_nomina_faltante_e.subtitulo = "";
          this._constant.kpi_conciliacion_nomina_faltante_e.color_titulo = "morado";
          this._constant.kpi_conciliacion_nomina_faltante_e.color_monto = "rojo";
          this._constant.kpi_conciliacion_nomina_faltante_e.cantidad_diferencia = data.kpi_faltante_cfdi_e;
          // Graficas
          this._constant.grafica_conciliacion_nomina_timbrado_diferencia_e = data.grafica_diferencia_e;

          this._constant.isLoading = false;
        },
        (error) => {
          console.log(error);
          this._constant.isLoading = false;
        }
      );
  }


  actualizarKPISNominaDetalle() {
    this._constant.isLoading = true;
    this._constant.kpi_conciliacion_nomina_timbrado_sat_e = new TarjetaTitulos();
    this._constant.kpi_conciliacion_nomina_timbrado_sistema_e = new TarjetaTitulos();
    this._constant.kpi_conciliacion_nomina_faltante_e = new TarjetaTitulos();
    this._constant.detalle_tabla_conciliacion = null;
    this._constant.detalle_grafica_cfdis_faltantes = null;
    this._constant.detalle_grafica_faltantes_sistema = null;
    this._constant.detalle_grafica_comparativo_timbrado = null;
    this._constant.detalle_tabla_diferencia = null;
    this._constant.grafica_diferencia_e = null;

    this.compartidosService.getDetalleConciliacionNomina(
      this._constant.filtro_header.rfc_solicitante,
      this._constant.filtro_header.anio,
      this._constant.filtro_header.meses.join("-"),
      this._constant.estatus_filtro_cabecera,
      this._constant.nombre_spn.nombre
    ).subscribe((data: any) => {
      // Timbrado SAT
      this._constant.kpi_conciliacion_nomina_timbrado_sat_e.monto = data.kpi_timbrado_sat;
      this._constant.kpi_conciliacion_nomina_timbrado_sat_e.titulo = "Timbrado SAT";
      this._constant.kpi_conciliacion_nomina_timbrado_sat_e.color_titulo = "azul";
      this._constant.kpi_conciliacion_nomina_timbrado_sat_e.color_monto = "verde_azul";
      this._constant.kpi_conciliacion_nomina_timbrado_sat_e.subtitulo = "";
      // Timbrado SPEI
      this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.monto = data.kpi_timbrado_sistema;
      this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.titulo = "Timbrado " + this._constant.nombre_spn.nombre;
      this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.subtitulo = "";
      this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.color_titulo = "morado";
      this._constant.kpi_conciliacion_nomina_timbrado_sistema_e.color_monto = "morado";
      // Resumen Faltante
      this._constant.kpi_conciliacion_nomina_faltante_e.monto = data.kpi_faltante;
      this._constant.kpi_conciliacion_nomina_faltante_e.titulo = "Faltante " + this._constant.nombre_spn.nombre;
      this._constant.kpi_conciliacion_nomina_faltante_e.subtitulo = "";
      this._constant.kpi_conciliacion_nomina_faltante_e.color_titulo = "morado";
      this._constant.kpi_conciliacion_nomina_faltante_e.color_monto = "rojo";
      this._constant.kpi_conciliacion_pago_faltante_e.cantidad_diferencia = data.cantidad_diferencia;

      // Grafica Diferencia de Pago por Receptor
      this._constant.grafica_diferencia_e = data.grafica_diferencia_receptores;
      // Tabla Diferencia de Pago por Emisor
      this._constant.detalle_tabla_diferencia = data.tabla_diferencia_receptores;

      // Grafica Botones Comparativo Timbrado, Facturado por fehca, Cantidad CFDis Faltantes
      this._constant.detalle_grafica_comparativo_timbrado = data.grafica_comparativo_timbrado;
      this._constant.detalle_grafica_faltantes_sistema = data.grafica_faltantes_sistema;
      this._constant.detalle_grafica_cfdis_faltantes = data.grafica_cfdis_faltantes;

      // Tabla Comparativa Timbrado SAP - Sistema Propio
      this._constant.detalle_tabla_conciliacion = data.tabla_conciliacion;

      // this._constant.detalle_grafica_activa = this
      this.actualizarDatosGraficaSeleccionada(this._constant.grafica_seleccionada);
      this._constant.isLoading = false;
    },
      (error) => {
        console.log(error);
        this._constant.isLoading = false;
      }
    );
  }
}
