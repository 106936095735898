import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: 'input[appUppercase]'
})
export class UppercaseDirective {
  constructor(private el: ElementRef) { }
  @HostListener('input', ['$event']) onInputChange(event) {
    console.log(event.target.value);
    event.target.value = event.target.value.toUpperCase();
  }

}
