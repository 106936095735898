import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { LandingModalComponent } from './landing-modal/landing-modal.component';


@NgModule({
  declarations: [LandingPageComponent, LandingHeaderComponent, LandingModalComponent],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class LandingPageModule { }
