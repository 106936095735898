import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './login.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';


// if you need forms support:
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [LoginComponent, SpinnerComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    RecaptchaModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    // tslint:disable-next-line: deprecation
    HttpModule
  ],
  exports: [SpinnerComponent],
  providers: [
    LoginService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeQR8cUAAAAAEnCgRa0J38TlEXA7Yuu6kaVPysr' },
  ]
})
export class LoginModule { }
