export class FiltroPagos {
  fecha_factura: string;
  rfc_receptor: string;
  rfc_emisor: string;
  serie: string;
  folio: string;
  folio_fiscal: string;
  documento_relacionado: string;
  estatus_cfdi: number;
  codigo_postal: string;
  forma_pago: string;
  metodo_pago_dr: string;
  listtype: string;
  tipo?: string;
  razon_social_filtro_receptor: string;
  razon_social_filtro_emisor: string;
  columnas?: any;
  estatus_conciliacion: number;

  constructor() {
    this.fecha_factura = '';
    this.rfc_receptor = '';
    this.rfc_emisor = '';
    this.serie = '';
    this.folio = '';
    this.folio_fiscal = '';
    this.documento_relacionado = '';
    this.estatus_cfdi = 2;
    this.codigo_postal = '';
    this.forma_pago = '';
    this.metodo_pago_dr = '';
    this.listtype = 'list';
    this.razon_social_filtro_receptor = '';
    this.razon_social_filtro_emisor = '';
    this.estatus_conciliacion = 2;
  }
}
