import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { AutoActivacionService } from '../auto-activacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-suscipcion-procesada',
  templateUrl: './suscipcion-procesada.component.html',
  styleUrls: ['./suscipcion-procesada.component.css']
})
export class SuscipcionProcesadaComponent {

  @Input() datos_cuenta: AutoActivacion;
  public formulariocuenta = new FormGroup({
    clave_activacion: new FormControl('', Validators.required),
    correo: new FormControl('')
  });

  @Output() evt_siguiente = new EventEmitter();
  constructor(
    private _servicio_activacion: AutoActivacionService,
    private _constantes: ConstantsService
  ) { }

  AnimacionSlide(): void {
    const elem: any = document.getElementsByClassName('carousel-item')[4];
    // Elemento siguiente
    const next_elem: any = document.getElementsByClassName('carousel-item')[5];
    let pos = 0;
    const id = setInterval(frame, 10);
    const that = this;
    function frame() {
      if (pos === -600) {
        document.getElementById('sombra-interna').setAttribute('style', 'height:0px');
        clearInterval(id);
        next_elem.style.left = 0 + 'px';
        that.evt_siguiente.emit();
        elem.style.left = 0 + 'px';
        elem.style.display = 'none';
        const next_elem2: any = document.getElementsByClassName('carousel-item')[6];
        next_elem2.style.left = '71%';
        next_elem2.style.display = 'block';

      } else {
        pos = pos - 10;
        elem.style.left = pos + 'px';
        const aux = Number(next_elem.offsetLeft) - 10;
        next_elem.style.left = aux + 'px';
      }
    }
  }

  siguiente(): void {
    this.activarUsusario();
  }

  activarUsusario(): void {
    this._constantes.isLoading = true;
    this._servicio_activacion.activarUsuario(this.datos_cuenta.datos_cuenta.correo, this.datos_cuenta.datos_cuenta.clave_activacion).subscribe(
      (data) => {
        this.AnimacionSlide();
        Swal.fire('Éxito', '¡Usuario activado con exito!', 'success');
        this._constantes.isLoading = false;
      },
      (error) => {
        if (error.error && error.error.error  ) {
          Swal.fire('Atención', 'Ha ocurrido un error. <br> Detalle error: ' + error.error.error.message, 'error');
        } else {
          Swal.fire('Atención', 'Ha ocurrido un error inesperado. ', 'error');
        }
        this._constantes.isLoading = false;
      }
    );
  }

  reenviarCodigo(e): void {
    e.preventDefault();
    e.stopPropagation();
    this._constantes.isLoading = true;
    this._servicio_activacion.reenviarCodigo(this.datos_cuenta.datos_cuenta.correo).subscribe(
      ( data ) => {
        this._constantes.isLoading = false;
        Swal.fire('Éxito', '¡Codigo de verificación reenviado!', 'success');
      },
      (error) => {
        this._constantes.isLoading = false;
        if (error.error) {
          Swal.fire('Atención', 'Ha ocurrido un error. <br> Detalle error: ' + error.error.message, 'error');
        } else {
          Swal.fire('Atención', 'Ha ocurrido un error inesperado. ', 'error');
        }
      }
    );
  }

}
