export class TablaGIngresos {
  tipo_comprobante: string;
  subtotal: number;
  iva_retenido: number;
  isr_retenido: number;
  isr_trasladado: number;
  ieps_trasladado: number;
  total: number;
  total_cfdi: number;
  iva_trasladado: number;

  constructor() {
    this.tipo_comprobante = '';
    this.subtotal = 0;
    this.iva_retenido = 0;
    this.isr_retenido = 0;
    this.isr_trasladado = 0;
    this.ieps_trasladado = 0;
    this.total = 0;
    this.total_cfdi = 0;
    this.iva_trasladado = 0;
  }
}
