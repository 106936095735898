import { CompartidosService } from 'src/app/Compartidos/compartidos.service';
import { Component, OnChanges } from '@angular/core';
import { StorageService } from 'src/app/Compartidos/login/storage.service';
import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { DatosIniciales } from 'src/app/Compartidos/Entidades';
import { DescargaService } from '../descarga.service';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';
import { ReportesDescargaService } from 'src/app/Compartidos/reportes-descarga.service';
declare var $: any;
@Component({
  selector: 'app-lista-descargas',
  templateUrl: './lista-descargas.component.html',
  styleUrls: ['./lista-descargas.component.css']
})
export class ListaDescargasComponent implements OnChanges {
  public go = 'https://frontendmasters.com/';
  public datos_inciales: DatosIniciales;
  public lista_descargas: any;
  public host = '';
  public lista_forma_pago: any;
  public ESTATUS_CON = {
    1: 'Conciliado',
    0: 'faltante',
    2: 'Sin selección'
  };
  public ESTATUS_CFDi = {
    1: 'Vigente',
    0: 'Cancelada',
    2: 'Sin selección'
  };

  constructor(
    private _storageService: StorageService,
    private _loading: ConstantsService,
    private _servicioDescargas: DescargaService,
    private globales: GlobalsComponent,
    private _serviciosReporteDescarga: ReportesDescargaService
  ) {
    // this._servicioscompartidos.getUsoCFDICatalogo();
    // this._servicioscompartidos.getFormaPagoCatalogo().subscribe(
    //   (data) => {
    //     this.lista_forma_pago = data;
    //     this.host = this.globales.host;
    //     this.datos_inciales = this._storageService.getDatosIniciales();
    //     this._loading.isLoading = true;
    //     this._servicioDescargas.getListaDescargas('tolist', this.datos_inciales.usuario.id_corporativo).subscribe(
    //       // tslint:disable-next-line: no-shadowed-variable
    //       (data: any) => {
    //         data.map((x) => {
    //           this.lista_forma_pago.forEach(element => {
    //             if (element.Forma_Pago === x.filtro.forma_pago) {
    //               x.filtro.forma_pago = element.Descripcion;
    //             }
    //           });
    //           return x;
    //         });
    //         this.lista_descargas = data;
    //         setTimeout(() => {
    //           $('#tabla-archivos').DataTable({
    //             language: {
    //               emptyTable: 'Ningún dato disponible en esta tabla',
    //               info: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
    //               infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
    //               infoFiltered: '(filtrado de un total de _MAX_ registros)',
    //               infoPostFix: '',
    //               thousands: '',
    //               processing: 'Procesando',
    //               lengthMenu: 'Mostrar _MENU_',
    //               search: 'Buscar',
    //               zeroRecords: 'No se encontraron resultados',
    //               paginate: {
    //                 first: 'Primero',
    //                 last: 'Último',
    //                 next: 'Siguiente',
    //                 previous: 'Anterior',
    //               }
    //             }
    //           });
    //           this._loading.isLoading = false;
    //         }, 500);
    //       }
    //       , (error) => { }
    //       , () => { }
    //     );
    //   }
    // );
    this.inicialzarTabla();
  }

  ngOnChanges() {
    this.inicialzarTabla();
  }

  inicialzarTabla() {
    this._serviciosReporteDescarga.getUsoCFDICatalogo();
    this._serviciosReporteDescarga.getFormaPagoCatalogo().subscribe(
      (data) => {
        console.log('98 lista', data);

        this.lista_forma_pago = data;
        this.host = this.globales.host_descargas;
        this.datos_inciales = this._storageService.getDatosIniciales();
        this._loading.isLoading = true;
        this._servicioDescargas.getListaDescargas('tolist', this.datos_inciales.usuario.id_corporativo, this._loading.filtro_header.rfc_solicitante).subscribe(
          // tslint:disable-next-line: no-shadowed-variable
          (data: any) => {
            data.map((x) => {
              this.lista_forma_pago.forEach(element => {
                if (element.Forma_Pago === x.filtro.forma_pago) {
                  x.filtro.forma_pago = element.Descripcion;
                }
              });
              return x;
            });
            this.lista_descargas = data;
            $('#tabla-archivos').dataTable().fnDestroy();
            setTimeout(() => {
              $('#tabla-archivos').DataTable({
                language: {
                  emptyTable: 'Ningún dato disponible en esta tabla',
                  info: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
                  infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
                  infoFiltered: '(filtrado de un total de _MAX_ registros)',
                  infoPostFix: '',
                  thousands: '',
                  processing: 'Procesando',
                  lengthMenu: 'Mostrar _MENU_',
                  search: 'Buscar',
                  zeroRecords: 'No se encontraron resultados',
                  paginate: {
                    first: 'Primero',
                    last: 'Último',
                    next: 'Siguiente',
                    previous: 'Anterior',
                  }
                }
              });
              this._loading.isLoading = false;
            }, 500);
          }
          , (error) => { }
          , () => { }
        );
      }
    );
  }

  descargarArchivos(uuid_descarga: string, path_descarga: string) {
    // const url_archivos = `${this.globales.host}/descargas_masivas?tipo=download&identificador_descarga=${uuid_descarga}&path_descarga=${path_descarga}`;
    const url_archivos = `http://10.10.5.127:5001/?tipo=download&identificador_descarga=${uuid_descarga}&path_descarga=${path_descarga}`;
    fetch(url_archivos, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.datos_inciales.usuario.token}` } })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return null;
      })
      .catch(error => {
        console.error(error);
      })
      .then((respuesta) => {
        // setLoading(false);
        console.error(respuesta);
      });
  }

  descargarArchivos2(uuid_descarga: string, path_descarga: string) {
    const url_archivos = `${this.globales.host_descargas}/descargas_masivas?type=download&identificador_descarga=${uuid_descarga}&path_descarga=${path_descarga}`;
    window.open(url_archivos);
  }

}
