import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../login/storage.service';
import { DatosIniciales } from '../Entidades';
@Injectable()
export class HttpClient2 {
  datos_iniciales: DatosIniciales;
  constructor(private http: HttpClient, private _storageService: StorageService) { }

  createAuthorizationHeader(tipo?) {
    this.datos_iniciales = this._storageService.getDatosIniciales();
    const token = this.datos_iniciales.usuario.token;
    let headers;
    tipo === 'get' ? headers = new HttpHeaders({'Authorization': `Bearer ${token}`}) : headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` });
    return headers;
  }

  get(url) {
    const headers = this.createAuthorizationHeader('get');
    return this.http.get(url, {
      headers
    });
  }

  post(url, data, responseType?: any) {
    const headers  = this.createAuthorizationHeader();
    return this.http.post(url, data, {
      headers,
      responseType
    });
  }

  put(url, data) {
    const headers  = this.createAuthorizationHeader();
    return this.http.put(url, data, {
      headers
    });
  }

  delete(url) {
    const headers  = this.createAuthorizationHeader();
    return this.http.delete(url, {
      // headers: headers
      headers
    });
  }

}
