import { Component } from '@angular/core';
import 'es6-promise';
import 'isomorphic-fetch';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'template-frontend';
  value: number;
}
