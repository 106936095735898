import { reject } from 'lodash';
import { Component, OnInit, Output, EventEmitter, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DatosIniciales, TarjetaTitulos, CardData, TablaPreliminar } from '../Entidades';
import { StorageService } from '../login/storage.service';
import { ConstantsService } from '../global/constants.service';
import { CompartidosService } from '../compartidos.service';
import { Router } from '@angular/router';
import { GlobalsComponent } from '../global/global.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ReportesDescargaService } from '../reportes-descarga.service';
declare var $: any;

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Output() toggleSideBar = new EventEmitter();
  public datos_inciales = new DatosIniciales();

  cargando_datos = false;
  razon_social = '';
  nombre_comercial = '';
  usuario: string;
  url_actual: any = 'factura';
  url_anterior = '';

  constructor(
    private _storageService: StorageService,
    public _constant: ConstantsService,
    private _servicioCompartidos: CompartidosService,
    private _servicioReporteDescarga: ReportesDescargaService,
    public router: Router,
    private global: GlobalsComponent
  ) {




    this.router.events.subscribe((val) => {
      const aux_url = window.location.href;
      if ((aux_url.indexOf('conciliacion/pagos') !== -1) || (aux_url.indexOf('conciliacion/detalle_pagos') !== -1)) {
        this.url_actual = 'pagos';
      } else if ((aux_url.indexOf('conciliacion/nomina') !== -1) || (aux_url.indexOf('conciliacion/detalle_nomina') !== -1)) {
        this.url_actual = 'nomina';
      } else {
        this.url_actual = 'factura';
      }
      if (this.url_actual !== this.url_anterior) {
        this.url_anterior = this.url_actual;
        this.actualizarAnios(this.url_actual);
      }
    });

    this._constant.isLoading = false;
    this.datos_inciales = this._storageService.getDatosIniciales();
    this.razon_social = this.datos_inciales.usuario.rfc[0].razon_social;
    this._constant.filtro_header.rfc_solicitante = this.datos_inciales.usuario.rfc[0].rfc;
    // this.actualizarAnios('factura');
  }
  ngOnInit() {
    this.usuario = 'Usuario';
    if (this.url_actual !== this.url_anterior) {
      this.url_anterior = this.url_actual;
      console.log('Entro desde contructor');
      this.actualizarAnios(this.url_actual);
    }
  }

  ngAfterViewInit(): void {
    if (this.datos_inciales.usuario.rfc[0].rfc === 'XAXAXAXAXAX') {
      const permiso_configuracion = this.datos_inciales.usuario.menu.filter((x) => { return x.title === 'Configuración' });
      if (permiso_configuracion.length !== 0) {
        const permiso = permiso_configuracion[0].submenu;
        if (permiso.filter(y => { return y.title === 'Configuración RFC' }).length !== 0) {
          this.router.navigateByUrl('/home/configuracion');
        } else {
          this.router.navigateByUrl('/home/principal/principal');
        }
      }
      Swal.fire({
        title: '<strong>¡Hey!</strong>',
        icon: 'info',
        html:
          '<br>' +
          'Aún no tienes información para vivir tu experiencia Audit.' +
          '<br>' +
          '<br>' +
          '<h3> ¡Por favor configura tu RFC! </h3>',
        showCloseButton: true,
        confirmButtonText:
          '¡OK!'
      } as SweetAlertOptions);
    }
  }

  ngAfterViewChecked(): void {

  }

  async cambioRFC(rfc_sel: string) {
    this.razon_social = '<i class="fa fa-spinner fa-spin" style="font-size:24px"></i>';
    this.nombre_comercial = '<i class="fa fa-spinner fa-spin" style="font-size:24px"></i>';
    const aux = this.datos_inciales.usuario.rfc.filter(rfc => rfc.rfc === rfc_sel);
    this.razon_social = aux[0].razon_social;
    this._constant.filtro_header.rfc_solicitante = aux[0].rfc;
    const aux_url = window.location.href;

    if (aux_url.indexOf('preliminar') !== -1) {
      this._constant.isLoading = true;
      await this.actualizarAnios('factura');
      this.DatosPreliminar();
    }
    if (aux_url.indexOf('general') !== -1) {
      this._constant.isLoading = true;
      await this.actualizarAnios('factura');
      this.DatosGeneral();
    }
    if (aux_url.indexOf('cancelaciones/emitidos') !== -1) {
      this._constant.isLoading = true;
      await this.actualizarAnios('factura');
      this.DatosCancelacionEmision();
    }
    if (aux_url.indexOf('cancelaciones/recibidos') !== -1) {
      this._constant.isLoading = true;
      await this.actualizarAnios('factura');
      this.datosCancelacionRecepcion();
    }
    if (aux_url.indexOf('facturas/emitidos') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('boton_filtro_reportes2').click();
    }
    if (aux_url.indexOf('facturas/recibidos') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('boton_filtro_reportes2').click();
    }
    if (aux_url.indexOf('pagos/emitidos') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('boton_filtro_pagos2').click();
    }
    if (aux_url.indexOf('pagos/recibidos') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('boton_filtro_pagos2').click();
    }
    if (aux_url.indexOf('nomina/emitidos') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('boton_filtro_nomina').click();
    }
    if (aux_url.indexOf('home/proveedores') !== -1) {
      this._constant.isLoading = true;
      document.getElementById('button-filtrar-meses').click();
    }
    // Conciliación facturas
    if (aux_url.indexOf('conciliacion/facturas') !== -1) {
      await this.actualizarAnios('factura');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('/conciliacion/detalle/recepcion') !== -1) {
      await this.actualizarAnios('factura');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('/conciliacion/detalle/emision') !== -1) {
      await this.actualizarAnios('factura');
      document.getElementById('button-filtrar-meses').click();
    }
    // Conciliación pagos
    if (aux_url.indexOf('conciliacion/pagos') !== -1) {
      await this.actualizarAnios('pagos');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('conciliacion/detalle_pagos/emision') !== -1) {
      await this.actualizarAnios('pagos');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('conciliacion/detalle_pagos/recepcion') !== -1) {
      await this.actualizarAnios('pagos');
      document.getElementById('button-filtrar-meses').click();
    }
    // Conciliación Nomina
    if (aux_url.indexOf('conciliacion/nomina') !== -1) {
      await this.actualizarAnios('nomina');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('conciliacion/detalle_nomina/emision') !== -1) {
      await this.actualizarAnios('nomina');
      document.getElementById('button-filtrar-meses').click();
    }
    if (aux_url.indexOf('conciliacion/carga_masiva') !== -1) {
      // document.getElementById('boton_filtro_reportes').click();
      $('#boton_filtro_reportes').click();
    }
    if (aux_url.indexOf('descargas/lista') !== -1) {
      document.getElementById('inicialzarTabla').click();
    }

  }

  // Inician Funciones Datos Generales
  DatosGeneral() {
    // Egresos

    this._constant.tarjeta_egresos_general = null;
    this._constant.datos_resultado_resumen_general = null;
    this._constant.card_egresos_general = null;
    this._constant.card_egresos_nc_general = null;
    this._constant.card_nomina_general = null;
    this._constant.tabla_egresos_general = null;
    this._constant.card_ingreso_general = null;
    this._constant.card_nc_general = null;
    this._constant.datos_grafica_egreso_general = null;
    this._constant.tabla_ingresos_general = null;
    this._constant.tarjeta_presupuesto_general = null;
    this._constant.tarjeta_ingresos_general = null;
    this._constant.datos_grafica_ingreso_general = null;
    this._constant.isLoading = true;

    this._servicioCompartidos.getAlldataResumenGenerall(this._constant.filtro_header).subscribe((data: any) => {

      //====== Resultados Resumen General =====
      // Kpi Resumen General
      const aux_res_general = new CardData();
      aux_res_general.titulo = 'Resultado Presupuesto General';
      aux_res_general.icono = 'fas fa-money-bill';
      aux_res_general.color = this.global.COLORES.azul;
      aux_res_general.monto = data[0].kpi_presupuesto_general;
      this._constant.tarjeta_presupuesto_general = aux_res_general;
      // Grafica resultado general
      this._constant.datos_resultado_resumen_general = data[0].grafico_presupuesto_general;
      // =========================================

      // ===== Presupuestos Ingresos =====
      const card_ingresos_general = new TarjetaTitulos();
      card_ingresos_general.titulo = 'Presupuesto';
      card_ingresos_general.subtitulo = 'Ingresos';
      card_ingresos_general.monto = data[1].kpi_presupuesto_ingresos;
      this._constant.tarjeta_ingresos_general = card_ingresos_general;

      const card_ingresos = new CardData();
      card_ingresos.titulo = 'Ingresos';
      card_ingresos.icono = 'fas fa-money-bill';
      card_ingresos.color = this.global.COLORES.lila;
      card_ingresos.monto = data[1].kpi_ingresos;
      this._constant.card_ingreso_general = card_ingresos;

      const card_nci = new CardData();
      card_nci.titulo = 'Notas de Crédito';
      card_nci.icono = 'fas fa-money-bill';
      card_nci.color = this.global.COLORES.verde;
      card_nci.monto = data[1].kpi_notas_credito;

      this._constant.card_nc_general = card_nci;
      this._constant.datos_grafica_ingreso_general = data[1].grafico_uno;
      this._constant.datos_grafica3_ingreso_general = data[1].grafico_tres;
      this._constant.tabla_ingresos_general = data[1].tabla_ingresos;



      // ===== Presupuestos Egresos =====
      const aux_tarjeta = new TarjetaTitulos();
      aux_tarjeta.titulo = 'Presupuesto';
      aux_tarjeta.subtitulo = 'Egresos';
      aux_tarjeta.monto = data[2].kpi_resultados_egresos;
      this._constant.tarjeta_egresos_general = aux_tarjeta;

      const aux_card = new CardData();
      aux_card.titulo = 'Egresos';
      aux_card.icono = 'fas fa-money-bill';
      aux_card.color = this.global.COLORES.lila;
      aux_card.monto = data[2].kpi_egresos_egresos;
      this._constant.card_egresos_general = aux_card;

      const card_nc = new CardData();
      card_nc.titulo = 'Notas de Crédito';
      card_nc.icono = 'fas fa-money-bill';
      card_nc.color = this.global.COLORES.verde;
      card_nc.monto = data[2].kpi_egresos_notas_credito;
      this._constant.card_egresos_nc_general = card_nc;


      const card_nomina = new CardData();
      card_nomina.titulo = 'Nómina';
      card_nomina.icono = 'fas fa-money-bill';
      card_nomina.color = this.global.COLORES.azul;
      card_nomina.monto = data[2].kpi_egresos_nomina;
      this._constant.card_nomina_general = card_nomina;
      this._constant.tabla_egresos_general = data[2].tabla_egresos;
      this._constant.datos_grafica_egreso_general = data[2].grafico_dos;
      this._constant.datos_grafica4_egreso_general = data[2].grafico_cuatro;


      this._constant.isLoading = false;
    }, error => {
      console.log(error);
      this._constant.isLoading = false;
    }, () => {
      this._constant.isLoading = false;
    });


  }
  // Terminan funciones datos generales

  // Inician funciones Datos preliminar
  DatosPreliminar() {
    this._constant.card_kpi_preliminar = null;
    this._constant.datos_grafia = null;
    this._constant.datos_tabla_preliminar = null;
    this._constant.isLoading = true;
    this._servicioCompartidos.getPresupuestoPreliminar(this._constant.filtro_header).subscribe((data: any) => {
      console.log('aqui la data', data);
      const card = new CardData();
      card.titulo = 'Resultado presupuesto preliminar';
      card.descripcion = '';
      card.icono = 'fas fa-money-bill';
      card.color = this.global.COLORES.obscuro;
      card.monto = data.kp1 ? data.kp1 : 0;
      this._constant.card_kpi_preliminar = card;
      this._constant.datos_grafia = data.grafico;
      this._constant.datos_tabla_preliminar = data.tabla ? data.tabla : [new TablaPreliminar()];
      this._constant.isLoading = false;
    }, error => {
      console.log('error buscado');
      
      this._constant.isLoading = false;
      Swal.fire('Helloooooo!', 'This is a SweetAlert dialog!', 'success');
    }, () => {

      this._constant.isLoading = false;
    });
  }
  // Terminan funciones Datos preliminar

  //#region Datos Cancealciones Emitidas
  DatosCancelacionEmision() {

    this._constant.kpi_uno_cancelaciones_emision = null;
    this._constant.kpi_dos_cancelaciones_emision = null;
    this._constant.datos_dashboard_cancelaciones_emision.grafico_uno = null;
    this._constant.datos_dashboard_cancelaciones_emision.grafico_dos = null;
    this._constant.datos_dashboard_cancelaciones_emision.lista_kpis = null;
    this._constant.datos_dashboard_cancelaciones_emision.lista_general = null;
    this._constant.isLoading = true;

    this._constant.filtro_header.tipo = 'emision';
    this._servicioCompartidos.getCancelaciones(this._constant.filtro_header).subscribe((data: any) => {
      const kpi_uno = new CardData();
      kpi_uno.titulo = 'TOTAL CFDIS EMITIDOS';
      kpi_uno.monto = data.kpi_uno;
      const kpi_dos = new CardData();
      kpi_dos.titulo = 'TOTAL CFDIS CANCELADOS';
      kpi_dos.monto = data.kpi_dos;

      this._constant.kpi_uno_cancelaciones_emision = kpi_uno;
      this._constant.kpi_dos_cancelaciones_emision = kpi_dos;
      this._constant.datos_dashboard_cancelaciones_emision.grafico_uno = data.grafico_uno;
      this._constant.datos_dashboard_cancelaciones_emision.grafico_dos = data.grafico_dos;
      this._constant.datos_dashboard_cancelaciones_emision.lista_kpis = data.lista_kpis;
      this._constant.datos_dashboard_cancelaciones_emision.lista_general = data.lista_general;
      this._constant.isLoading = false;

    }, error => {

      this._constant.isLoading = false;
    }, () => {

      this._constant.isLoading = false;
    });

  }
  //#endregion

  //#region Datos Cancelacion Recepcion

  datosCancelacionRecepcion() {
    this._constant.filtro_header.tipo = 'recepcion';

    this._constant.kpi_uno_cancelaciones_recepcion = null;
    this._constant.kpi_dos_cancelaciones_recepcion = null;
    this._constant.datos_dashboard_cancelaciones_recepcion.grafico_uno = null;
    this._constant.datos_dashboard_cancelaciones_recepcion.grafico_dos = null;
    this._constant.datos_dashboard_cancelaciones_recepcion.lista_kpis = null;
    this._constant.datos_dashboard_cancelaciones_recepcion.lista_general = null;
    this._constant.isLoading = true;

    this._servicioCompartidos.getCancelaciones(this._constant.filtro_header).subscribe((data: any) => {
      this._constant.isLoading = false;
      const kpi_uno = new CardData();
      kpi_uno.titulo = 'TOTAL CFDIS RECIBIDOS';
      kpi_uno.monto = data.kpi_uno;
      const kpi_dos = new CardData();
      kpi_dos.titulo = 'TOTAL CFDIS CANCELADOS';
      kpi_dos.monto = data.kpi_dos;

      this._constant.kpi_uno_cancelaciones_recepcion = kpi_uno;
      this._constant.kpi_dos_cancelaciones_recepcion = kpi_dos;
      this._constant.datos_dashboard_cancelaciones_recepcion.grafico_uno = data.grafico_uno;
      this._constant.datos_dashboard_cancelaciones_recepcion.grafico_dos = data.grafico_dos;
      this._constant.datos_dashboard_cancelaciones_recepcion.lista_kpis = data.lista_kpis;
      this._constant.datos_dashboard_cancelaciones_recepcion.lista_general = data.lista_general;

    }, error => {
      this._constant.isLoading = false;

    }, () => {
      this._constant.isLoading = false;

    });

  }
  //#endregion


  Salir() {
    this._storageService.logout();
  }


  actualizarAnios(tipo: 'factura' | 'pagos' | 'nomina'): Promise<any> {
    return new Promise(resolve => {
      this._constant.isLoading = true;
      this._servicioReporteDescarga.getFiltroAnioRfc(tipo, this._constant.filtro_header.rfc_solicitante).subscribe(
        (data: any) => {
          const aux = data.map(x => {
            const anio = {
              text: x,
              value: x
            };
            return anio;
          });
          this._constant.isLoading = false;
          this._constant.filtro_header.anios = aux;
          if (this._constant.filtro_header.anios.length === 0) {
            this._constant.filtro_header.anio = 0;
          } else {
            this._constant.filtro_header.anio = this._constant.filtro_header.anios[this._constant.filtro_header.anios.length - 1].value;
          }
          resolve();
        },
        (error) => {
          this._constant.isLoading = false;
          console.log(error.erro);
          reject(error);
        }
      );
    });
  }

}
