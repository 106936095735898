import { Component, Input, OnInit } from '@angular/core';
import { CardData } from '../Entidades/CardData';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.css']
})
export class TarjetaComponent implements OnInit {
  @Input() card: CardData;
  @Input() porcentaje?: false;
  @Input() mensaje_error?: string;

  /**
   * TarjetaComponent
   * Recibe por medio de un input un objeto de tipo CardData
   * monto y descripcion son campos requerido
   */
  constructor() {};

  ngOnInit(): void {
    console.log('Hello there !!');
    // Swal.fire('Helloooooo!', 'This is a SweetAlert dialog!', 'success');

  };

}
