import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Usuario } from 'src/app/Compartidos/Entidades';

@Component({
  selector: 'app-usuarios-formulario',
  templateUrl: './usuarios-formulario.component.html',
  styleUrls: ['./usuarios-formulario.component.css']
})
export class UsuariosFormularioComponent implements OnInit {
  formulario: FormGroup;
  public cargando = false;
  public mensaje_error = '';
  public aux_error = false;
  public show = false;
  public usuario = '';
  public contrasena = '';
  public ojo = 'ojo fas fa-eye';
  public usuarios = new Usuario();
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.iniciarFormulario();
  }

  iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      usuario: ['', Validators.required],
      contrasena: ['', [
        Validators.required, Validators.minLength(4),
        this.validarContrasena
      ]]
    });
  }

  private validarContrasena(control: AbstractControl) {
    const contrasena = control.value;
    let error = null;
    const regex = new RegExp('^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$');
    if (regex.test(contrasena)) {
      error = 'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico';
    }
    return error;
  }

  password() {
    this.show = !this.show;
    if (this.show) {
      this.ojo = 'ojo far fa-eye-slash';
    } else {
      this.ojo = 'ojo fas fa-eye';
    }
  }

//   [8/1 18:19] Oscar Alberto Ramirez Anaya
//     {
//     "flag" : "create|confirm|auth",
//     "username": "oscar.ramirez10",
//     "password": "Adminr5310$",
//     "email" : "oscar.ramirez@next-technologies.com.mx",
//     "nombre" : "Oscar Ramirez",
//     "id_corporativo" : 1,
//     "code" : "228071"
// }


}
