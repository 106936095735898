import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { animate, style, trigger, transition, state } from '@angular/animations';
import { Router } from '@angular/router';
import { StorageService } from '../login/storage.service';

@Component({
   selector: 'app-sidebar',
   templateUrl: './sidebar.component.html',
   styleUrls: ['./sidebar.component.css'],
   animations: [
      trigger('expandCollapse', [
         state('expand', style({ height: '*', overflow: 'hidden', display: 'block' })),
         state('collapse', style({ height: '0px', overflow: 'hidden', display: 'block' })),
         state('active', style({ height: '*', overflow: 'hidden', display: 'block' })),
         transition('expand <=> collapse', animate(100)),
         transition('active => collapse', animate(100))
      ])
   ]
})
export class SidebarComponent implements OnInit {
   @Output() toggleSideBar = new EventEmitter();
   @Output() toggleSideBar2 = new EventEmitter();
   navProfileState = 'collapse';

   menus: any[] = [];
   constructor(
      private rutaActiva: Router,
      private _storageService: StorageService
   ) {
      this.menus = this._storageService.getDatosIniciales().usuario.menu;
   }

   ngOnInit() {
   }

   toggleNavProfile() {
      if (this.navProfileState === 'collapse') {
         this.navProfileState = 'expand';
      } else {
         this.navProfileState = 'collapse';
      }
   }

   expandCollapseSubmenu(currentMenu, allMenu, active) {
      for (const menu of allMenu) {
         if (menu !== currentMenu) {
            menu.state = 'collapse';
         }
      }
      if (currentMenu.state === 'expand' || (active.isActive && !currentMenu.state)) {
         currentMenu.state = 'collapse';
      } else {
         currentMenu.state = 'expand';
      }
   }

   mostrarLoading(url) {
      if (this.rutaActiva.url !== url) {
        const barra_menu = document.getElementById('sidebar');
        let arr;
        arr = barra_menu.className.split(' ');
        if ( arr.indexOf('sidebar-oculto') === -1 ) {
          this.toggleSideBar.emit(true);
        } else {
          this.toggleSideBar.emit();
        }
      }
   }

}
