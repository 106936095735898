import { Component, OnInit, Injectable, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct, NgbDate, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';


const I18N_VALUES = {
  'es': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  }
};
@Injectable()
export class I18n {
  language = 'es';
}
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}


@Component({
  selector: 'app-range-input-date',
  templateUrl: './range-input-date.component.html',
  styleUrls: ['./range-input-date.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class RangeInputDateComponent implements OnInit, OnChanges {
  @Input() fecha_inicial: NgbDate;
  @Input() fecha_final: NgbDate;
  @Input() texto_label: string;
  @Output() onFechaSelected = new EventEmitter();

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  origen: string;
  zip_archivo: string;
  nombre_archivo: string;
  model: NgbDateStruct;
  date: { year: number, month: number };

  constructor(
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
  ) {
  }

  ngOnInit() {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', (this.calendar.getToday().day - 1));
    this.onFechaSelected.emit({
      value: `${this.formatDate(this.fromDate)} - ${this.formatDate(this.toDate)}`,
      fecha_inicial: this.formatDate(this.fromDate),
      fecha_final: this.formatDate(this.toDate)
    });
  }

  ngOnChanges(changes) {
    console.log(changes);
    if (changes.fecha_inicial && !changes.fecha_inicial.currentValue) {
      this.fromDate = this.fecha_final;
      this.toDate = this.fecha_final;
      this.onFechaSelected.emit({
        value: null,
        fecha_inicial: null,
        fecha_final: null
      });
    } else if (changes.fecha_final && changes.fecha_inicial) {
      this.fromDate = this.fecha_inicial;
      this.toDate = this.fecha_final;
    }

    // this.fromDate = null;
    // this.toDate = null;
  }


  onDateSelectionCarga(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.onFechaSelected.emit({
        value: `${this.formatDate(this.fromDate)} - ${this.formatDate(this.toDate)}`,
        fecha_inicial: this.formatDate(this.fromDate),
        fecha_final: this.formatDate(this.toDate)
      });
    }
  }
  isHovered(date: NgbDate) {
    return (this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate));
  }

  isInside(date: NgbDate) {
    return (date.after(this.fromDate) && date.before(this.toDate));
  }

  isRange(date: NgbDate) {
    return (date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date));
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  formatDate(date: NgbDateStruct): string {
    return `${date.day < 10 ? '0' + date.day : date.day}/${date.month < 10 ? '0' + date.month : date.month}/${date.year}`;
    // return `${date.year}-${date.month < 10 ? "0" + date.month : date.month}-${date.day < 10 ? "0" + date.day : date.day}`;
  }

}
