export class FiltroCabecera {
    rfc_solicitante: string;
    anio: number;
    anios: any;
    meses: Array<number>;
    tipo?: string;
    columnas?: any;

    constructor() {
        this.rfc_solicitante = '';
        this.anio = Number(new Date().getFullYear());
        this.anios = this.years(Number(new Date().getFullYear() - 1));
        this.meses = [new Date().getMonth() + 1];
    }


  years(startYear): any {
      const currentYear = new Date().getFullYear();
      const years = new Array<any>();
      startYear = startYear || currentYear;

      while ( startYear <= currentYear ) {
          const aux = { text: startYear, value: startYear };
          years.push(aux);
          startYear++;
      }
      return years.reverse();
  }
}
