import { Injectable } from '@angular/core';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';

@Injectable({
  providedIn: 'root'
})
export class DescargaService {

  constructor(
    private _http: HttpClient2,
    private globals: GlobalsComponent
  ) { }

  generarSolicitudDescargaProgramada(solicitud: any) {
    return this._http.post(`${this.globals.host_descargas}/descargas_masivas`, solicitud);
  }

  getListaDescargas(type: string, corporativo_id: number, rfc_emisor?) {
    return this._http.post(`${this.globals.host_descargas}/descargas_masivas`, { type, corporativo_id, rfc_emisor });
  }
}
