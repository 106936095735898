import { Component, OnInit, Input } from '@angular/core';
import { GlobalsComponent } from '../global/global.component';
import { UrlSanitizedPipe } from '../url-sanitized.pipe';

@Component({
  selector: 'app-visor-factura',
  templateUrl: './visor-factura.component.html',
  styleUrls: ['./visor-factura.component.css']
})
export class VisorFacturaComponent implements OnInit {
  // @Input() respuestaDetallesFActura;
  @Input() PDF_URL;
  otros_datos = 'cargando';
  factura_pdf_url: string;

  constructor(
    public globals: GlobalsComponent
  ) { }

  ngOnChanges() {
    // this.otros_datos = this.respuestaDetallesFActura;
    this.factura_pdf_url = this.PDF_URL;
    console.log('changes', this.factura_pdf_url);
    
  }


  ngOnInit() {
  }

}
