import { Component, OnInit, Input } from "@angular/core";
import { TarjetaTitulos } from "../Entidades";

@Component({
  selector: "app-tarjeta-diferencias",
  templateUrl: "./tarjeta-diferencias.component.html",
  styleUrls: ["./tarjeta-diferencias.component.css"],
})
export class TarjetaDiferenciasComponent implements OnInit {
  @Input() data: TarjetaTitulos = new TarjetaTitulos();
  @Input() monto;
  @Input() moneda?: false;
  constructor() {}

  ngOnInit() {}
}
