import { Injectable } from '@angular/core';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { GlobalsComponent } from './global/global.component';
import { FiltroCabecera } from './Entidades/FiltroCabecera';
import { TiposLista } from './Entidades';
@Injectable({
  providedIn: 'root',
})
export class CompartidosService {
  constructor(
    private _http: HttpClient2,
    private globals: GlobalsComponent
  ) { }

  // *********** PRELIMINAR ********** //
  /**
   * Regresa los datos de la grafica
   * @param filtro Filtro del header
   */
  GetPresupuestoPreliminar(filtro: FiltroCabecera) {
    console.log('filtro A', filtro);
    return this._http.post(
      `${this.globals.host_dashboards}/data_engine/dashboard/presupuesto_preliminar`,
      filtro
    );
  }
  getPresupuestoPreliminar(filtro: FiltroCabecera) {

    console.log('filtro B', filtro);
    

    return this._http.post(
      `${this.globals.host_dashboards}/dsh_prespuesto_preliminar`,
      filtro
    );
  }

  // Servicios Presupuesto preliminar
  GetKPI(filtro: FiltroCabecera) {
    console.log('GetKPI esta api no se consume ');
    return this._http.post(
      `${this.globals.host}/data_engine/kpi1/presupuesto_preliminar`,
      filtro
    );
  }

  // Servicios Presupuesto preliminar
  GetDatosTablaPreliminar(filtro: FiltroCabecera) {
    console.log('GetDatosTablaPreliminar esta api no se consume ')
    return this._http.post(
      `${this.globals.host}/data_engine/tabla1/presupuesto_preliminar`,
      filtro
    );
  }
  getDFCIConciliacion(rfc_solicitante: string, anio: number, meses: string, sistema_propio_emision: string, sistema_propio_recepcion: string, estatus: "vigente" | "cancelado") {
    console.log('getDFCIConciliacion compartidos service');
    return this._http.get(
      `${this.globals.host_conciliacion}/conciliacion_cfdis?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&sistema_propio_emision=${sistema_propio_emision}&sistema_propio_recepcion=${sistema_propio_recepcion}&estatus=${estatus}`
    );
  }

  getDetalleConciliacion(
    rfc_solicitante: string,
    anio: number,
    meses: string,
    tipo: "recepcion" | "emision",
    nombre_sistema_propio: string,
    estatus: string
  ) {
    return this._http.get(
      `${this.globals.host_conciliacion}/conciliacion_cfdis_detalle?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&tipo=${tipo}&nombre_sistema_propio=${nombre_sistema_propio}&estatus=${estatus}`
    );
  }

  getConciliacionPagos(
    rfc_solicitante: string,
    anio: number,
    meses: string,
    tipo_fecha: 'pago' | 'emision',
    estatus: 'vigente' | 'cancelado',
    sistema_propio_emision: string,
    sistema_propio_recepcion: string
  ) {
    return this._http.get(
      `${this.globals.host_conciliacion}/conciliacion_pagos?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&tipo_fecha=${tipo_fecha}&estatus=${estatus}&sistema_propio_emision=${sistema_propio_emision}&sistema_propio_recepcion=${sistema_propio_recepcion}`
    );
  }
  getDetalleConciliacionPagos(
    rfc_solicitante: string,
    anio: number,
    meses: string,
    tipo: 'emision' | 'recepcion',
    tipo_fecha: 'pago' | 'emision',
    estatus: 'vigente' | 'cancelado',
    respuesta: 'cinturon_kpis' | 'grafica_diferencia_receptores'
      | 'tabla_diferencia_receptores' | 'grafica_comparativo_timbrado'
      | 'grafica_faltantes_sistema' | 'grafica_cfdis_faltantes'
      | 'tabla_conciliacion',
    nombre_sistema: string) {
    return this._http.get(`${this.globals.host_conciliacion}/conciliacion_pagos_detalle?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&tipo=${tipo}&tipo_fecha=${tipo_fecha}&estatus=${estatus}&respuesta=${respuesta}&nombre_sistema=${nombre_sistema}`);
  }

  //#region
  getConciliacionNomina(
    rfc_solicitante: string,
    anio: number,
    meses: string,
    estatus: "vigente" | "cancelado",
    nombre_sistema_propio
  ) {
    return this._http.get(
      `${this.globals.host_conciliacion}/conciliacion_nomina?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&estatus=${estatus}&sistema_propio_nomina=${nombre_sistema_propio}`
    );
  }

  getDetalleConciliacionNomina(
    rfc_solicitante: string,
    anio: number,
    meses: string,
    estatus: "vigente" | "cancelado",
    nombre_sistema_propio: string
  ) {
    return this._http.get(`${this.globals.host}/conciliacion_nomina_detalle?rfc_solicitante=${rfc_solicitante}&anio=${anio}&meses=${meses}&nombre_sistema_propio=${nombre_sistema_propio}&estatus=${estatus}`);
  }

  /**
  * * Este servicio se consume en mas de un componentes
  * * por lo cual se dejara en servicios compartidos
  */
  getCancelaciones(filtro: FiltroCabecera) {
    console.log('getCancelaciones##');
    return this._http.post(
      `${this.globals.host_dashboards}/dsh_cancelaciones`,
      filtro
    );
  }
  getAlldataResumenGenerall(filtro: FiltroCabecera) {
    return this._http.post(
      `${this.globals.host_dashboards}/dsh_general`,
      filtro
    );
  }

}
