export class DashboardCencelacionEmision {
    grafico_dos: GraficaDashboard;
    grafico_uno: GraficaDashboard;
    kpi_dos: string;
    kpi_uno: string;
    lista_general: any[];
    lista_kpis: ObjetoKPI[];
}

class GraficaDashboard {
    chart: any;
    plotOptions: any;
    series: any;
    title: any;
    tooltip: any;
}

class ObjetoKPI {
    cantidad: number;
    estatus: string;
    monto: number;
    porcentaje: number;
    tipo: string;
}