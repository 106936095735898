export interface ITarjetaTitulos {
    titulo: string;
    subtitulo: string;
    monto: string;
}

export class TarjetaTitulos implements ITarjetaTitulos {
    titulo: string;
    subtitulo: string;
    monto: string;
    cantidad_diferencia?: number;
    color_titulo?: 'azul' | 'verde' | 'morado' | 'rojo' | 'rosa' | 'sky_blue' | 'verde_pistache' | 'verde_azul';
    color_monto?: 'azul' | 'verde' | 'morado' | 'rojo' | 'rosa' | 'sky_blue' | 'verde_pistache' | 'verde_azul';

    constructor() {
        this.titulo = 'Cargando...';
        this.subtitulo = 'Cargando...';
        this.monto = '0';
    }
}
