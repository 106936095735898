import { Injectable } from '@angular/core';
import { HttpClient2 } from './Servicios-Compartidos/http-clien.service';
import { GlobalsComponent } from './global/global.component';

@Injectable({
  providedIn: 'root'
})
export class ApiNotificacionesService {

  constructor(
    private _http: HttpClient2,
    private globals: GlobalsComponent
  ) { }

  // Proveedores
  getGraficasConciliacion(rfc_solicitante: string, anio: any) {
    return this._http.post(
      `${this.globals.host_notificaciones}/proveedores-conciliacion`,
      { rfc_solicitante }
    );
  }
  getListaNoLocalizable(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=no_localizable&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaEliminados(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=eliminados&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaDefinitivo(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=definitivo&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaFavorables(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=favorables&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaSentencias(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=sentencias&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaDesvirtuados(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=desvirtuados&rfc_solicitante=${rfc_solicitante}`
    );
  }
  getListaPresuntos(rfc_solicitante: string) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=presuntos&rfc_solicitante=${rfc_solicitante}`
    );
  }

  getProveedoresListas(
    rfc_solicitante,
    tipo_lista:
      | "no_localizable"
      | "eliminados"
      | "definitivo"
      | "favorables"
      | "sentencias"
      | "desvirtuados"
      | "presuntos"
  ) {
    return this._http.get(
      `${this.globals.host_notificaciones}/proveedores-listas?tipo_lista=${tipo_lista}&rfc_solicitante=${rfc_solicitante}`
    );
  }
}
