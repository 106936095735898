export class FacturaNomina {
    _id: string;
    header: HeaderFactura;
    fiscal: Fiscal;
    emisor: Emisor;
    receptor: Receptor;
    complementos: any;
    conceptos: {
        "conceptos": ConceptosFactura[]
    };
    nomina: NominaFactura;
}

class HeaderFactura {
    fecha: string;
    folio: string;
    forma_pago: string;
    lugar_expedicion: string;
    metodo_pago: string;
    moneda: string;
    tipo_cambio: string;
    no_certificado: string;
    serie: string;
    subtotal: string;
    tipo_comprobante: string;
    total: string;
    descuento: number;
    condiciones_pago: string;
    version: string;
}

class Fiscal {
    fecha_timbrado: string;
    no_certificado_sat: string;
    rfc_prov_certif: string;
    uuid: string;
}

class Emisor {
    nombre: string;
    rfc: string;
    regimen_fiscal: string;
}

class Receptor {
    nombre: string;
    rfc: string;
    uso_cfdi: string;

}

class ConceptosFactura {
    descuento: string;
    valor_Unitario: string;
    unidad: string;
    importe: string;
    descripcion: string;
    clave_unidad: string;
    clave_prod_serv: string;
    cantidad: string;
    impuestos_trasladados: string;
    impuestos_retenidos: string;
}

class NominaFactura {
    version: string;
    tipo_nomina: string;
    fecha_pago: string;
    fecha_inicial_pago: string;
    fecha_final_pago: string;
    num_dias_pagados: string;
    total_percepciones: string;
    total_deducciones: string;
    total_otros_pagos: string;
    nomina_emisor: any;
    nomina_deducciones: NominaDeducciones;
    nomina_receptor: NominaReceptor;
    nomina_otros_pagos: NominaOtrosPagos;
    nomina_percepciones: NominaPercepciones
}

class NominaDeducciones {
    deduccion: Deduccion[];
    total_otras_deducciones: string;
    total_impuestos_retenidos: number;
}
class Deduccion {
    tipo_deduccion: string;
    clave: string;
    concepto: string;
    item: string;
}

class NominaReceptor {
    curp: string;
    num_seguridad_social: string;
    fecha_inicio_rel_laboral: string;
    antiguedad: string;
    tipo_contrato: string;
    sindicalizado: string;
    tipo_jornada: string;
    tipo_regimen: string;
    num_empleado: string;
    departamento: string;
    puesto: string;
    riesgo_puesto: string;
    periodicidadPago: string;
    banco: string;
    cuenta_bancaria: string;
    salario_base_cot_apor: string;
    salario_diario_integrado: string;
    ClaveEntFed: string;
}

class NominaPercepciones {
    total_exento: string;
    total_gravado: string;
    total_sueldos: string;
    total_separacion_indemnizacion: number;
    total_pension_retiro: number;
    percepcion: PercepcionNomina[]
}

class PercepcionNomina {
    clave: string;
    concepto: string;
    importe_exento: string;
    importe_gravado: string;
    tipo_percepcion: string;
}

class NominaOtrosPagos {
    otros_pagos: OtrosPagos[]
}

class OtrosPagos {
    tipo_otro_pago: string;
    clave: string;
    concepto: string;
    importe: string;
    subsidio_al_empleo: SubsidioEmpleo[];
    compensacion_saldos_favor: string;
}

class SubsidioEmpleo {
    subsidio_causado: string;
}