export class FiltroCargaMasiva {
  fecha_carga_inicial: string;
  fecha_carga_final: string;
  // fecha_emision: string;
  origen_documento: string;
  estatus: number;
  constructor() {
    this.fecha_carga_inicial = '';
    this.fecha_carga_final = '';
    // this.fecha_emision = '';
    this.origen_documento = '';
    this.estatus = 1;
  }
}
