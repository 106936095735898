import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-razon-social',
  templateUrl: './select-razon-social.component.html',
  styleUrls: ['./select-razon-social.component.css']
})
export class SelectRazonSocialComponent {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChange = new EventEmitter();
  @Input() data = new Array<any>();
  @ViewChild('contenedor_select_rs', { static: true }) modal: any;
  @ViewChild('input_todos', { static: true }) select_todos: any;

  expanded = false;
  isToogle = false;
  isFocus = false;
  seleccioandos = [];
  listenerClick: any;
  constructor(
  ) { }

  ngOnChanges() {
    if (this.data.length === 0) {
      this.select_todos.nativeElement.disabled = true;
    } else {
      this.select_todos.nativeElement.disabled = false;
      this.select_todos.nativeElement.checked = false;
    }
    this.seleccioandos.length = 0;
    this.data = this.data.map(x => {
      x.checked = false;
      return x;
    })
  }

  ngAfterViewInit(): void {
    this.modal = document.getElementById('contenedor_select_rs');
    window.addEventListener('click', (event: any) => {
      if (!event.target.classList.contains('class_cerrar_modal') && this.isToogle) {
        this.toogleSelect();
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.modal);
  }

  toogleSelect() {
    document.getElementById('select_rs').blur();
    if (this.isToogle) {
      this.isToogle = false;
      this.modal.style.display = 'none';
    } else {
      this.isToogle = true;
      this.modal.style.display = 'block';
    }
  }

  addSeleccion(input, dato) {
    if (input.checked && this.seleccioandos.filter(x => x.rfc === dato.rfc).length === 0) {
      this.seleccioandos.push(dato);
      if (this.seleccioandos.length == this.data.length) {
        this.select_todos.nativeElement.checked = true;
        this.seleccionarTodos(this.select_todos.nativeElement);
      }
    } else {
      if (!input.checked) {
        this.seleccioandos = this.seleccioandos.filter(x => x.rfc !== dato.rfc);
        if (this.select_todos.nativeElement.checked) {
          this.select_todos.nativeElement.checked = false;
        }
      }
    }
    this.onChange.emit(this.seleccioandos);
  }

  seleccionarTodos(input) {
    if (input.checked) {
      this.data = this.data.map(x => { x.checked = true; return x; })
      const aux = this.data;
      this.data = new Array<any>();
      this.seleccioandos.length = 0;
      setTimeout(() => {
        this.data = aux;
        this.seleccioandos = this.seleccioandos.concat(this.data);
        this.onChange.emit(this.seleccioandos);
      }, 10);
    } else {
      this.data = this.data.map(x => { x.checked = false; return x; });
      this.seleccioandos.length = 0;
      this.onChange.emit(this.seleccioandos);
    }
    // this.data.forEach(x => {
    //   x.checked = true;
    //   return x;
    // });
  }
}
