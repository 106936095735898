export class Contribuyente {
  id: number;
  rfc: string;
  id_estatus_contribuyente: number;
  descripcion_estatus: string;
  razon_social: string;
  file_cer: string;
  file_key: string;
  file_cer_nombre: string;
  file_key_nombre: string;
  password: string;
  id_corporativo: number;
  request: string;

  constructor() {
    this.id = 0;
    this.rfc = '';
    this.id_estatus_contribuyente = null;
    this.descripcion_estatus = '';
    this.razon_social = '';
    this.file_cer = '';
    this.file_key = '';
    this.file_cer_nombre = '';
    this.file_key_nombre = '';
    this.password = '';
    this.id_corporativo = 0;
  }

}
