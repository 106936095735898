export class OrdenarColumnas {
    id_tabla: number;
    columnas: Array<Columnas>;

    constructor() {
        this.id_tabla = 0;
        this.columnas = new Array<Columnas>();
    }
}

export class Columnas {
    nombre_columna: string;
    posicion: number;
    nomnbre_data: string;

    constructor() {
        this.nombre_columna = '';
        this.nomnbre_data = '';
        this.posicion = 0;
    }
}