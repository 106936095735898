export class PerfilFacturacion {
  razon_social: string;
  rfc_receptor: string;
  correo: string;
  uso_cfdi: string;

  constructor() {
    this.razon_social = '';
    this.rfc_receptor = '';
    this.correo = '';
    this.uso_cfdi = '';
  }
}
