export class GraficaLineasDrill  {
  chart: { type: '' };
  title: { text: '' };
  subtitle: { text: '' };
  accessibility: {
      announceNewData: {
          enabled: true
      }
  };
  xAxis: { type: '' };
  yAxis: {
      title: { text: '' }
  };
  legend: {
      enabled: false
  };
  plotOptions: {
      series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: ''
          }
      }
  };
  tooltip: {
      headerFormat: '',
      pointFormat: ''
  };
  series: [
      {
          name: '',
          colorByPoint: true,
          data: [
              {
                  name: '',
                  y: 0,
                  drilldown: ''
              }
          ]
      }
  ];
  drilldown: {
      series: [
          {
              name: '',
              id: '',
              data: [
                  [
                      '',
                      0
                  ]
              ]
          }
      ]
  };
}
