import { GlobalsComponent } from './../../Compartidos/global/global.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private globals: GlobalsComponent
  ) { }

  auth_usuario(token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': `Bearer ${token}`
    });
    return this.http.post(`${this.globals.host}/com-next-tech-api-audit-auth`, null, { headers });
  }

  logIg(username_aux: string, password_aux: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const usuario = {
      username: username_aux,
      password: password_aux,
      flag: 'auth'
    };
    // ${this.globals.host_cognito}
    // /com-next-tech-api-audit-auth_cognito
    return this.http.post(`${this.globals.host}/com-next-tech-api-audit-auth_cognito`, usuario, { headers });
  }

  // logInhttp(username_aux: string, password_aux: string) {
  //   const xhr = new XMLHttpRequest();
  //   const usuario = {
  //     username: username_aux,
  //     password: password_aux,
  //     flag: 'auth'
  //   };
  //   xhr.open('POST', 'http://10.10.5.116:5000');
  //   xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
  //   xhr.onload = () => {
  //     const status = xhr.status;
  //     console.log(xhr);
  //     if (status === 200) {
  //       console.log(xhr);
  //     } else {
  //       console.error('Cannot load data from remote source');
  //     }
  //   };
  //   xhr.onerror = () => {

  //   };
  //   xhr.send(JSON.stringify(usuario));
  // }

  // logInJquey(username_aux: string, password_aux: string) {
  //   const usuario = {
  //     username: username_aux,
  //     password: password_aux,
  //     flag: 'auth'
  //   };
  //   $.ajax({
  //     type: 'POST',
  //     url: 'http://10.10.5.116:5000',
  //     dataType: 'json',
  //     contentType: 'application/json',
  //     beforeSend: function( xhr ) {
  //       xhr.overrideMimeType( "text/html; charset=x-user-defined" );
  //     },
  //     data: JSON.stringify(usuario),
  //     success: function (data) {
  //     }
  //   });
  // }

}
