import { GlobalsComponent } from './../../Compartidos/global/global.component';
import { Injectable } from '@angular/core';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { DatosIniciales } from 'src/app/Compartidos/Entidades';
import { StorageService } from 'src/app/Compartidos/login/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private _http: HttpClient2,
    private _globales: GlobalsComponent,
    private _storageService: StorageService
  ) { }

  actualizarContrasena(old_password: string, new_password: string) {
    const datos_inciales: DatosIniciales = this._storageService.getDatosIniciales();
    const obj = {
      flag: 'change_password',
      arguments: {
        old_password,
        new_password,
        token_access: datos_inciales.usuario.token
      }
    };
    return this._http.post(`${this._globales.host}/com-next-tech-api-audit-auth_cognito`, obj);
  }

  ConfirmarUsuario() {
    return this._http.get(`/post`);
  }

  update() {
    return this._http.put(`/update`, null);
  }

  delete() {
    return this._http.delete(`/update`);
  }
}
