import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TarjetaComponent } from "./tarjeta/tarjeta.component";
import { TarjetaTitulosComponent } from "./tarjeta-titulos/tarjeta-titulos.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GraficaDonaComponent } from "./Graficas/grafica-dona/grafica-dona.component";
import { ChartsModule } from "ng2-charts";
import { GraficaLineasComponent } from "./Graficas/Grafica-Lineas/grafica-lineas.component";
import { LoadingComponent } from "./loading/loading.component";
import { SelectCheckboxComponent } from "./select-checkbox/select-checkbox.component";
import { GraficaPieComponent } from "./Graficas/grafica-pie/grafica-pie.component";
import { HttpClient2 } from "./Servicios-Compartidos/http-clien.service";
import { FiltroReportesComponent } from "./filtro-reportes/filtro-reportes.component";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { FiltroPagosComponent } from "./filtro-pagos/filtro-pagos.component";
import { CompartidosService } from "./compartidos.service";
import { GraficaBarraGrillComponent } from "./Graficas/grafica-barra-grill/grafica-barra-grill.component";
import { VisorFacturaComponent } from "./visor-factura/visor-factura.component";
import { FiltroMesesComponent } from "./filtro-meses/filtro-meses.component";
import { UppercaseDirective } from "./Directivas/uppercase.directive";
import { NumberOnlyDirective } from "./Directivas/number-only.directive";
import { SelectFuncionalidadesComponent } from "./select-funcionalidades/select-funcionalidades.component";
import { SelectRazonSocialComponent } from "./select-razon-social/select-razon-social.component";
import { SwitcherComponent } from "./switcher/switcher.component";
import { ModalOrdenarColumnasComponent } from "./modal-ordenar-columnas/modal-ordenar-columnas.component";
import { SortablejsModule } from "ngx-sortablejs";
import { Select2Module } from "ng2-select2";
import { RangeInputDateComponent } from "./range-input-date/range-input-date.component";
import { TarjetaDiferenciasComponent } from "./tarjeta-diferencias/tarjeta-diferencias.component";
import { UrlSanitizedPipe } from './url-sanitized.pipe';

@NgModule({
  declarations: [
    UrlSanitizedPipe,
    TarjetaTitulosComponent,
    TarjetaComponent,
    GraficaDonaComponent,
    GraficaLineasComponent,
    LoadingComponent,
    SelectCheckboxComponent,
    GraficaPieComponent,
    // GraficaDona3DComponent,
    FiltroReportesComponent,
    FiltroPagosComponent,
    GraficaBarraGrillComponent,
    VisorFacturaComponent,
    FiltroMesesComponent,
    NumberOnlyDirective,
    UppercaseDirective,
    SelectFuncionalidadesComponent,
    SelectRazonSocialComponent,
    SwitcherComponent,
    ModalOrdenarColumnasComponent,
    RangeInputDateComponent,
    TarjetaDiferenciasComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    SortablejsModule.forRoot({ animation: 150 }),
    FormsModule,
    Select2Module,
  ],
  providers: [HttpClient2, CompartidosService, UppercaseDirective],
  exports: [
    TarjetaTitulosComponent,
    TarjetaComponent,
    GraficaDonaComponent,
    GraficaLineasComponent,
    LoadingComponent,
    SelectCheckboxComponent,
    GraficaPieComponent,
    // GraficaDona3DComponent,
    FiltroReportesComponent,
    FiltroPagosComponent,
    GraficaBarraGrillComponent,
    NumberOnlyDirective,
    VisorFacturaComponent,
    FiltroMesesComponent,
    SelectRazonSocialComponent,
    SelectFuncionalidadesComponent,
    SwitcherComponent,
    ModalOrdenarColumnasComponent,
    RangeInputDateComponent,
    TarjetaDiferenciasComponent,
    // UrlSanitizedPipe,
  ],
})
export class CompartidosModule {}
