class DatosCuenta {
  nombres: string;
  apellidos: string;
  celular: string;
  nombre_corporativo: string;
  correo: string;
  correo_confirmacion: string;
  contrasena: string;
  contrasena_confirmacion: string;
  clave_activacion: string;

  constructor() {
    this.nombres = '';
    this.apellidos = '';
    this.celular = '';
    this.nombre_corporativo = '';
    this.correo = '';
    this.correo_confirmacion = '';
    this.contrasena = '';
    this.contrasena_confirmacion = '';
    this.clave_activacion = '';
  }
}

class DatosSuscripcion {
  tipo_plan: string;
  catidad_rfc: number;
  precio_unitario: number;
  sub_total: number;
  total_iva: number;
  total_pagar: number;
  terminos_condiciones: any;

  constructor() {
    this.tipo_plan = '';
    this.catidad_rfc = 1;
    this.precio_unitario = 1500.00;
    this.sub_total = 0;
    this.total_iva = 0;
    this.total_pagar = 0;
  }
}

class MetodoPago {
  nombre_tarjetahabiente: string;
  numero_tarjeta: string;
  mes: string;
  anio: string;
  codigo_seguridad: string;

  constructor() {
    this.nombre_tarjetahabiente = '';
    this.numero_tarjeta = '';
    this.mes = '';
    this.anio = '';
    this.codigo_seguridad = '';
  }
}

class Facturacion {
  rfc: string;
  razon_social: string;
  uso_cfdi: string;
  correo: string;

  constructor() {
    this.rfc = '';
    this.razon_social = '';
    this.uso_cfdi = 'G03';
    this.correo = '';
  }
}

export class AutoActivacion {
  datos_cuenta: DatosCuenta;
  suscripcion: DatosSuscripcion;
  metodo_pago: MetodoPago;
  facturacion: Facturacion;
  tarjeta_token: string;
  constructor() {
    this.datos_cuenta = new DatosCuenta();
    this.suscripcion = new DatosSuscripcion();
    this.metodo_pago = new MetodoPago();
    this.facturacion = new Facturacion();
  }
}
