import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing';
import { InicioComponent } from './Compartidos/inicio/inicio.component';
import { LoginModule } from './paginas/login/login.module';
import { HomeModule } from './paginas/home/home.module';
import { Pagina404Module } from './paginas/pagina404/pagina404.module';
import { GlobalsComponent } from './Compartidos/global/global.component';
import { ConstantsService } from './Compartidos/global/constants.service';
import { CompartidosModule } from './Compartidos/compartidos.module';
import { HttpClient2 } from './Compartidos/Servicios-Compartidos/http-clien.service';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './Compartidos/login/storage.service';
import { AuthorizatedGuard } from './Compartidos/login/authorizated-guard';
import { UsuariosModule } from './Modulos/Usuarios/usuarios.module';
import { DescargasModule } from './Modulos/Descargas/descargas.module';
import { LandingPageModule } from './paginas/landing-page/landing-page.module';
import { AutoActivacionModule } from './Modulos/auto-activacion/auto-activacion.module';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
  ],
  imports: [
    BrowserModule,
    CurrencyMaskModule,
    FormsModule,
    LoginModule,
    LandingPageModule,
    HomeModule,
    CompartidosModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    Pagina404Module,
    HttpClientModule,
    UsuariosModule,
    DescargasModule,
    AutoActivacionModule,
  ],
  providers: [
    GlobalsComponent,
    ConstantsService,
    StorageService,
    HttpClient2,
    AuthorizatedGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
