import { ListaDescargasComponent } from './lista-descargas/lista-descargas.component';
import { DescargasMainComponent } from './descargas-main/descargas-main.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizatedGuard } from 'src/app/Compartidos/login/authorizated-guard';

const routes: Routes = [
  {
    path: '', component: DescargasMainComponent, children: [
      { path: 'lista', component: ListaDescargasComponent, canActivate: [AuthorizatedGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DescargasRoutingModule { }
