import { Injectable } from '@angular/core';
// import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
// HWH
@Injectable()
export class GlobalsComponent {
  public cargando = true;

  // Cambiar de proyecto firebase
  // DESARROLLO
  // firebase use next-tech-test
  // PRODUCCION
  // firebase use com-next-technologies-big-data

  // HOST
  // public host = 'https://us-central1-next-tech-test.cloudfunctions.net';

  // PRODUCCION
  // public host = 'https://us-central1-com-next-technologies-big-data.cloudfunctions.net';
  //
  // DESARROLLO
  public host_dashboards = 'https://com-next-tech-audit-api-dashboards-t3w52zi3dq-uc.a.run.app'; /* Dashboards/Cancelaciones */
  public host_descargas = 'https://com-next-tech-audit-api-reportes-descargas-t3w52zi3dq-uc.a.run.app'; /* Reportes/descargas */
  public host_notificaciones = 'https://com-next-tech-audit-api-notificaciones-config-t3w52zi3dq-uc.a.run.app'; /* Notificaciones/Configuración */
  public host_conciliacion = 'https://com-next-tech-audit-api-conciliacion-t3w52zi3dq-uc.a.run.app'; /* Conciliación */
  public host = 'https://us-central1-com-next-technologies-big-data.cloudfunctions.net';
  public COLORES = {
    obscuro: '#353535',
    azul: '#625AA4',
    morado: '#8D80BC',
    lila: '#C5A9D1',
    verde: '#72B5A8',
    azul_claro: '#E1EBE4',
    rosa: '#C695D1',
  };

  public usuario = 'AminTest';
  public PoolData = {
    UserPoolId: 'us-east-2_ufSliXaYu',
    ClientId: '2ape89v52r91oc8jfq7ajnuiug'
  };
  public userPool: any;
  public authenticationDetails: any;
  public userData: any;
  public cognitoUser: any;
  // public anios_aux = this.years(2018);



  meses = [{ text: 'Todos', value: 0, checked: false },
  { text: 'Enero', value: 1, checked: false },
  { text: 'Febrero', value: 2, checked: false },
  { text: 'Marzo', value: 3, checked: false },
  { text: 'Abril', value: 4, checked: false },
  { text: 'Mayo', value: 5, checked: false },
  { text: 'Junio', value: 6, checked: false },
  { text: 'Julio', value: 7, checked: false },
  { text: 'Agosto', value: 8, checked: false },
  { text: 'Septiembre', value: 9, checked: false },
  { text: 'Octubre', value: 10, checked: false },
  { text: 'Noviembre', value: 11, checked: false },
  { text: 'Diciembre', value: 12, checked: false }
  ];
  // anios = [  { text: '2019', value: 2019 }, { text: '2020', value: 2020 } ];

  /**
   * Función para validar un RFC,
   * Valida si la cadena de texto proporcionada es un RFC valida,
   * Devuelve false si es inválido,
   * Tambien valida el RFC para público en general 'XAXX010101000'.
   * - Eduardo Castellanos Huicochea 10/04/2019
   * @param rfc RFC a validar
   */
  public validarRFC(rfc: string): boolean {
    let rfc_valido = false;
    if (rfc.length > 11) {
      if (rfc === 'XAXX010101000') { // RFC Genérico (ventas a público general)?
        rfc_valido = true;
      } else {
        const re = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;
        const validado = rfc.match(re);
        if (!validado) {
          rfc_valido = false;
        } else {
          rfc_valido = true;
        }
      }
    } else {
      rfc_valido = false;
    }
    return rfc_valido;
  }

  public objectLength(objeto: object): number {
    let contador = 0;
    for (const i in objeto) {
      if (contador !== null) {
        contador += 1;
      }
    }
    return contador;
  }

  // Powered By Huico
  // https://www.npmjs.com/package/amazon-cognito-identity-js
  // iniciarSessionAWS(username: string, password: string) {
  //   return new Promise((resolve, reject) => {
  //     this.userPool = new CognitoUserPool(this.PoolData);
  //     const authenticationData = {
  //       Username: username,
  //       Password: password,
  //     };
  //     this.authenticationDetails = new AuthenticationDetails(authenticationData);
  //     this.userData = {
  //       Username: username,
  //       Pool: this.userPool
  //     };
  //     this.cognitoUser = new CognitoUser(this.userData);
  //     this.cognitoUser.authenticateUser(this.authenticationDetails, {
  //       onSuccess: (result) => {
  //         resolve(result.getAccessToken().getJwtToken()); // ¡Todo salió bien
  //       },
  //       onFailure: (err) => {
  //         reject(err);
  //       },
  //     });
  //   });
  // }

  // altaUsuarioAWS(email: string, username: string, password: string, tel: string) {
  //   return new Promise((resolve, reject) => {
  //     const userPool = new CognitoUserPool(this.PoolData);
  //     const attributeList = [];
  //     const dataEmail = {
  //       Name: 'email',
  //       Value: email,
  //     };
  //     const dataPhoneNumber = {
  //       Name: 'phone_number',
  //       Value: tel,
  //     };
  //     const attributeEmail = new CognitoUserAttribute(dataEmail);
  //     const attributePhoneNumber = new CognitoUserAttribute(
  //       dataPhoneNumber
  //     );
  //     attributeList.push(attributeEmail);
  //     attributeList.push(attributePhoneNumber);
  //     userPool.signUp(username, password, attributeList, null, (
  //       err,
  //       result
  //     ) => {
  //       if (err) {
  //         reject(err.message || JSON.stringify(err));
  //       }
  //       const cognitoUser = result.user;
  //       resolve(cognitoUser.getUsername());
  //     });
  //   });
  // }

  // confirmarUsuarioAWS(username: string, code: string) {
  //   const userPool = new CognitoUserPool(this.PoolData);
  //   const userData = {
  //     Username: username,
  //     Pool: userPool
  //   };
  //   const cognitoUser = new CognitoUser(userData);
  //   cognitoUser.confirmRegistration(code, true, (err, result) => {
  //     if (err) {
  //       console.log(err);
  //       return;
  //     }
  //     console.log('call result: ' + result);
  //   });
  // }

  // cerrarSession(username: string) {
  //   const userPool = new CognitoUserPool(this.PoolData);
  //   const userData = {
  //     Username: username,
  //     Pool: userPool
  //   };
  //   const cognitoUser = new CognitoUser(userData);
  //   cognitoUser.signOut();
  // }

  // cambiarContrasena() {
  //   const userPool = new CognitoUserPool(this.PoolData);
  //   const userData = {
  //     Username: 'huicochea2',
  //     Pool: userPool
  //   };
  //   const cognitoUser = new CognitoUser(userData);

  //   cognitoUser.confirmPassword('073700', 'ContraseÑa#123', {
  //     onSuccess() {
  //         console.log('Password confirmed!');
  //     },
  //     onFailure(err) {
  //         console.log('Password not confirmed!');
  //     },
  //   });
  //   // cognitoUser.changePassword('oldPassword', 'newPassword', (err, result) => {
  //   //   if (err) {
  //   //       alert(err.message || JSON.stringify(err));
  //   //       return;
  //   //   }
  //   //   console.log('call result: ' + result);
  //   // });
  // }


  /**
   * @param type Indica si el elemnto para aplicarle el efecto Slide es por id o por clase
   * @param name_element Nombre identificador del elemtno al que se le aplicará el efecto
   * @param direction Indica la direccion en la que se movera el Slide left|right
   * @param position_pixel Indica la cantidad maxima de pixeles que se moverá el elemento indicado
   * @param speed Indica la velocidad a la que se movera el elemento indicado
   * @param position_element Este parametro es requerido sólo cuando se indica que el elemento a mover proviene de una clase, en ese caso se debe indicar en que posicion de  la lista de elemento que regreso la clase se encuentra nuestro elemento a mover.
   */
  AnimacionSlide(type: string, name_element: string, direction = 'right', position_pixel = 500, speed = 1, position_element?: number): void {
    // if ( type === 'id' ) {

    // } else {
    const elem: any = document.getElementsByClassName(name_element)[position_element];
    let pos = 0;
    const id = setInterval(frame, speed);
    function frame() {
      if (pos === -position_pixel) {
        clearInterval(id);
        // that.evt_siguiente.emit();
        elem.style.left = 0;
      } else {
        pos = pos - 10;
        elem.style.left = pos + 'px';
      }
    }
    // }

  }


}
