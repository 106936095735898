export class ResumenGeneralData {
    tipo_dato: string;
    subtotal: string;
    iva_retenido: string;
    isr_retenido: string;
    iva_trasladado?: string;
    isr_trasladado: string;
    total: string;
    total_cfdis: string;

    cosntructor() {
        this.tipo_dato = '';
        this.subtotal = '';
        this.iva_retenido = '';
        this.isr_retenido = '';
        this.iva_trasladado = '';
        this.isr_trasladado = '';
        this.total = '';
        this.total_cfdis = '';
    }
}