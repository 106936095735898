import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoActivacionRoutingModule } from './auto-activacion-routing.module';
import { AutoActivacionMainComponent } from './auto-activacion-main/auto-activacion-main.component';
import { DatosCuentaComponent } from './datos-cuenta/datos-cuenta.component';
import { DatosSuscripcionComponent } from './datos-suscripcion/datos-suscripcion.component';
import { MetodoPagoComponent } from './metodo-pago/metodo-pago.component';
import { SuscipcionProcesadaComponent } from './suscipcion-procesada/suscipcion-procesada.component';
import { AuditActivadoComponent } from './audit-activado/audit-activado.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoActivacionService } from './auto-activacion.service';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { AuthorizatedGuard } from 'src/app/Compartidos/login/authorizated-guard';
import { CompartidosModule } from 'src/app/Compartidos/compartidos.module';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';

@NgModule({
  declarations: [AutoActivacionMainComponent
    , DatosCuentaComponent
    , DatosSuscripcionComponent
    , MetodoPagoComponent
    , SuscipcionProcesadaComponent
    , AuditActivadoComponent, TerminosCondicionesComponent
  ],
  imports: [
    CommonModule,
    AutoActivacionRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    CompartidosModule
  ],
  providers: [
    AutoActivacionService,
    GlobalsComponent,
    HttpClient2,
    AuthorizatedGuard
  ]
})
export class AutoActivacionModule { }
