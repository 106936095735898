
import { LoginService } from './login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/Compartidos/login/storage.service';
import { DatosIniciales } from 'src/app/Compartidos/Entidades';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';
// https://www.npmjs.com/package/ng-recaptcha#example-basic-v3
declare var document: any;
declare var $: any;
import Swal from 'sweetalert2';

const MSJ_ERROR = {
  UserNotConfirmedException: 'No se finalizo el proceso de confirmación de usuario.',
  PasswordResetRequiredException: 'Es necesario hacer una solicitud de resset de password',
  NotAuthorizedException: 'Contraseña Incorrecta',
  UserNotFoundException: 'El usuario ingresado no existe',
  CodeMismatchException: 'Código de verificación incorrecto, por favor intentalo nuevamente',
  LimitExceededException: 'Limite de intentos exedido, por favor intentalo más tarde'
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formulario: FormGroup;
  public cargando = false;
  public mensaje_error = '';
  public aux_error = false;
  public show = false;
  public ojo = 'ojo fas fa-eye';
  // public contrasena = 'ContraseÑa#123';
  public contrasena = '';
  // public usuario = 'audit_cognito16@yopmail.com';
  public usuario = '';
  public PoolData = {
    UserPoolId: 'us-east-2_ufSliXaYu',
    ClientId: '2ape89v52r91oc8jfq7ajnuiug'
  };
  public userPool: any;
  public authenticationData = {
    Username: 'username',
    Password: 'password',
  };
  public captchaResponse = '';

  public authenticationDetails: any;
  public userData: any;
  public cognitoUser: any;
  reactiveForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private _loginService: LoginService,
    private globales: GlobalsComponent
  ) { }

  ngOnInit() {
    this.iniciarFormulario();
    this.reactiveForm = new FormGroup({
      recaptchaReactive: new FormControl(null, Validators.required)
    });

    if (document.getElementsByClassName('ts-full-screen').length) {
      document.getElementsByClassName('ts-full-screen')[0].style.height = window.innerHeight + 'px';
    }

    window.onresize = function (event) {
      document.getElementsByClassName('ts-full-screen')[0].style.height = window.innerHeight + 'px';
    };
    $(document).ready(function ($) {
      "use strict";

      $("body").imagesLoaded(function () {
        $("body").addClass("loading-done");
        var $animatedWaves = $(".ts-animated-waves");
        $animatedWaves.css("transform", "translateX( calc( -100% + " + ($(window).width() + 5) + "px )");
        $animatedWaves.on("transitionend webkitTransitionEnd oTransitionEnd", function () {
          $(this).toggleClass("repeat");
        });
      });

      $('.navbar-nav .nav-link').on('click', function () {
        $('.navbar-collapse').collapse('hide');
      });

      $(".ts-img-into-bg").each(function () {
        $(this).css("background-image", "url(" + $(this).find("img").attr("src") + ")");
      });

      //  Background

      $("[data-bg-color], [data-bg-image], [data-bg-particles]").each(function () {
        var $this = $(this);

        if ($this.hasClass("ts-separate-bg-element")) {
          $this.append('<div class="ts-background">');

          // Background Color

          if ($("[data-bg-color]")) {
            $this.find(".ts-background").css("background-color", $this.attr("data-bg-color"));
          }

          // Particles

          if ($this.attr("data-bg-particles-line-color") || $this.attr("data-bg-particles-dot-color")) {
            $this.find(".ts-background").append('<div class="ts-background-particles">');
            $(".ts-background-particles").each(function () {
              var lineColor = $this.attr("data-bg-particles-line-color");
              var dotColor = $this.attr("data-bg-particles-dot-color");
              var parallax = $this.attr("data-bg-particles-parallax");
              $(this).particleground({
                density: 15000,
                lineWidth: 0.2,
                lineColor: lineColor,
                dotColor: dotColor,
                parallax: parallax,
                proximity: 200
              });
            });
          }

          // Background Image

          if ($this.attr("data-bg-image") !== undefined) {
            $this.find(".ts-background").append('<div class="ts-background-image">');
            $this.find(".ts-background-image").css("background-image", "url(" + $this.attr("data-bg-image") + ")");
            $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size"));
            $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position"));
            $this.find(".ts-background-image").css("opacity", $this.attr("data-bg-image-opacity"));

            $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size"));
            $this.find(".ts-background-image").css("background-repeat", $this.attr("data-bg-repeat"));
            $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position"));
            $this.find(".ts-background-image").css("background-blend-mode", $this.attr("data-bg-blend-mode"));
          }

          // Parallax effect

          if ($this.attr("data-bg-parallax") !== undefined) {
            $this.find(".ts-background-image").addClass("ts-parallax-element");
          }
        }
        else {

          if ($this.attr("data-bg-color") !== undefined) {
            $this.css("background-color", $this.attr("data-bg-color"));
            if ($this.hasClass("btn")) {
              $this.css("border-color", $this.attr("data-bg-color"));
            }
          }

          if ($this.attr("data-bg-image") !== undefined) {
            $this.css("background-image", "url(" + $this.attr("data-bg-image") + ")");

            $this.css("background-size", $this.attr("data-bg-size"));
            $this.css("background-repeat", $this.attr("data-bg-repeat"));
            $this.css("background-position", $this.attr("data-bg-position"));
            $this.css("background-blend-mode", $this.attr("data-bg-blend-mode"));
          }

        }
      });

      //  Parallax Background Image

      $("[data-bg-parallax='scroll']").each(function () {
        var speed = $(this).attr("data-bg-parallax-speed");
        var $this = $(this);
        var isVisible;
        var backgroundPosition;

        $this.isInViewport(function (status) {
          if (status === "entered") {
            isVisible = 1;
            var position;

            $(window).scroll(function () {
              if (isVisible === 1) {
                position = $(window).scrollTop() - $this.offset().top;
                backgroundPosition = (100 - (Math.abs((-$(window).height()) - position) / ($(window).height() + $this.height())) * 100);
                if ($this.find(".ts-parallax-element").hasClass("ts-background-image")) {
                  $this.find(".ts-background-image.ts-parallax-element").css("background-position-y", (position / speed) + "px");
                }
                else {
                  $this.find(".ts-parallax-element").css("transform", "translateY(" + (position / speed) + "px)");
                }
              }
            });
          }
          if (status === "leaved") {
            isVisible = 0;
          }
        });
      });


      // Dynamic Waves in Hero

      $(".ts-dynamic-wave").each(function () {
        $(this).wavify({
          //height: (1- $(this).attr("data-wave-height")) * $(window).height(),
          height: $(this).attr("data-wave-height"),
          bones: $(this).attr("data-wave-bones"),
          amplitude: .1 * $(window).height(),
          color: $(this).attr("data-wave-color"),
          speed: .15
        });
      });

      $(".ts-labels-inside-input input, .ts-labels-inside-input textarea").focusin(function () {
        $(this).parent().find("label").addClass("focused");
      })
        .focusout(function () {
          if ($(this).val().length === 0) {
            $(this).parent().find("label").removeClass("focused")
          }
        });

      $("select").each(function () {
        $(this).wrap('<div class="select-wrapper"></div>');
      });

      // Owl Carousel

      var $owlCarousel = $(".owl-carousel");

      if ($owlCarousel.length) {
        $owlCarousel.each(function () {

          var items = parseInt($(this).attr("data-owl-items"), 10);
          if (!items) items = 1;

          var nav = parseInt($(this).attr("data-owl-nav"), 2);
          if (!nav) nav = 0;

          var dots = parseInt($(this).attr("data-owl-dots"), 2);
          if (!dots) dots = 0;

          var center = parseInt($(this).attr("data-owl-center"), 2);
          if (!center) center = 0;

          var loop = parseInt($(this).attr("data-owl-loop"), 2);
          if (!loop) loop = 0;

          var margin = parseInt($(this).attr("data-owl-margin"), 2);
          if (!margin) margin = 0;

          var autoWidth = parseInt($(this).attr("data-owl-auto-width"), 2);
          if (!autoWidth) autoWidth = 0;

          var navContainer = $(this).attr("data-owl-nav-container");
          if (!navContainer) navContainer = 0;

          var autoplay = parseInt($(this).attr("data-owl-autoplay"), 2);
          if (!autoplay) autoplay = 0;

          var autoplayTimeOut = parseInt($(this).attr("data-owl-autoplay-timeout"), 10);
          if (!autoplayTimeOut) autoplayTimeOut = 5000;

          var autoHeight = parseInt($(this).attr("data-owl-auto-height"), 2);
          if (!autoHeight) autoHeight = 0;

          var fadeOut = $(this).attr("data-owl-fadeout");
          if (!fadeOut) fadeOut = 0;
          else fadeOut = "fadeOut";

          if ($("body").hasClass("rtl")) var rtl = true;
          else rtl = false;

          if (items === 1) {
            $(this).owlCarousel({
              navContainer: navContainer,
              animateOut: fadeOut,
              autoplayTimeout: autoplayTimeOut,
              autoplay: 1,
              autoheight: autoHeight,
              center: center,
              loop: loop,
              margin: margin,
              autoWidth: autoWidth,
              items: 1,
              nav: nav,
              dots: dots,
              rtl: rtl,
              navText: []
            });
          }
          else {
            $(this).owlCarousel({
              navContainer: navContainer,
              animateOut: fadeOut,
              autoplayTimeout: autoplayTimeOut,
              autoplay: autoplay,
              autoHeight: autoHeight,
              center: center,
              loop: loop,
              margin: margin,
              autoWidth: autoWidth,
              items: 1,
              nav: nav,
              dots: dots,
              rtl: rtl,
              navText: [],
              responsive: {
                1199: {
                  items: items
                },
                992: {
                  items: 3
                },
                768: {
                  items: 2
                },
                0: {
                  items: 1
                }
              }
            });
          }

          if ($(this).find(".owl-item").length === 1) {
            $(this).find(".owl-nav").css({ "opacity": 0, "pointer-events": "none" });
          }

        });
      }

      $(".ts-count-down").each(function () {
        var date = $(this).attr("data-date");
        $(this).countdown({
          date: date,
          render: function (data) {
            var el = $(this.el);
            el.empty()
              .append("<div>" + this.leadingZeros(data.days, 3) + " <span>Days</span></div>")
              .append("<figure class='divider'>:</figure>")
              .append("<div>" + this.leadingZeros(data.hours, 2) + " <span>Hours</span></div>")
              .append("<figure class='divider'>:</figure>")
              .append("<div>" + this.leadingZeros(data.min, 2) + " <span>Minutes</span></div>")
              .append("<figure class='divider'>:</figure>")
              .append("<div>" + this.leadingZeros(data.sec, 2) + " <span>Seconds</span></div>");
          }
        });
      });


      // On RESIZE actions

      var resizeId;

      $(window).on("resize", function () {
        clearTimeout(resizeId);
        resizeId = setTimeout(this.doneResizing(), 250);
      });

      // On SCROLL actions

      $(window).on("scroll", function () {
        if ($(window).scrollTop() > $(window).height()) {
          $(".navbar").addClass("in");
        }
        else {
          $(".navbar").removeClass("in");
        }
      });

    });


  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }


  doneResizing() {
    this.heroHeight();
    $('.owl-carousel').trigger('next.owl.carousel');
  }

  heroHeight() {
    $('.ts-full-screen').height($(window).height());
  }

  iniciarFormulario() {
    this.formulario = this.formBuilder.group({
      usuario: ['', Validators.required],
      recaptchaReactive: [''],
      contrasena: ['', [
        Validators.required, Validators.minLength(4),
        this.validarContrasena
      ]]
    });
  }

  private validarContrasena(control: AbstractControl) {
    const contrasena = control.value;
    let error = null;
    const regex = new RegExp('^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$');
    if (regex.test(contrasena)) {
      error = 'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico';
    }
    return error;
  }

  inciarSesion() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE');
    if (msie > 0) {
    } else {
      if (grecaptcha.getResponse().length === 0) {
        this.cargando = false;
        this.aux_error = true;
        this.mensaje_error = 'Es necesario validar el captcha nuevamente';
        return false;
      }
    }

    if (this.captchaResponse !== '') {
      this.cargando = true;
      this._loginService.logIg(this.formulario.value.usuario, this.formulario.value.contrasena).subscribe(
        (data: any) => {
          if (data.__type) {
            this.mensaje_error = MSJ_ERROR[data.__type];
            this.aux_error = true;
            this.cargando = false;
            return null;
          } else {
            this._loginService.auth_usuario(data.AuthenticationResult.AccessToken).subscribe(
              (data2: any) => {
                this.aux_error = false;
                const datosIniciales = new DatosIniciales();
                // datosIniciales.usuario = new Usuario();
                datosIniciales.usuario = data2;
                datosIniciales.usuario.token = data.AuthenticationResult.AccessToken;
                datosIniciales.usuario.username = this.formulario.value.usuario;
                datosIniciales.usuario.IdToken = data.AuthenticationResult.IdToken;
                datosIniciales.usuario.RefreshToken = data.AuthenticationResult.RefreshToken;
                datosIniciales.usuario.password = this.formulario.value.contrasena;
                this.storageService.setDatosIniciales(datosIniciales);
                this.cargando = false;
                this.router.navigate(['home', 'principal']);
              }
              , (error) => {
                this.aux_error = true;
                if (error.error) {
                  if (error.error.error.message) {
                    this.mensaje_error = error.error.error.message;
                  } else {
                    if (error.error.__type) {
                      this.mensaje_error = MSJ_ERROR[error.error.__type];
                    } else {
                      this.mensaje_error = error.error.message;
                    }
                  }
                } else {
                  this.mensaje_error = 'Ocurrió un problema, por favor intentalo nuevamente más tarde';
                }
                this.cargando = false;
              }
              , () => {
                this.cargando = false;
              }
            );
          }
        },
        (error) => {

        },
        () => {

        }
      );
    }
  }

  password() {
    this.show = !this.show;
    if (this.show) {
      this.ojo = 'ojo far fa-eye-slash';
    } else {
      this.ojo = 'ojo fas fa-eye';
    }
  }

  public submit(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }

  public restaurarContrasena(): void {
    Swal.fire({
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showLoaderOnConfirm: true,
      customClass: {
        title: 'swal2-title3'
      },
      title: 'Reestablecer Contraseña',
      html:
        `
        <form name='formulario_password'>
          <div class="form-row" style='margin-bottom: 0%;'>
            <div class="form-group col-md-12" style='margin-bottom: 0rem;'>
              <label class='text-left' for='email-r' style='float: left; font-size: 0.7em; color: gray; margin-top: 8px'>Correo electrónico* </label>
              <input type='text' id="email-r" class="form-control m-3" autocomplete="new-password" placeholder='Introduce tu correo electrónico'>
            </div>
          </div>
        </form>
        `,
      preConfirm:
        () => {
          const email = $('#email-r').val();
          if (email.trim() === '') {
            Swal.showValidationMessage(
              `El email es requerido`
            );
            return false;
          }
          const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!reg.test(email)) {
            Swal.showValidationMessage(
              `Formato de email incorrecto`
            );
            return false;
          }
          const solicitud = {
            flag: 'forgot_password',
            arguments: { username: email }
          };
          return fetch(`${this.globales.host}/com-next-tech-api-audit-auth_cognito`,
            {
              method: 'POST',
              body: JSON.stringify(solicitud),
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(() => { // Abrir segundo Swal
              const that = this;
              that.confirmarContrasenas(email);
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Ocurrio un error: ${error}`
              );
            });
        }
      ,
      onOpen: () => {
        $('#email-r').focus();
      }
    }).then((result) => {
      console.log(JSON.stringify(result));
    });
  }

  public restaurarPassword(): void {
    alert('Restaurando');
  }

  public confirmarContrasenas(email: string): void {
    setTimeout(() => {
      Swal.fire({
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showLoaderOnConfirm: true,
        customClass: {
          title: 'swal2-title4'
        },
        title: 'Hemos enviado un correo electrónico con código de verificación, por favor ingrese la clave para continuar',
        html:
          `
          <form name='formulario_password'>
            <div class="form-row" style='margin-bottom: 0%;'>
              <div class="form-group col-md-12" style='margin-bottom: 0rem;'>
              <label class='text-left' for='codigo' style='float: left; font-size: 0.7em; color: gray; margin-top: 8px'>Código de verificación* </label>
                <input type='text' id="codigo" class="form-control m-3" autocomplete="new-password" placeholder='Introduce código de verificación'>
                <label class='text-left' for='password' style='float: left; font-size: 0.7em; color: gray; margin-top: 8px'>Nueva Contraseña* </label>
                <input type='password' id="password-r" class="form-control m-3" autocomplete="new-password" placeholder='Introduce la contraseña'>
                <label class='text-left' for='password2' style='float: left; font-size: 0.7em; color: gray; margin-top: 8px'>Confirma Nueva Contraseña* </label>
                <input type='password' id="password2-r" class="form-control m-3" autocomplete="new-password" placeholder='Repite contraseña' >
                <p class='text-danger small' id='iguales' style='display:none'>Las contraseñas deben ser iguales.<p>
                <p>
                <small style='font-size: 0.6em;' class="form-text text-muted hidden">La contraseña debe tener entre 8 y 16 caracteres, al menos
                un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico.</small></p>
                </div>
            </div>
          </form>
          `,
        preConfirm:
          () => {
            const codigo = $('#codigo').val();
            const pass1 = $('#password-r').val();
            const pass2 = $('#password2-r').val();
            if (codigo.trim() === '') {
              Swal.showValidationMessage(
                `El código de verificación no puede ir vacio.`
              );
              return false;
            }
            if (pass1.trim() !== pass2.trim()) {
              Swal.showValidationMessage(
                `Las contraseñas deben ser iguales`
              );
              return false;
            }

            const regex = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/);
            if (!regex.test(pass1) || !regex.test(pass2)) {
              Swal.showValidationMessage(
                `La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico.`
              );
              return false;
            }

            const confirmacion = {
              flag: 'confirm_code_forgot',
              arguments: {
                username: email,
                password: pass1,
                code: codigo
              }
            };
            return fetch(`${this.globales.host}/com-next-tech-api-audit-auth_cognito`,
              {
                method: 'POST',
                body: JSON.stringify(confirmacion),
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            ).then((response2: any) => {
              if (response2.ok) {
                setTimeout(() => {
                  Swal.fire({
                    title: `¡Contraseña actualizada con exito!`,
                  });
                }, 500);
                return response2;
              } else {
                return response2.json();
              }
            }).then((data: any) => {
              throw new Error(data.error.details.response.Error.Code);
            })
              .catch((error) => {
                Swal.showValidationMessage(`Ocurrio un error: ${MSJ_ERROR[error.message]}`);
              });
          }
        ,
        onOpen: () => {
          $('#codigo').focus();
        }
      }).then((result) => {
        return false;
      });
    }, 500);
  }

}
