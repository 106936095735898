/** HWH
 * Servicio auxiliar para administrar el token y usuario almacenados cuando se hace un login.
 * Este servicio permite utilizar la información del usuario que se ha logueado desde cualquier lugar.
 * También tenemos un método para eliminar la información almacenada y posteriormente regresar a la pantalla de login.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Usuario, DatosIniciales } from '../Entidades';

@Injectable()
export class StorageService {
  private localStorageService;
  private currentSession: Usuario = null;
  private datos_iniciales: DatosIniciales = null;
  private menuDinammico: any;
  private corporativos: any[];
  corporativo_activo: any;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }
  setCurrentSession(session: Usuario): void {
    this.currentSession = session;
    this.localStorageService.setItem(
      'currentUser',
      this.encriptar(JSON.stringify(session))
    );
  }

  setDatosIniciales(datos_inciales: DatosIniciales): void {
    this.datos_iniciales = datos_inciales;
    this.localStorageService.setItem(
      'datosIniciales',
      this.encriptar(JSON.stringify(datos_inciales))
    );
  }
  getDatosIniciales() {
    const aux = localStorage.getItem('datosIniciales');
    if (this.datos_iniciales) {
      return this.datos_iniciales;
    } else {
      if (aux) {
        return JSON.parse(this.desencriptar(localStorage.getItem('datosIniciales'))) as DatosIniciales;
      }
    }
  }
  setCorporativoActivo(corporativo_activo: any): void {
    this.corporativo_activo = corporativo_activo;
    this.localStorageService.setItem(
      'corporativoActivo',
      this.encriptar(JSON.stringify(corporativo_activo))
    );
  }
  getCorporativoActivo() {
    const aux = localStorage.getItem('corporativoActivo');
    if (this.corporativo_activo) {
      return this.corporativo_activo;
    }
    if (aux) {
      return JSON.parse(this.desencriptar(localStorage.getItem('corporativoActivo')));
    }
  }

  loadSessionData(): Usuario {
    let sessionStr = this.localStorageService.getItem('currentUser');
    if (sessionStr !== undefined && sessionStr !== null) {
      sessionStr = this.desencriptar(sessionStr);
      return sessionStr ? JSON.parse(sessionStr) : null;
    }
  }
  setMenuDinamico(menu: any) {
    this.menuDinammico = menu;
    this.localStorageService.setItem(
      'menuDinamico',
      this.encriptar(JSON.stringify(menu))
    );
  }
  getMenuDinamico() {
    const aux = localStorage.getItem('menuDinamico');
    if (this.menuDinammico) {
      return this.menuDinammico;
    }
    if (aux) {
      return JSON.parse(this.desencriptar(localStorage.getItem('menuDinamico')));
    }
  }

  setCorporativos(menu: any) {
    this.corporativos = menu;
    this.localStorageService.setItem(
      'corporativos',
      this.encriptar(JSON.stringify(menu))
    );
  }
  getCorporativos() {
    const aux = localStorage.getItem('corporativos');
    if (this.corporativos) {
      return this.corporativos;
    }
    if (aux) {
      return JSON.parse(this.desencriptar(localStorage.getItem('corporativos')));
    }
  }

  getCurrentSession(): Usuario {
    return this.currentSession;
  }
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('actions');
    this.localStorageService.removeItem('listaRolSucursales');
    this.localStorageService.removeItem('rolSucursales');
    this.localStorageService.removeItem('datosIniciales');
    this.localStorageService.removeItem('corporativoActivo');
    this.localStorageService.removeItem('menuDinamico');
    this.localStorageService.removeItem('corporativos');
    this.currentSession = null;
    this.datos_iniciales = null;
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    //  return (this.getCurrentToken() != null) ? true : false;
    return true;
  }
  getCurrentToken(): string {
    const session = this.getDatosIniciales();
    return (session && session.usuario.token) ? session.usuario.token : null;
  }
  logout(): void {
    this.removeCurrentSession();
    this.router.navigate([`/login`]);
  }

  logoutProveedores(): void {
    const identificador = localStorage.getItem('identificador');
    this.removeCurrentSession();

    if (identificador !== null) {
      this.router.navigate([`${identificador}/login/proveedores`]);
    } else {
      this.router.navigate([`/`]);
    }
  }

  encriptar(palabra: any) {
    const aux = CryptoJS.AES.encrypt(palabra, 'N0m3L4z39999');
    return aux;
  }

  desencriptar(palabra: any) {
    if (palabra !== undefined && palabra !== null) {
      const aux = CryptoJS.AES.decrypt(palabra, 'N0m3L4z39999').toString(
        CryptoJS.enc.Utf8
      );
      return aux;
    }
  }

  encriptar_ids(palabra: any) {
    const aux = CryptoJS.AES.encrypt(palabra, 'N0m3L4z39999');
    const encodedText = btoa(aux.toString()).replace('/', '*');
    return encodedText;
  }

  desencriptar_ids(palabra: any) {
    if (palabra !== undefined && palabra !== null) {
      const decodedText = atob(palabra.replace('*', '/'));
      const aux = CryptoJS.AES.decrypt(decodedText, 'N0m3L4z39999').toString(
        CryptoJS.enc.Utf8
      );
      return aux;
    }
  }

  // Guarda y encripta los datos de las acciones que puede hacer el usuario.
  setCurrenRolcc(actions: any): void {
    this.localStorageService.setItem(
      'actions',
      this.encriptar(JSON.stringify(actions))
    );
  }

  getCurrenRolcc(palabra: any) {
    return this.desencriptar(palabra);
  }
}
