import { Usuario } from 'src/app/Compartidos/Entidades';
import { Injectable } from '@angular/core';
import { HttpClient2 } from 'src/app/Compartidos/Servicios-Compartidos/http-clien.service';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private _http: HttpClient2,
    private globals: GlobalsComponent
  ) { }

  actualizarToken(refreshToken: string) {
    const aux = {
      'flag': 'refresh',
      'refresh_token': refreshToken
    }
    return this._http.post(`${this.globals.host}/com-next-tech-api-audit-auth_cognito`, aux);
  }

  ConfirmarUsuario() {
    return this._http.get(`/post`);
  }

  update() {
    return this._http.put(`/update`, null);
  }

  delete() {
    return this._http.delete(`/update`);
  }
}
