import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoActivacionMainComponent } from './auto-activacion-main/auto-activacion-main.component';
import { DatosCuentaComponent } from './datos-cuenta/datos-cuenta.component';

const routes: Routes = [
  {
    path: '', component: AutoActivacionMainComponent,
    children: [
      {
        path: 'cuenta', component: DatosCuentaComponent
      },
      {
        path: 'cuenta/:plan', component: DatosCuentaComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutoActivacionRoutingModule { }
