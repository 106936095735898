import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.css']
})
export class SelectCheckboxComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChange = new EventEmitter();
  @Input() data = [];
  @ViewChild('contenedor_select', { static: true }) modal: any;
  expanded = false;
  // modal: any;
  isToogle = false;
  isFocus = false;
  seleccioandos = [];
  listenerClick: any;
  constructor(
    private _constant: ConstantsService
  ) { }

  ngOnInit() {
    if ( this._constant.filtro_header.meses && this._constant.filtro_header.meses.length > 0 ) {
      this.data.forEach(meses => {
        meses.checked = false;
      });
    }

    this.data.forEach(meses => {
      this._constant.filtro_header.meses.forEach(messelected => {
        if (meses.value === messelected) {
          meses.checked = true;
        }
      });
    })
    this.modal = document.getElementById('contenedor_select');
    this.listenerClick = window.addEventListener('click', (event: any) => {
      if (!event.target.classList.contains('class_cerrar_modal') && this.isToogle) {
        this.toogleSelect();
      }
    });
  }
  ngOnDestroy(): void {
    window.removeEventListener('click', this.modal);
  }

  toogleSelect() {
    if (this.isToogle) {
      this.isToogle = false;
      this.modal.style.display = 'none';
    } else {
      this.isToogle = true;
      this.modal.style.display = 'block';
    }
  }

  addSeleccion(dato) {
    // console.log(dato);
    // if (dato.checked) {
    //   if (dato.value === '0') {
    //     this.data.forEach(element => {
    //       element.checked = true;
    //       this.seleccioandos.push(element.value);
    //     });
    //   } else {
    //     this.seleccioandos.push(dato.value);
    //   }
    // } else {
    //   if (dato.value === '0') {
    //     this.data.forEach(element => {
    //       element.checked = false;
    //       this.seleccioandos = this.seleccioandos.filter(x => x !== element.value);
    //     });
    //   } else {
    //     this.seleccioandos = this.seleccioandos.filter(x => x !== dato.value);
    //   }
    // }
    this.seleccioandos = [];
    this.seleccioandos.push(dato.value);
    this.onChange.emit(this.seleccioandos);
  }
}
