import { MergeMapSubscriber } from 'rxjs/internal/operators/mergeMap';

export class FiltroReportes {
    codigo_postal: string;
    estatus_cfdi: number;
    fecha_factura: string;
    folio: string;
    folio_fiscal: string;
    forma_pago: string;
    metodo_pago: string;
    rfc_receptor: string;
    rfc_emisor: string;
    serie: string;
    tipo_comprobante: string;
    razon_social_filtro_receptor: string;
    razon_social_filtro_emisor: string;
    columnas?: any;
    estatus_conciliacion: number;
    constructor() {
        this.fecha_factura = '';
        this.rfc_receptor = '';
        this.rfc_emisor = '';
        this.serie = '';
        this.folio = '';
        this.folio_fiscal = '';
        this.tipo_comprobante = '';
        this.estatus_cfdi = 2;
        this.codigo_postal = '';
        this.forma_pago = '';
        this.metodo_pago = '';
        this.razon_social_filtro_receptor = '';
        this.razon_social_filtro_emisor = '';
        this.estatus_conciliacion = 2;
    }
}
