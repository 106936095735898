import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DescargasRoutingModule } from './descargas-routing.module';
import { DescargasMainComponent } from './descargas-main/descargas-main.component';
import { ListaDescargasComponent } from './lista-descargas/lista-descargas.component';


@NgModule({
  declarations: [DescargasMainComponent, ListaDescargasComponent],
  imports: [
    CommonModule,
    DescargasRoutingModule
  ]
})
export class DescargasModule { }
