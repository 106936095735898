export interface ICardData {
  titulo?: string;
  monto: any;
  descripcion: string;
  icono?: string;
  color?: string;
}

export class CardData implements ICardData {
  public COLORESCARD = {
    'VERDE': 'dashbord-green',
    'NARANJA': 'dashbord-orange',
    'AZUL': 'dashbord-blue',
    'ROJO': 'dashbord-red',
    'SKY': 'dashbord-skyblue',
  };
  titulo: string;
  monto: any;
  descripcion: string;
  icono: string;
  color: string;
  constructor() {
    this.titulo = 'Cargando...';
    this.monto = '';
    this.descripcion = '';
    this.icono = '';
    this.color = '';
  }
}
