import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';

@Component({
  selector: 'app-datos-suscripcion',
  templateUrl: './datos-suscripcion.component.html',
  styleUrls: ['./datos-suscripcion.component.css']
})
export class DatosSuscripcionComponent {

  @Input() datos_cuenta: AutoActivacion;
  public formulariocuenta = new FormGroup({
    tipo_plan: new FormControl( '' , Validators.required),
    tipo_plan2: new FormControl(''),
    cantidad_rfc: new FormControl( '' , Validators.required ),
    precio_unitario: new FormControl(),
    sub_total: new FormControl(),
    total_iva: new FormControl(),
    terminos: new FormControl('' , Validators.required)

  });

  @Output() evt_siguiente = new EventEmitter();
  @Output() evt_anterior = new EventEmitter();
  constructor() {

  }

  AnimacionSlide() {
    const elem: any = document.getElementsByClassName('carousel-item')[1];
    const next_elem: any = document.getElementsByClassName('carousel-item')[2];
    let pos = 0;
    const id = setInterval(frame, 10);
    const that = this;
    function frame() {
      if (pos === -600) {
        clearInterval(id);
        next_elem.style.left = 0 + 'px';
        that.evt_siguiente.emit();
        elem.style.left = 0 + 'px';
        elem.style.display = 'none';
        const next_elem2: any = document.getElementsByClassName('carousel-item')[3];
        next_elem2.style.left = '71%';
        next_elem2.style.display = 'block';
        document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem2.offsetHeight + 'px');
      } else {
        pos = pos - 10;
        elem.style.left = pos + 'px';
        const aux = Number(next_elem.offsetLeft) - 10;
        next_elem.style.left = aux + 'px';
      }
    }
  }


  siguiente() {
    this.AnimacionSlide();
  }

  AnteriorSlide() {
    const next_elem_1: any = document.getElementsByClassName('carousel-item')[0];
    next_elem_1.style.display = 'block';
    const next_elem: any = document.getElementsByClassName('carousel-item')[1];
    next_elem.style.left = '71%';
    next_elem.style.display = 'block';
    this.evt_anterior.emit();
    const next_elem2: any = document.getElementsByClassName('carousel-item')[2];
    next_elem2.style.left = '71%';
    next_elem2.style.display = 'none';
    document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem.offsetHeight + 'px');
  }
}
