import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeRoutingModule } from './home-routing.module';
import { MainPageComponent } from './home.component';
import { ContenidoComponent } from 'src/app/Compartidos/contenido/contenido.component';
import { SidebarComponent } from 'src/app/Compartidos/sidebar/sidebar.component';
import { CabeceraComponent } from 'src/app/Compartidos/cabecera/cabecera.component';
import { FooterComponent } from 'src/app/Compartidos/footer/footer.component';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';
import { CompartidosModule } from 'src/app/Compartidos/compartidos.module';

@NgModule({
  declarations: [
    MainPageComponent,
    CabeceraComponent,
    SidebarComponent,
    ContenidoComponent,
    FooterComponent,
    // LoadingComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    HomeRoutingModule,
    CompartidosModule
  ],
  providers: [
    GlobalsComponent
  ]
})
export class HomeModule { }
