import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsComponent } from 'src/app/Compartidos/global/global.component';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';
@Injectable({
  providedIn: 'root'
})
export class AutoActivacionService {

  constructor(
    private _http: HttpClient,
    private globals: GlobalsComponent
  ) { }

  crearCliente( cliente: AutoActivacion ) {
    const obj_cliente = {
      flag: 'cliente_stagin',
      arguments: {
        nombre: cliente.datos_cuenta.nombres,
        apellidos: cliente.datos_cuenta.apellidos,
        correo: cliente.datos_cuenta.correo,
        corporativo: cliente.datos_cuenta.nombre_corporativo,
        telefono: cliente.datos_cuenta.celular,
        tarjeta_token: cliente.tarjeta_token,
        tipo_tarjeta: 'card',
        plan_cantidad: cliente.suscripcion.precio_unitario,
        plan_intervalo:  cliente.suscripcion.tipo_plan === 'MN' ? 'mensual' : 'anual',
        plan_num_rfc: cliente.suscripcion.catidad_rfc,
        password: cliente.datos_cuenta.contrasena
      }
    };
    return this._http.post(`${this.globals.host}/next-tech-bi-gcf-audit_suscripcion_conekta`, obj_cliente);
  }

  procesarSuscripcion(uuid_ususario: string) {
    const obj_suscripcion = {
      flag: 'suscripcion',
      uuid: uuid_ususario
    };
    return this._http.post(`${this.globals.host}/next-tech-bi-gcf-audit_suscripcion_conekta`, obj_suscripcion);
  }

  activarUsuario(email: string, codigo_confirmacion: string) {
    const obj_confirmacion = {
      flag: 'confirm_user',
      arguments: {
        email,
        code : codigo_confirmacion
      }
    };
    return this._http.post(`${this.globals.host}/next-tech-bi-gcf-audit_suscripcion_conekta`, obj_confirmacion);
  }

  reenviarCodigo(correo) {
    const obj = {
      flag : 'resend',
      arguments: {
        correo
      }
    };
    return this._http.post(`${this.globals.host}/next-tech-bi-gcf-audit_suscripcion_conekta`, obj);
  }

}
