import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';
import { AutoActivacionService } from '../auto-activacion.service';
import { ConstantsService } from 'src/app/Compartidos/global/constants.service';
declare var Conekta: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-metodo-pago',
  templateUrl: './metodo-pago.component.html',
  styleUrls: ['./metodo-pago.component.css']
})
export class MetodoPagoComponent {

  @Input() datos_cuenta: AutoActivacion;
  public formulario_metodo_pago = new FormGroup({
    nombre_tarjetahabiente: new FormControl('', Validators.required),
    numero_tarjeta: new FormControl('', Validators.required),
    mes: new FormControl('1', Validators.required),
    anio: new FormControl('2020', Validators.required),
    codigo_seguridad: new FormControl('', Validators.required)
  });
  @Output() evt_siguiente = new EventEmitter();
  @Output() evt_anterior = new EventEmitter();
  public lista_anios = [...Array(10)].map((a, b) => new Date().getFullYear() + b);
  public lista_plan = [
    { 'AN': 'Anual' }, { 'MN': 'Mensual' }
  ];

  constructor(
    private _servicio_activacion: AutoActivacionService,
    private _constantes: ConstantsService
  ) {
    const elem: any = document.getElementsByClassName('carousel-item')[4];
    document.getElementById('sombra-interna').setAttribute('style', 'height:' + elem.offsetHeight + 'px');
   }

  AnimacionSlide() {
    const elem: any = document.getElementsByClassName('carousel-item')[3];
    // Elemento siguiente
    const next_elem: any = document.getElementsByClassName('carousel-item')[4];
    let pos = 0;
    const id = setInterval(frame, 10);
    const that = this;
    function frame() {
      if (pos === -600) {
        clearInterval(id);
        next_elem.style.left = 0 + 'px';
        that.evt_siguiente.emit();
        elem.style.left = 0 + 'px';
        elem.style.display = 'none';

        const next_elem2: any = document.getElementsByClassName('carousel-item')[5];
        next_elem2.style.left = '71%';
        next_elem2.style.display = 'block';

        document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem2.offsetHeight + 'px');
      } else {
        pos = pos - 10;
        elem.style.left = pos + 'px';
        const aux = Number(next_elem.offsetLeft) - 10;
        next_elem.style.left = aux + 'px';
      }
    }
  }


  siguiente() {
    this._constantes.isLoading = true;
    this.enviarConekta();
  }

  AnteriorSlide() {
    const next_elem_1: any = document.getElementsByClassName('carousel-item')[0];
    next_elem_1.style.left = '0px';
    next_elem_1.style.display = 'none';
    const next_elem_2: any = document.getElementsByClassName('carousel-item')[1];
    next_elem_2.style.left = '0px';
    next_elem_2.style.display = 'none';
    const next_elem_21: any = document.getElementsByClassName('carousel-item')[2];
    next_elem_21.style.left = '0px';
    next_elem_21.style.display = 'block';
    const next_elem3: any = document.getElementsByClassName('carousel-item')[3];
    next_elem3.style.left = '71%';
    next_elem3.style.display = 'block';

    const next_elem: any = document.getElementsByClassName('carousel-item')[4];
    next_elem.style.display = 'none';



    document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem3.offsetHeight + 'px');
    this.evt_anterior.emit();
  }

  enviarConekta() {
    Conekta.setPublicKey('key_M3o95bhy6AztbSaPmPBJw7g');
    Conekta.setLanguage('es');
    const tokenParams = {
      'card': {
        'number': this.datos_cuenta.metodo_pago.numero_tarjeta,
        'name': this.datos_cuenta.metodo_pago.nombre_tarjetahabiente,
        'exp_year': this.datos_cuenta.metodo_pago.anio,
        'exp_month': this.datos_cuenta.metodo_pago.mes,
        'cvc': this.datos_cuenta.metodo_pago.codigo_seguridad,
        'address': {
          'street1': '',
          'street2': '',
          'city': '',
          'state': '',
          'zip': '',
          'country': ''
        }
      }
    };
    Conekta.Token.create(tokenParams
      , success => {
        this.datos_cuenta.tarjeta_token = success.id;
        this.guardarDatosAudit();
      }
      , error => {
        this._constantes.isLoading = false;
        Swal.fire('Atención', 'Ha ocurrido un error. <br> Detalle error: ' + error.message_to_purchaser, 'error');
        console.log(error);
      });
  }

  // Metodo para guardar los datos en Audit una vez que conekta nos dio un token valido
  guardarDatosAudit() {
    this._servicio_activacion.crearCliente(this.datos_cuenta).subscribe(
      (data: any) => {
        this._servicio_activacion.procesarSuscripcion(data.uuid).subscribe(
          (data_suscripcion) => {
            Swal.fire('Éxito', '¡Usuario creado con exito!', 'success');
            this.AnimacionSlide();
            this._constantes.isLoading = false;
          },
          (error) => {
            if (error && error.error) {
              Swal.fire('Atención', 'Ha ocurrido un error. <br> Detalle error: ' + error.error.error.message, 'error');
            } else {
              Swal.fire('Atención', 'Ha ocurrido un error inesperado. ', 'error');
            }
            this._constantes.isLoading = false;
          }
        );
      },
      (error) => {
        this._constantes.isLoading = false;
        console.log(error);
      }
    );
  }

}
