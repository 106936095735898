import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { LoginComponent } from './paginas/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'index', loadChildren: () => import('./paginas/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'login', loadChildren: () => import('./paginas/login/login.module').then(m => m.LoginModule) },
  { path: 'home', loadChildren: () => import('./paginas/home/home.module').then(m => m.HomeModule) },
  { path: 'activacion', loadChildren: () => import('./Modulos/auto-activacion/auto-activacion.module').then(m => m.AutoActivacionModule) },
  { path: 'pagina404', loadChildren: () => import('./paginas/pagina404/pagina404.module').then(m => m.Pagina404Module) },
  { path: '**', redirectTo: 'pagina404', pathMatch: 'full' }
];

const routerOptions: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
