export class DetalleConciliacion {

}

export class DetalleTablaDiferencia {
  cantidad: number;
  monto: number;
  razon_social_receptor: string;
  rfc_receptor: string;
  razon_social_emisor: string;
  rfc_emisor: string;

  constructor() {
    this.cantidad = 0;
    this.monto = 0;
    this.razon_social_receptor = '';
    this.razon_social_emisor = '';
    this.rfc_receptor = '';
    this.rfc_emisor = '';
  }
}

export class TablaConciliacion {
  cantidad_cfdi_faltante: number;
  faltante_sistema_propio: number;
  fecha: string;
  timbrado_sat: number;
  timbrado_sistema_propio: number;

  constructor() {
    this.cantidad_cfdi_faltante = 0;
    this.faltante_sistema_propio = 0;
    this.fecha = '';
    this.timbrado_sat = 0;
    this.timbrado_sistema_propio = 0;
  }
}

