import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page.component';


const routes: Routes = [
  { path: 'index', redirectTo: 'login', pathMatch: 'prefix' },
  { path: 'index', component: LandingPageComponent }
];

// const routes: Routes = [
//   { path: '', redirectTo: 'index', pathMatch: 'full' },
//   { path: '', component: LandingPageComponent }
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
