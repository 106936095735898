import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css']
})
export class SwitcherComponent implements OnInit {

  @Input() label = '';
  @Input() index = 0;
  @Input() checked = false;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  change(e) {
    this.onChange.emit(e);
  }
}
