import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ConstantsService } from 'src/app/Compartidos/global/constants.service';

@Component({
  selector: 'app-usuarios-main',
  templateUrl: './usuarios-main.component.html',
  styleUrls: ['./usuarios-main.component.css']
})
export class UsuariosMainComponent implements OnInit {
  @Output() cargando = new EventEmitter();
  public titulo = '';

  constructor(
    private _constant: ConstantsService,
    private router: Router
  ) {
    this._constant.isLoading = false;
    this.titulo = 'Cambiar Contraseña' ;
   }

  ngOnInit() {
  }

}
