export class TablaPreliminar {
  neto: number;
  iva: number;
  total: number;
  tipo: string;
  lista: any;
  datos: any;

  constructor() {
    this.neto = 0;
    this.iva = 0;
    this.total = 0;
    this.tipo = '';
  }
}
