import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { AutoActivacion } from 'src/app/Compartidos/Entidades';
@Component({
  selector: 'app-datos-cuenta',
  templateUrl: './datos-cuenta.component.html',
  styleUrls: ['./datos-cuenta.component.css'],

})
export class DatosCuentaComponent {

  public formulariocuenta = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)])
    , apellidos: new FormControl('', [Validators.required, Validators.minLength(3)])
    , celular: new FormControl('', Validators.required)
    , nombre_corporativo: new FormControl('', Validators.required)
    , correo: new FormControl('', [ Validators.required, Validators.email ])
    , correo_confirmacion: new FormControl('', [ Validators.required, Validators.email])
    , contrasena: new FormControl('', [Validators.required, this.validarContrasena])
    , contrasena_confirmacion: new FormControl('', [Validators.required, this.validarContrasena])
  });
  @Input() datos_cuenta: AutoActivacion;
  @Output() evt_siguiente = new EventEmitter();
  constructor() {
    setTimeout( () => {
      const next_elem: any = document.getElementsByClassName('carousel-item')[1];
      next_elem.style.left = '71%';
      next_elem.style.display = 'block';
      document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem.offsetHeight + 'px');
    }, 200);
   }


  AnimacionSlide() {
    const elem: any = document.getElementsByClassName('carousel-item')[0];
    const next_elem: any = document.getElementsByClassName('carousel-item')[1];
    let pos = 0;
    const id = setInterval(frame, 10);
    const that = this;
    function frame() {
      if (pos === -600) {
        clearInterval(id);
        next_elem.style.left = 0 + 'px';
        that.evt_siguiente.emit();
        elem.style.left = 0 + 'px';
        elem.style.display = 'none';
        const next_elem2: any = document.getElementsByClassName('carousel-item')[2];
        next_elem2.style.left = '71%';
        next_elem2.style.display = 'block';
        document.getElementById('sombra-interna').setAttribute('style', 'height:' + next_elem2.offsetHeight + 'px');
      } else {
        pos = pos - 10;
        elem.style.left = pos + 'px';
        const aux = Number(next_elem.offsetLeft) - 10;
        next_elem.style.left = aux + 'px';
      }
    }
  }


  siguiente() {
    this.AnimacionSlide();
  }

  private validarContrasena(control: AbstractControl) {
    const contrasena = control.value;
    let error = null;
    const regex = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/);
    if (!regex.test(contrasena)) {
      error = 'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico';
    }
    console.log(error);
    return error;
  }

}
