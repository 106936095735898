export class Usuario {
  id: number;
  administrador: string;
  username: string;
  password: string;
  password2: string;
  new_password: string;
  token: string;
  identificador: string;
  id_corporativo: number;
  corporativo: string;
  nombre_comercial: string;
  rfc: Array<InfoRfc>;
  menu: Menu[];
  flag: string;
  email: string;
  telefono: number;
  nombre: string;
  code: string;
  IdToken: string;
  RefreshToken: string;
  sistemas: Sistemas[];


  constructor() {
    this.id = null;
    this.username = '';
    this.nombre = '';
    this.email = '';
    this.password = '';
    this.token = '';
    this.identificador = '';
    this.id_corporativo = 0;
    this.corporativo = '';
    this.nombre_comercial = '';
    this.rfc = new Array<InfoRfc>();
  }
}

class InfoRfc {
  razon_social: string;
  rfc: string;
  aux_temp: string;
}

export class UserSistema {
  email: string;
  id: string;
  id_corporativo: string;
  identificador: string;
  nombre: string;
  user_name: string;
  habilitado: number;
  administrador: number;
}
class Menu {
  caret: string;
  icon: string;
  submenu: SubMenu[];
  title: string;
  url: string;
}

class SubMenu {
  title: string;
  url: string;
}
class Sistemas {
  nombre: string;
  tipo: string;
}
