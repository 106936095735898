export class FiltroNomina {
    fecha_factura: string;
    rfc_emisor: string;
    rfc_receptor: string;
    nombre_empleado: string;
    serie: string;
    folio: string;
    folio_fiscal: string;
    codigo_postal: string;
    tipo?: string;
    columnas?: any;
    estatus_conciliacion: number;

    constructor() {
        this.fecha_factura = '';
        this.rfc_emisor = '';
        this.rfc_receptor = '';
        this.nombre_empleado = '';
        this.serie = '';
        this.folio = '';
        this.folio_fiscal = '';
        this.codigo_postal = '';
        this.estatus_conciliacion = 2;
    }
}
