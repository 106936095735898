import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuariosMainComponent } from './usuarios-main/usuarios-main.component';
import { UsuariosFormularioComponent } from './usuarios-formulario/usuarios-formulario.component';
import { UsuarioContrasenaComponent } from './usuario-contrasena/usuario-contrasena.component';

const routes: Routes = [
  {
    path: '', component: UsuariosMainComponent,
    children: [
      {
        path: 'registro', component: UsuariosFormularioComponent
      },
      {
        path: 'confirmacion', component: UsuariosFormularioComponent
      },
      {
        path: 'nueva_contrasena', component: UsuarioContrasenaComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuariosRoutingModule { }
