import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, Injectable, OnChanges } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbCalendar, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { FiltroPagos, DatosIniciales } from '../Entidades';
import { ConstantsService } from '../global/constants.service';
import { StorageService } from '../login/storage.service';
import Swal from 'sweetalert2';
import { DescargaService } from 'src/app/Modulos/Descargas/descarga.service';
import { Select2OptionData } from 'ng2-select2';
import { ReportesDescargaService } from '../reportes-descarga.service';
const I18N_VALUES = {
  'es': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  }
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

interface FormaPago {
  Descripcion: string;
  Forma_Pago: string;
}
interface MetodoPago {
  Descripcion: string;
  Metodo_Pago: string;
}
interface UsoCFDI {
  Descripcion: string;
  uso_cfdi: string;
}


@Component({
  selector: 'app-filtro-pagos',
  templateUrl: './filtro-pagos.component.html',
  styleUrls: ['./filtro-pagos.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class FiltroPagosComponent implements OnInit, OnChanges {
  @Input() carga_incial: boolean;
  @Output() filtrar = new EventEmitter();
  @Input() mostrar_emisor: boolean;
  filtro = new FiltroPagos();
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  lista_forma_pago: FormaPago[];
  lista_metodo_pago: MetodoPago[];
  lista_uso_cfdi: UsoCFDI[];
  formularioFiltro: FormGroup;
  model: NgbDateStruct;
  date: { year: number, month: number };
  public datos_inciales: DatosIniciales;
  opciones: Select2Options = {};
  lista_razones: Array<Select2OptionData>;
  public startValue = '';
  constructor(
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private _servicioReportesDescarga: ReportesDescargaService,
    private _loading: ConstantsService,
    private _storageService: StorageService,
    private _servicioDescargas: DescargaService
  ) {
    this.opciones.placeholder = 'Seleccione...';
    this.opciones.language = {
      noResults: () => {
        return 'No hay resultado';
      },
      searching: () => {
        return 'Buscando..';
      }
    };
    this.opciones.allowClear = true;
    if (this._loading.filtro_header.meses) { // poner la fecha que se trae

      console.log('if nomina');
      
      
      // GET LOCAL STORAGE COTA SUPERIOR

      const FECHA_COTA_SUPERIOR = JSON.parse(localStorage.getItem('FECHA_FINAL'));

      const lastDayOfMonth = new Date(this._loading.filtro_header.anio, this._loading.filtro_header.meses[this._loading.filtro_header.meses.length - 1], 0);
      const date = new NgbDate(this._loading.filtro_header.anio, Number(this._loading.filtro_header.meses[this._loading.filtro_header.meses.length - 1]), lastDayOfMonth.getDate());

      const FECHA_COMPLETA_COTA_SUPERIOR = FECHA_COTA_SUPERIOR ? FECHA_COTA_SUPERIOR : date;

      this.toDate = FECHA_COMPLETA_COTA_SUPERIOR; // calendar.getToday();

      // GET LOCAL STORAGE COTA INFERIOR

      const FECHA_COTA_INFERIOR = JSON.parse(localStorage.getItem('FECHA_INICIO'));
      const DIA_COTA_INFERIOR = FECHA_COTA_INFERIOR ? JSON.parse(localStorage.getItem('FECHA_INICIO')).day  : 1;
      const MES_COTA_INFERIOR = FECHA_COTA_INFERIOR ? JSON.parse(localStorage.getItem('FECHA_INICIO')).month  : Number(this._loading.filtro_header.meses[0]);
      const ANIO_COTA_INFERIOR = FECHA_COTA_INFERIOR ? JSON.parse(localStorage.getItem('FECHA_INICIO')).year : this._loading.filtro_header.anio;

      this.fromDate = new NgbDate(ANIO_COTA_INFERIOR, MES_COTA_INFERIOR, DIA_COTA_INFERIOR); // calendar.getPrev(calendar.getToday(), 'd', (calendar.getToday().day - 1));
    } else {
      this.toDate = calendar.getToday();
      this.fromDate = calendar.getPrev(calendar.getToday(), 'd', (calendar.getToday().day - 1));
    }
    this.formularioFiltro = this.formBuilder.group({
      fecha_factura: [this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate), Validators.required],
      rfc_receptor: this.filtro.rfc_receptor,
      rfc_emisor: this.filtro.rfc_emisor,
      serie: this.filtro.serie,
      folio: this.filtro.folio,
      folio_fiscal: this.filtro.folio_fiscal,
      documento_relacionado: this.filtro.documento_relacionado,
      estatus_cfdi: Number(this.filtro.estatus_cfdi),
      estatus_conciliacion: Number(this.filtro.estatus_conciliacion),
      codigo_postal: this.filtro.codigo_postal,
      forma_pago: this.filtro.forma_pago,
      metodo_pago_dr: this.filtro.metodo_pago_dr,
      listtype: this.filtro.listtype,
      razon_social_filtro_receptor: this.filtro.razon_social_filtro_receptor,
      razon_social_filtro_emisor: this.filtro.razon_social_filtro_emisor
    });
    this.datos_inciales = this._storageService.getDatosIniciales();
    this.getCatalgos();
  }


  ngOnInit() {
    document.getElementsByTagName('input').namedItem('dpFromDate').setAttribute('readonly', 'true');
    document.getElementsByTagName('input').namedItem('dpFromDate').classList.add('input-readonly');
  }

  getCatalgos() {
    this._servicioReportesDescarga.getFormaPagoCatalogo().subscribe((data: any) => {
      this.lista_forma_pago = data;
    });
    this._servicioReportesDescarga.getMetodoPagoCatalogo().subscribe((data: any) => {
      this.lista_metodo_pago = data;
    });
    this._servicioReportesDescarga.getUsoCFDICatalogo().subscribe((data: any) => {
      this.lista_uso_cfdi = data;
    });
    // this.actualizarRazones();
  }

  actualizarRazones() {
    this._servicioReportesDescarga.getRazonesSociales(this._loading.filtro_header.rfc_solicitante, 'pagos', this.mostrar_emisor ? 'recepcion' : 'emision').subscribe(
      (data) => {
        this.lista_razones = data[0].map((x: any) => {
          x.id = x.razon_social;
          x.text = x.razon_social;
          return x;
        });
        this.lista_razones.unshift({ id: '', text: 'Seleccione...' });
      }
    );
  }

  ngOnChanges(): void {
    this.actualizarRazones();
  }

  enviarFiltro() {
    this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
    this.formularioFiltro.get('estatus_conciliacion').setValue(Number(this.formularioFiltro.get('estatus_conciliacion').value));
    this.filtrar.emit(this.formularioFiltro.value);
  }

  onDateSelection(date: NgbDate) {
    console.log('Pagos Emitidos/Recibidos');


    if (!this.fromDate && !this.toDate) {
      localStorage.setItem('FECHA_INICIO', JSON.stringify(date));
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      localStorage.setItem('FECHA_FINAL', JSON.stringify(date));
      this.toDate = date;
      const meses = [];
      for (let i = this._loading.filtro_header.meses[0]; i <= date.month; i++) {
        meses.push(i);
      }
      this._loading.filtro_header.meses = meses;
      this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
    } else {
      localStorage.setItem('FECHA_INICIO', JSON.stringify(date));
      this.toDate = null;
      this.formularioFiltro.get('fecha_factura').setValue(null);
      const meses_marcados = [date.month];
      this._loading.filtro_header.meses = meses_marcados;
      this.fromDate = date;
    }
    this._loading.filtro_header.anio = date.year;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  formatDate(date: NgbDateStruct): string {
    return `${date.month}/${date.day}/${date.year}`;
  }

  limpiarFormulaio() {
    this.formularioFiltro = this.formBuilder.group({
      fecha_factura: this.filtro.fecha_factura,
      rfc_receptor: this.filtro.rfc_receptor,
      rfc_emisor: this.filtro.rfc_emisor,
      serie: this.filtro.serie,
      folio: this.filtro.folio,
      folio_fiscal: this.filtro.folio_fiscal,
      documento_relacionado: this.filtro.documento_relacionado,
      estatus_cfdi: Number(this.filtro.estatus_cfdi),
      codigo_postal: this.filtro.codigo_postal,
      forma_pago: this.filtro.forma_pago,
      metodo_pago_dr: this.filtro.metodo_pago_dr,
      listtype: this.filtro.listtype,
      razon_social_filtro_receptor: this.filtro.razon_social_filtro_receptor,
      razon_social_filtro_emisor: this.filtro.razon_social_filtro_emisor,
      estatus_conciliacion: Number(this.filtro.estatus_conciliacion),
      // tipo_comprobante: this.filtro.tipo_comprobante
    });
    this.toDate = this.calendar.getToday();
    // Inicializa el dia inicial como el primero del mes
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', (this.calendar.getToday().day - 1));
    this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
    const aux_razon_receptor = [...this.lista_razones];
    this.lista_razones.length = 0;
    setTimeout(() => {
      this.lista_razones = aux_razon_receptor;
    }, 250);
  }

  generarDescarga() {
    this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
    this._loading.isLoading = true;
    const request = {
      'type': 'create',
      'corporativo_id': this.datos_inciales.usuario.id_corporativo,
      'user': this.datos_inciales.usuario.username,
      'reporte': 'pagos',
      'identificador': this.datos_inciales.usuario.identificador,
      'filtro': this.formularioFiltro.value
    };
    if ((window.location.href.indexOf('emitidos') !== -1)) {
      request.filtro.tipo = 'emision';
      request.filtro.rfc_emisor = this._loading.filtro_header.rfc_solicitante;
    } else {
      request.filtro.tipo = 'recepcion';
      request.filtro.rfc_receptor = this._loading.filtro_header.rfc_solicitante;
    }
    this._servicioDescargas.generarSolicitudDescargaProgramada(request).subscribe(
      (data) => {
        Swal.fire({
          title: '<strong>Generando su link de descarga</strong>',
          html:
            'Puedes accede a la lista de descargas desde el siguiente, ' +
            '<a href="/#/home/descargas/lista">link</a> ',
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: 'Cerrar',
        }
          // 'Éxito', 'Se esta generando su link de descarga', 'success'
        );
        this._loading.isLoading = false;
      }
      , (error) => {
        Swal.fire('Atención', 'Ha ocurrido un error inesperado. ', 'error');
        console.log(error);
        this._loading.isLoading = false;
      }
      , () => { }
    );
  }

  seleccionaRazon(event: any): void {
    if (!this.mostrar_emisor) {
      this.formularioFiltro.get('razon_social_filtro_receptor').setValue(event.value);
    } else {
      this.formularioFiltro.get('razon_social_filtro_emisor').setValue(event.value);
    }
  }

  cargarRazones() {
    this._loading.isLoading = true;
    this._servicioReportesDescarga.getRazonesSociales(this._loading.filtro_header.rfc_solicitante, 'pagos', this.mostrar_emisor ? 'recepcion' : 'emision').subscribe(
      (data) => {
        this.lista_razones = data[0].map((x) => {
          x.id = x.razon_social;
          x.text = x.razon_social;
          return x;
        });
        this.lista_razones.unshift({ id: '', text: 'Seleccione...' });
        this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
        this.formularioFiltro.get('razon_social_filtro_receptor').setValue('');
        this.formularioFiltro.get('razon_social_filtro_emisor').setValue('');
        this.filtrar.emit(this.formularioFiltro.value);
      },
      (error) => {
        this._loading.isLoading = false;
        this.formularioFiltro.get('fecha_factura').setValue(this.formatDate(this.fromDate) + ' - ' + this.formatDate(this.toDate));
        this.filtrar.emit(this.formularioFiltro.value);
      }
    );
  }

}
